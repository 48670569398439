import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { formatter } from '../../helpers/Utils';

const PaymentList = ({ data, quotas, setQuotas, setAmount }) => {
  const [subtotal, setSubtotal] = useState(null);

  const onSelectQuotas = (e) => {
    setQuotas((prevQuotas) => {
      if (prevQuotas.includes(e.id)) {
        return prevQuotas.filter((q) => q !== e.id);
      } else {
        return [...prevQuotas, e.id];
      }
    });
  };

  const getSubTotal = () => {
    let total = 0;
    quotas.forEach((selectedQuotaId) => {
      const selectedQuota = data.find((quota) => quota.id === selectedQuotaId);
      if (selectedQuota) {
        total += selectedQuota.pending;
      }
    });
    return total;
  };

  useEffect(() => {
    const newSubtotal = getSubTotal();
    setSubtotal(newSubtotal);
    setAmount(newSubtotal);
  }, [quotas, data]);

  return (
    <div className="col-12 col-lg-8 mx-auto">
      <div id="accordion-icon-left" className="accordion-icon icon-01">
        <div className="widget has-shadow bg-transparent">
          <a
            className="card-header collapsed d-flex align-items-center"
            data-toggle="collapse"
            href="#IconLeftCollapseOne"
          >
            <div className="card-title">
              <h5 className="text-blue m-0">
                Selecciona cargos a pagar/abonar
              </h5>
            </div>
            {subtotal !== null && (
              <p className="ml-auto text-16 m-0">
                <b>Total:</b> {formatter.format(Math.abs(subtotal))}.00
              </p>
            )}
          </a>
          <div
            id="IconLeftCollapseOne"
            className="card-body collapse pt-0"
            data-parent="#accordion-icon-left"
          >
            {data?.map((quota, i) => {
              const checked = quotas.find(
                (checkQuota) => checkQuota === quota.id
              );
              const isExpired = moment(quota.expirationDate).isBefore(
                new Date()
              );
              const daysSinceExpiration = isExpired
                ? moment().diff(quota.expirationDate, 'days')
                : 0;
              const expiredText = isExpired
                ? `Vencido hace ${daysSinceExpiration} días`
                : '';

              return (
                <div className="w-100" key={quota.id}>
                  <input
                    type="checkbox"
                    className="d-none"
                    onChange={() => onSelectQuotas(quota)}
                    id={`quota_${quota.id}`}
                  />
                  <label
                    htmlFor={`quota_${quota.id}`}
                    className="d-flex justify-content-between"
                  >
                    <div className="mr-4 ml-2">
                      <svg
                        width="40"
                        height="40"
                        viewBox="0 0 50 50"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle
                          cx="25"
                          cy="25"
                          r="23"
                          fillOpacity="0"
                          stroke="#3927AB"
                          strokeWidth="4"
                        />
                        {checked && (
                          <circle cx="25" cy="25" r="16" fill="#3927AB" />
                        )}
                      </svg>
                    </div>
                    <div className="w-100">
                      <p className="text-blue m-0">{quota.name}</p>
                      <div>{expiredText}</div>
                    </div>
                    <h5 className="text-blue">
                      {formatter.format(Math.abs(quota.pending))}.00
                    </h5>
                  </label>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentList;
