import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import { servicePath } from '../../constants/defaultsValues';
import Section from '../../components/layouts/Section';
import InvitationsForm from '../../components/invitations/invitationsForm';
import { Alert } from '../../alertServices';

const apiUrl = `${servicePath}/invitations`;

const Create = () => {
  let history = useHistory();
  const state = useSelector((state) => state);
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = async (values) => {
    setIsLoading(true);
    axios
      .post(apiUrl, values, {
        headers: {
          Authorization: `Bearer ${state.jwt}`,
        },
      })
      .then(() => {
        Alert.success({
          title: 'Invitación creada',
        });
        history.push('/invitations');
        setIsLoading(false);
      })
      .catch((error) => {
        Alert.error({
          title: 'Hubo un problema al crear invitación, intentelo mas tarde.',
        });
        setIsLoading(false);
      });
  };

  return (
    <Section
      title="Invitaciones"
      withBack={true}
      noBorder={true}
      cancelTo="/invitations"
    >
      <div
        className="container pt-5 section-invitations"
        style={{ paddingBottom: '4rem' }}
      >
        <div className="col-10 mx-auto text-center text-title">
          Selecciona las opciones para generar tu invitación
        </div>

        <InvitationsForm onSubmit={onSubmit} isLoading={isLoading} />
      </div>
    </Section>
  );
};

export default Create;
