import React, { useState } from 'react';
import Section from '../layouts/Section';
import { Link } from 'react-router-dom';
import { registerUser } from '../../redux/auth/actions';
import { connect } from 'react-redux';

const validatePassword = (value) => {
  let error = null;
  if (!value) {
    error = 'Campo obligatorio';
  } else if (value.length < 6) {
    error = 'Contraseña debe ser mayor a 6 caracteres';
  }
  return error;
};
const validateConfirm = (value1, value2) => {
  let error = null;
  if (value1 !== value2) {
    error = 'Las contraseñas no coinciden';
  }
  return error;
};
const validateUsername = (value) => {
  let error = null;
  if (!value) {
    error = 'Campo obligatorio';
  }
  return error;
};
const validatePhone = (value) => {
  let error = null;
  if (!value) {
    error = 'Campo obligatorio';
  }
  return error;
};
const validateEmail = (value) => {
  let error = null;
  if (!value) {
    error = 'Campo obligatorio';
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
    error = 'Email invalido';
  }
  return error;
};

const Register = ({ history, registerUserAction, error }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [submit, setSubmit] = useState(null);
  const [confirmPassword, setConfirmPassword] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [errors, setErrors] = useState({
    password: null,
    username: null,
    phone: null,
    email: null,
    confirm: null,
  });

  const onUserRegister = () => {
    const registerData = {
      username: username,
      password: password,
      phone: phone,
      email: email,
    };
    registerUserAction({ registerData, history });
    // go to welcome page
  };
  const validateRegister = (name, pass) => {
    const validPass = validatePassword(password);
    const validName = validateUsername(username);
    const validPhone = validatePhone(phone);
    const validEmail = validateEmail(email);
    const validConfirm = validateConfirm(password, confirmPassword);
    setErrors({
      password: validPass,
      username: validName,
      phone: validPhone,
      email: validEmail,
      confirm: validConfirm,
    });
    return (
      !validPass && !validName && !validPhone && !validEmail && !validConfirm
    );
  };

  return (
    <Section
      allNotification={false}
      searchPopup={true}
      title={'Register'}
      disabledHeader={true}
      disabledFooter={true}
    >
      <section
        className="section-login "
        style={{
          backgroundImage:
            'url(' +
            process.env.PUBLIC_URL +
            '/assets/img/Fondo-login.png' +
            ')',
        }}
      >
        <div className="page-title bg-transparent ">
          <div className="container pt-3">
            <Link
              to="/login"
              className="text-left home-clicked go-back text-white"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="30"
                height="30"
                fill="currentColor"
                className="bi bi-chevron-left"
                viewBox="0 0 16 16"
              >
                <path
                  fillRule="evenodd"
                  d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"
                />
              </svg>
            </Link>
          </div>
        </div>
        <div className="text-center title ">
          <div className="col-md-4 col-5 mx-auto mb-4">
            <img
              src={process.env.PUBLIC_URL + '/assets/img/civity-logo.svg'}
              alt="civity"
            />
          </div>
          <div className="col-12 text-center">
            <h4 className="text-center">Registrate</h4>
          </div>
        </div>

        <div className="signin-area  bg-transparent">
          <div className="container">
            <form className="register contact-form-inner bg-transparent">
              {submit && error ? (
                <div className="col-12 text-center mx-auto mb-3">
                  <span className="text-danger mx-auto">{error}</span>
                </div>
              ) : null}
              <label className="single-input-wrap">
                <input
                  type="text"
                  placeholder="Nombre de Usuario"
                  onChange={(e) => setUsername(e.target.value)}
                  value={username}
                />
                {errors.username ? (
                  <span className="text-danger">{errors.username}</span>
                ) : null}
              </label>
              <label className="single-input-wrap">
                <input
                  type="password"
                  placeholder="Contraseña"
                  onChange={(e) => setPassword(e.target.value)}
                  value={password}
                />
                {errors.password ? (
                  <span className="text-danger">{errors.password}</span>
                ) : null}
              </label>
              <label className="single-input-wrap">
                <input
                  type="password"
                  placeholder="Confirmar Contraseña"
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  value={confirmPassword}
                />
                {errors.confirm ? (
                  <span className="text-danger">{errors.confirm}</span>
                ) : null}
              </label>
              <label className="single-input-wrap">
                <input
                  type="number"
                  placeholder="Número de Teléfono"
                  onChange={(e) => setPhone(e.target.value)}
                  value={phone}
                />
                {errors.phone ? (
                  <span className="text-danger">{errors.phone}</span>
                ) : null}
              </label>
              <label className="single-input-wrap">
                <input
                  type="email"
                  placeholder="Email"
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                />
                {errors.email ? (
                  <span className="text-danger">{errors.email}</span>
                ) : null}
              </label>
              <div className="container text-center">
                <button
                  className="btn btn-purple btn-login"
                  onClick={(e) => {
                    if (validateRegister(username, password)) {
                      setSubmit(true);
                      onUserRegister();
                    }
                  }}
                >
                  Crear mi Cuenta
                </button>
              </div>
            </form>
          </div>
        </div>
        <div className="bottom bottom-register">
          <span>¿Tienes cuenta?</span>
          <Link to="/login"> Inicia Sesión</Link>
        </div>
      </section>
    </Section>
  );
};
const mapStateToProps = (state) => {
  return { error: state.error };
};
export default connect(mapStateToProps, {
  registerUserAction: registerUser,
})(Register);
