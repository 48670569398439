import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import reducer from './reducers';
import rootSaga from './sagas';
import storage from 'redux-persist/lib/storage';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
/*añadido */
import { persistStore, persistReducer } from 'redux-persist';

const sagaMiddleware = createSagaMiddleware();
// const devToolsMiddleware = window.__REDUX_DEVTOOLS_EXTENSION__();

//Only for Dev, commit when you done.
// export const store = createStore(reducer,initialState, compose(applyMiddleware(sagaMiddleware),devToolsMiddleware));

//For Prod.
/* export const store = createStore(
  reducer,
  initialState,
  compose(applyMiddleware(sagaMiddleware))
); */
const middlewares = [sagaMiddleware];
const persistConfig = {
  key: 'root',
  storage,
  stateReconciler: autoMergeLevel2,
};
const persistedReducer = persistReducer(persistConfig, reducer);
export function configureStore(initialState) {
  const store = createStore(
    persistedReducer,
    initialState,
    compose(applyMiddleware(...middlewares))
  );
  const persistor = persistStore(store);
  sagaMiddleware.run(rootSaga);

  if (module.hot) {
    module.hot.accept('./reducers', () => {
      // eslint-disable-next-line global-require
      const nextRootReducer = require('./reducers');
      store.replaceReducer(nextRootReducer);
    });
  }

  return { store, persistor };
}

// sagaMiddleware.run(rootSaga);
