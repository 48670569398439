import React, { useEffect, useState } from 'react';
import Section from '../layouts/Section';
import { useHistory, useParams } from 'react-router-dom';
import { resetPasswordStart, logoutUser } from '../../redux/auth/actions';
import { useDispatch } from 'react-redux';
import { connect } from 'react-redux';
import axios from 'axios';
import { servicePath } from '../../constants/defaultsValues';
import { Alert } from '../../alertServices';

const ResetPassword = (jwt, message) => {
  localStorage.clear();
  const dispatch = useDispatch();
  const history = useHistory();
  const { token } = useParams();
  const [user, setUser] = useState({});
  const [oldEmail, setOldEmail] = useState('');
  const [email, setEmail] = useState('');
  const [confirmEmail, setConfirmEmail] = useState('');
  const [errors, setErrors] = useState({ email: '', confirmEmail: '' });
  const [loading, setLoading] = useState(false);
  const jasonWebToken = jwt.jwt;

  const validateEmail = (emailAddress) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(emailAddress);
  };

  async function userFetch() {
    axios
      .post(`${servicePath}/residents/new/confirm`, {
        token,
      })
      .then((res) => {
        setUser(res.data?.id);
        setOldEmail(res.data?.email);
      })
      .catch((err) => {
        console.log(err.message);
        Alert.error({
          title:
            'El token ya ha sido utilizado o es inválido, por favor intenté enviar otro correo.',
        });
      });
  }

  useEffect(() => {
    if (token) {
      userFetch();
    }
  }, []);

  const onSubmit = async (e) => {
    e.preventDefault();

    const emailValid = validateEmail(email);
    const confirmEmailValid = validateEmail(confirmEmail);
    const emailError = !emailValid
      ? 'Email no válido'
      : email === oldEmail
      ? 'El nuevo correo debe ser diferente al anterior'
      : '';
    const confirmEmailError = !confirmEmailValid
      ? 'Email no válido'
      : email !== confirmEmail
      ? 'Los correos no coinciden'
      : '';

    if (emailError || confirmEmailError) {
      setErrors({ email: emailError, confirmEmail: confirmEmailError });
      return;
    }

    setLoading(true);
    try {
      const res = await axios.post(`${servicePath}/email/change-email`, {
        user: user,
        newEmail: email,
      });
      if (res.data.status) {
        Alert.success({
          title: 'El correo electronico se ha actualizado',
        });
        setTimeout(() => {
          dispatch(logoutUser());
          history.push('/login');
        }, 3000);
      } else {
        Alert.error({
          title: res.data.message,
        });
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error('Error al enviar la solicitud:', error);
      Alert.error({
        title:
          'El token ya ha sido utilizado o es inválido, por favor intenté enviar otro correo.',
      });
    }
  };

  /* if (jasonWebToken !== null) {
    return <Redirect to="/">Error, ya hay una cuenta iniciada</Redirect>;
  } else { */
  return (
    <Section
      allNotification={false}
      searchPopup={true}
      title={'Login'}
      disabledHeader={true}
      disabledFooter={true}
    >
      <section
        className="section-login"
        style={{
          backgroundImage:
            'url(' +
            process.env.PUBLIC_URL +
            '/assets/img/Fondo-login.png' +
            ')',
          backgroundSize: '100% 100%',
          minHeight: '100%',
          position: 'absolute',
          width: '100%',
        }}
      >
        <div className="page-title bg-transparent"></div>
        <div className="text-center mg-bottom-40 title">
          <div className="col-md-4 col-5 mx-auto mb-5">
            <img
              src={process.env.PUBLIC_URL + '/assets/img/civity-logo.svg'}
              alt="civity"
            />
          </div>
          <div className="col-12 text-center">
            <h4 className="text-center">Nuevo correo electrónico</h4>
          </div>
        </div>
        <div className="Login-area">
          <div className="container">
            <form className="contact-form-inner bg-transparent">
              {jwt.message ? (
                <div className="col-12 text-center mx-auto mb-3">
                  <span className="text-danger mx-auto">{jwt.message}</span>
                </div>
              ) : null}
              <label className="single-input-wrap">
                <input
                  type="email"
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                  placeholder="Email"
                />
                {errors.email ? (
                  <span className="text-danger">{errors.email}</span>
                ) : null}
              </label>
              <label className="single-input-wrap mt-4">
                <input
                  type="email"
                  onChange={(e) => setConfirmEmail(e.target.value)}
                  value={confirmEmail}
                  placeholder="Confirmar Email"
                />
                {errors.confirmEmail ? (
                  <span className="text-danger">{errors.confirmEmail}</span>
                ) : null}
              </label>

              <div className="w-100 text-center">
                <button
                  className="btn btn-login mx-auto position-relative"
                  type="submit"
                  disabled={loading}
                  onClick={(e) => onSubmit(e)}
                >
                  {loading ? (
                    <span className="spinner-button spinner-center"></span>
                  ) : (
                    'Establecer'
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      </section>
    </Section>
  );
};
/* }; */

const mapStateToProps = (state) => {
  return {
    jwt: state.jwt,
    message: state.error,
  };
};

export default connect(mapStateToProps, {
  resetPasswordAction: resetPasswordStart,
})(ResetPassword);
