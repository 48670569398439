import React, { useState, useEffect } from 'react';
import axios from 'axios';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Section from '../layouts/Section';
import { servicePath } from '../../constants/defaultsValues';
import CalendarStart from '../svg/CalendarStart';
import Loader from '../../components/Loader';
moment.locale('es-mx');

const News = () => {
  const user = useSelector((state) => state.user);
  const token = useSelector((state) => state.jwt);
  let year = null;
  let month = null;
  let newDate = false;
  const apiUrl = `${servicePath}/news`;
  const resident = useSelector((state) => state.resident);
  const address = useSelector((state) => state.address);
  const apiFavoriteNews = `${servicePath}/favorite-news`;
  const [news, setNews] = useState([]);
  const [items, setItems] = useState([]);
  const [favoriteNews, setFavoriteNews] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [filterFavorites, setFilterFavorites] = useState(false);
  const [filterSearch, setFilterSearch] = useState(false);

  async function getFavorites() {
    axios
      .get(`${apiFavoriteNews}?_limit=-1&&user=${user.id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setFavoriteNews(res.data);
      });
  }
  async function fetchData() {
    getFavorites();
    const condominium = resident.addresses
      ? resident.addresses.find((x) => x.id === address).condominium
      : null;
    axios
      .get(
        `${apiUrl}?condominium=${condominium}&_limit=-1&_sort=date:DESC&isPublished=true`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setNews(res.data);
        setItems(res.data);
        setIsLoaded(true);
      });
  }

  const onClickFavorites = (id, action) => {
    if (action === 'add') {
      const data = { new: id, user: user.id };
      axios
        .post(`${apiFavoriteNews}`, data, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then(() => getFavorites());
    } else {
      const idItem = favoriteNews.find((favorite) => favorite.new.id === id).id;
      axios
        .delete(`${apiFavoriteNews}/${idItem}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then(() => getFavorites());
    }
  };

  const onFilterFavorites = () => {
    setFilterFavorites(!filterFavorites);
  };
  const onKeyFilter = (value) => {
    setFilterSearch(value);
  };
  const applyFilters = () => {
    const aux =
      filterSearch && filterSearch !== ''
        ? items.filter((d) => {
            const title = d.title.toLowerCase();
            const description = d.description.toLowerCase();
            const author = d.author.toLowerCase();
            const date = moment(d.date).format('LL');
            const campo = `${date} ${description} ${title} ${author}`;
            const text = filterSearch.toLowerCase();
            return campo.indexOf(text) > 1;
          })
        : items;
    if (!filterFavorites) {
      setNews(aux);
    } else {
      const aux2 = [];
      favoriteNews.forEach((element) =>
        element.new && aux && aux.find((x) => x.id === element.new.id)
          ? aux2.push(aux.find((x) => x.id === element.new.id))
          : null
      );
      setNews(aux2);
    }
  };

  useEffect(() => {
    if (token) {
      fetchData();
    }
  }, [token]);
  useEffect(() => {
    applyFilters();
  }, [filterSearch, filterFavorites]);

  return (
    <Section title={'Noticias'}>
      {isLoaded ? (
        <div className="transaction-area pt-3">
          {/* <div className="container">
            <SelectAddress />
          </div> */}
          <div className="container my-3">
            <div className="col-12">
              <div className="text-center my-4">
                <h3 className="title text-blue">Últimas noticias</h3>
              </div>
              <div className="row mx-auto div-filters">
                <div className="col-7  d-flex new-search input pr-0">
                  <div className="col-2 p-0  d-flex justify-content-end ">
                    <i className="xsmall-icon  icon-search" />
                  </div>
                  <div className="col-10 p-0 d-flex align-items-stretch">
                    <input
                      type="text"
                      placeholder="Buscar"
                      className="form-control border-0 w-100 bg-beige "
                      onKeyUp={(e) => onKeyFilter(e.target.value)}
                    />
                  </div>
                </div>
                <div className="col-5  d-flex new-search pr-0">
                  <button
                    className={`btn p-1 w-100 bg-beige d-flex align-items-center justify-content-around px-2 favorite border-0${
                      filterFavorites ? 'active' : ''
                    }`}
                    onClick={() => onFilterFavorites()}
                  >
                    {!filterFavorites ? (
                      <>
                        Favoritas
                        <i className="small-icon icon-favoritas" />
                      </>
                    ) : (
                      <>
                        Todas
                        <i className="small-icon icon-noticias " />
                      </>
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
          {news.length > 0 ? (
            <>
              <div className="container container-news">
                <div className="col-12">
                  <ul className="transaction-inner list-news row">
                    {news.map((x, key) => {
                      newDate =
                        year &&
                        year === moment(x.date).format('YYYY') &&
                        month &&
                        month === moment(x.date).format('MMMM')
                          ? false
                          : true;
                      year =
                        year && year === moment(x.date).format('YYYY')
                          ? year
                          : moment(x.date).format('YYYY');
                      month =
                        month && month === moment(x.date).format('MMMM')
                          ? month
                          : moment(x.date).format('MMMM');
                      return (
                        <React.Fragment key={key}>
                          {newDate ? (
                            <h6 className="subtitle w-100">
                              {month.charAt(0).toUpperCase()}
                              {month.slice(1)} {year}
                            </h6>
                          ) : null}
                          <li className="ba-single-transaction item-new w-100 align-self-stretch">
                            <Link className="details row" to={`/new/${x.id}`}>
                              <div className="imagen">
                                <img
                                  className="w-100"
                                  src={`${servicePath}${x.image?.url}`}
                                  alt=""
                                />
                              </div>
                              <div className="text">
                                <h5>{x.title}</h5>
                                <p>Creado por {x.author}</p>
                                <p>
                                  {moment(x.date).locale('es').format('LL')}
                                </p>
                                <span>{x.preview}</span>
                              </div>
                            </Link>
                            <div className="icon">
                              {favoriteNews &&
                              favoriteNews.find(
                                (favorite) => favorite.new.id === x.id
                              ) ? (
                                <button
                                  className="border-0 bg-transparent outline-none"
                                  onClick={() =>
                                    onClickFavorites(x.id, 'delete')
                                  }
                                >
                                  <i className="large-icon icon-favoritas" />
                                </button>
                              ) : (
                                <button
                                  className="border-0 bg-transparent outline-none"
                                  onClick={() => onClickFavorites(x.id, 'add')}
                                >
                                  <i className="large-icon icon-no-favoritas" />
                                </button>
                              )}
                            </div>
                          </li>
                        </React.Fragment>
                      );
                    })}
                  </ul>
                </div>
              </div>
              {/* <div className="container text-center mt-5">
                <Link
                  to="/news/create"
                  className="btn btn-new-incidence col-8 col-md-6 col-lg-4 mx-auto d-flex align-items-center justify-content-center text-center"
                >
                  Crear una noticia
                </Link>
              </div> */}
            </>
          ) : (
            <div className="container">
              <div className="col-12 mt-5 pt-5">
                <div className="text-center mt-4">
                  <CalendarStart />
                </div>
                <div className="text-center mt-4">
                  <h3 className="title text-blue">Sin novedades por ahora</h3>
                </div>
              </div>
            </div>
          )}
        </div>
      ) : (
        <Loader />
      )}
    </Section>
  );
};

export default News;
