import React, { useState, useEffect } from 'react';
import axios from 'axios';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Doughnut } from 'react-chartjs-2';
import { Alert } from '../../alertServices';
import { servicePath } from '../../constants/defaultsValues';
import Section from '../../components/layouts/Section';
import Loader from '../../components/Loader';

const apiSurveys = `${servicePath}/surveys`;
const apiQuestions = `${servicePath}/survey-questions`;
const mainColors = ['#3927ab', '#fed65a', '#4741eb'];

const SurveySummary = () => {
  const { id } = useParams();
  const token = useSelector((state) => state.jwt);
  const [isLoaded, setIsLoaded] = useState(false);
  const [data, setData] = useState();
  const [loadingPercent, setLoadingPercent] = useState(0);
  const [timeLeft, setTimeLeft] = useState('');
  const [surveyClosed, setSurveyClosed] = useState(false);

  const calculateTimeLeft = () => {
    const now = moment();
    const end = moment(data?.survey.endDate);

    if (now.isAfter(end)) {
      setSurveyClosed(true);
      setTimeLeft('00:00:00');
    } else {
      const diff = end.diff(now);
      const duration = moment.duration(diff);

      const days = Math.floor(duration.asDays());
      const hours = duration.hours();
      const minutes = duration.minutes();
      const seconds = duration.seconds();

      setTimeLeft(
        `${days > 0 ? days + 'd ' : ''}${hours
          .toString()
          .padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds
          .toString()
          .padStart(2, '0')}`
      );
    }
  };

  const generateColors = (count) => {
    const similarColors = [];
    const hueStep = 360 / count;

    for (let i = 0; i < count; i++) {
      const hue = (i * hueStep) % 360;
      const saturation = 70 + Math.random() * 10;
      const lightness = 50 + Math.random() * 10;
      similarColors.push(`hsl(${hue}, ${saturation}%, ${lightness}%)`);
    }

    return similarColors;
  };

  async function fetchData() {
    setIsLoaded(true);
    try {
      const response = await axios.get(`${apiQuestions}?survey=${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const surveyData = await axios.get(`${apiSurveys}/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const val = {
        survey: surveyData.data,
        questions: response.data,
      };
      setData(val);
      setIsLoaded(false);
    } catch (error) {
      Alert.error({
        title: 'Error al obtener la invitación',
      });
      setIsLoaded(false);
    }
  }

  useEffect(() => {
    if (data) {
      const percent = Math.round(
        (data.survey.voted_addresses.length / data.survey.addresses.length) *
          100
      );

      setLoadingPercent(percent);
    }
  }, [data]);

  useEffect(() => {
    if (data) {
      const interval = setInterval(() => {
        calculateTimeLeft();
      }, 1000);

      return () => clearInterval(interval);
    }
  }, [data]);

  useEffect(() => {
    if (token) {
      fetchData();
    }
  }, [token]);

  return (
    <Section title="Encuestas" cancelTo="/surveys">
      {isLoaded ? (
        <Loader />
      ) : (
        <div className="container my-4" style={{ paddingBottom: '150px' }}>
          <div className="col-12 ">
            <div className="text-center my-4">
              <h3 className="title text-blue">{data?.survey?.title}</h3>
            </div>
            <p className="mb-0 text-16">
              <span className="font-weight-bold text-blue">Descripción: </span>
              <span>{data?.survey.description}</span>
            </p>
          </div>
          <div className="col-12 my-4">
            <div className="row align-items-stretch gap-y-3 ">
              {data?.questions && data?.questions?.length
                ? data?.questions?.map((item, key) => {
                    let chartData = {};
                    let chartOptions = {};

                    if (item.type === 'text') {
                      const responses = item.survey_residents;
                      const uniqueResponses = Array.from(
                        new Set(responses.map((response) => response.text))
                      );

                      const responseFrequency = uniqueResponses.map(
                        (response) => ({
                          response,
                          count: responses.filter((r) => r.text === response)
                            .length,
                        })
                      );

                      chartData = {
                        labels: responseFrequency.map((r) => r.response),
                        datasets: [
                          {
                            data: responseFrequency.map((r) => r.count),
                            backgroundColor:
                              responseFrequency.length <= mainColors.length
                                ? mainColors.slice(0, responseFrequency.length)
                                : [
                                    ...mainColors,
                                    ...generateColors(
                                      responseFrequency.length -
                                        mainColors.length
                                    ),
                                  ],
                          },
                        ],
                      };
                    } else if (item.type === 'radio') {
                      const responses = item.survey_responses;

                      chartData = {
                        labels: responses.map((response) => response.title),
                        datasets: [
                          {
                            data: responses.map(
                              (response) =>
                                item.survey_residents.filter(
                                  (resident) =>
                                    resident.survey_response === response.id
                                ).length
                            ),
                            backgroundColor:
                              responses.length <= mainColors.length
                                ? mainColors.slice(0, responses.length)
                                : [
                                    ...mainColors,
                                    ...generateColors(
                                      responses.length - mainColors.length
                                    ),
                                  ],
                          },
                        ],
                      };
                    }

                    return (
                      <div
                        key={item.id}
                        className="col-12 col-sm-12 col-md-6 col-xl-4 mb-5"
                      >
                        <p className="text-16 font-weight-bold">
                          <span className="text-blue ">
                            Pregunta {key + 1}:
                          </span>{' '}
                          {item.title}
                        </p>
                        <div className="">
                          <Doughnut data={chartData} options={chartOptions} />
                        </div>
                      </div>
                    );
                  })
                : ''}
            </div>
          </div>
        </div>
      )}
      <div className="footer-pay bg-white d-flex justify-content-center text-center mt-4">
        <div className="mt-4">
          <p className="text-blue text-lg text-center font-weight-bold">
            % de Votación
          </p>
          <div
            className="progress"
            style={{ width: '300px', height: '25px', borderRadius: '19px ' }}
          >
            <div
              className="progress-bar"
              role="progressbar"
              style={{
                width: `${loadingPercent}%`,
                backgroundColor: '#3927ab',
              }}
              aria-valuenow={loadingPercent}
              aria-valuemin="0"
              aria-valuemax="100"
            ></div>
          </div>

          <p className="text-16">
            {loadingPercent}% ({data?.survey.voted_addresses.length} votos de{' '}
            {data?.survey?.addresses.length})
          </p>
          <p className="text-16">
            {surveyClosed ? (
              <span className="text-danger">Cerrada</span>
            ) : (
              <span className="text-mint">
                Abierta: ({timeLeft} para cerrar)
              </span>
            )}
          </p>
        </div>
      </div>
    </Section>
  );
};

export default SurveySummary;
