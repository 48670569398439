import React, { useState, useEffect } from 'react';
import axios from 'axios';
import moment from 'moment';
import { Link, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Section from '../layouts/Section';
import io from 'socket.io-client';
import { servicePath } from '../../constants/defaultsValues';
import Loader from '../../components/Loader';

const List = () => {
  const history = useHistory();
  const address = useSelector((state) => state.address);
  const resident = useSelector((state) => state.resident);
  const condominium = useSelector((state) => state.condominium);
  const token = useSelector((state) => state.jwt);

  const apiUpload = `${servicePath}/upload`;
  const apiUrl = `${servicePath}/incidences`;
  const apiIncidences = `${servicePath}/incidences/unit`;
  const apiStatus = `${servicePath}/status-incidences`;
  const [items, setItems] = useState([]);
  const [incidences, setIncidences] = useState([]);
  const [filterStatus] = useState('');
  const [status, setStatus] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);

  async function fetchData() {
    setIsLoaded(false);
    try {
      const statusResponse = await axios.get(`${apiStatus}?_limit=-1`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const itemsResponse = await axios.get(
        `${apiIncidences}/${address}?_limit=-1&_sort=date:DESC&_status=${filterStatus}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setStatus(statusResponse.data);
      setItems(itemsResponse.data);
      setIncidences(itemsResponse.data);
      setIsLoaded(true);
    } catch (error) {
      Alert.error({
        title: 'Ocurrió un problema. Intente nuevamente más tarde.',
      });
      history.push('/');
    }
  }

  useEffect(() => {
    if (token) {
      fetchData();
    }
  }, [token]);

  const applyFilters = (value) => {
    if (value !== '') {
      const aux = items.filter((d) => d.status.id === Number(value));
      setIncidences(aux);
    } else {
      setIncidences(items);
    }
  };
  const handlePushNotification = (incidence) => {
    const socket = io.connect(servicePath);
    socket.emit('notification:incidence-delete', {
      username: `${resident.name} ${resident.lastname}`,
      condominium,
      incidence,
    });
  };

  const deleteFile = async (fileId) => {
    axios
      .delete(`${apiUpload}/files/${fileId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const deleteItem = (id) => {
    setIsLoaded(false);
    axios
      .delete(`${apiUrl}/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        res.data.photo.forEach((p) => {
          deleteFile(p.id);
        });
        handlePushNotification(res.data);
        fetchData();
      });
  };

  return (
    <Section title={'Noticias'}>
      {isLoaded ? (
        <div className="transaction-area pt-3">
          <div className="container my-3">
            <div className="col-12">
              <div className="row mx-auto div-filters align-items-center">
                <div className="col-4 text-center">Estado</div>
                <div className="col-8 p-0 div-select bg-beige p-2">
                  <select
                    className="form-select bg-beige  w-100"
                    defaultValue=""
                    onChange={(e) => applyFilters(e.target.value)}
                  >
                    <option value="">Todas</option>
                    {status.map((state, key) => (
                      <option value={state.id} key={key}>
                        {state.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div className="container pt-2">
            <div className="col-12 text-center text-title">
              Tus incidencias actuales
            </div>
            <div className="transaction-area pt-3 section-incidences">
              <ul className="transaction-inner row align-items-stretch">
                {incidences.map((x, key) => (
                  <li
                    key={key}
                    className="col-12 col-sm-12 col-md-6 col-xl-4 my-2 "
                  >
                    <div className="card-incidences h-100">
                      <div className="w-100">
                        <div
                          className={`status ${
                            x.status?.id === 1 ? 'bg-red' : ''
                          } ${x.status?.id === 2 ? 'bg-orange' : ''} ${
                            x.status?.id === 3 ? 'bg-green' : ''
                          }`}
                        ></div>
                      </div>
                      <div className="col-12 py-2 d-flex">
                        <Link
                          to={`/incidences/monitoring/${x.id}`}
                          className="col-10 p-0 d-flex align-items-center container-text"
                        >
                          <div className="col-10 p-0 d-flex align-items-center container-text">
                            {x.photo
                              ? x.photo.map((p) => {
                                  return (
                                    <div
                                      key={`image-incidence-${p.url}`}
                                      className="my-2"
                                    >
                                      {/* eslint-disable-next-line jsx-a11y/img-redundant-alt */}
                                      <img
                                        src={`${servicePath}${p.url}`}
                                        alt="photo"
                                      />
                                    </div>
                                  );
                                })
                              : ''}
                            <div className="w-70 details px-2 pb-3">
                              <h5>{x.title}</h5>
                              <span>Servicio: {x.service?.name}</span>
                              <br />
                              <span>
                                Creada: {moment(x.date).format('DD')}{' '}
                                {moment(x.date).format('MMMM')}{' '}
                                {moment(x.date).format('YYYY')}
                              </span>{' '}
                              <br />
                              <span>
                                Estatus:{' '}
                                <span
                                  className={`${
                                    x.status?.id === 1 ? 'text-red' : ''
                                  } ${x.status?.id === 3 ? 'text-green' : ''} ${
                                    x.status?.id === 2 ? 'text-orange' : ''
                                  }`}
                                >
                                  {x.status?.name}
                                </span>
                              </span>
                            </div>
                          </div>
                        </Link>
                        <div className="col-2 p-0 d-flex align-items-end flex-column div-icon">
                          <div className="d-flex icons-bottom-incidence justify-content-end">
                            <button
                              type="button"
                              className="btn bg-transparent border-0 p-0 m-0 d-flex align-items-center ml-2"
                              data-toggle="modal"
                              data-target={`#modal-delete-${x.id}`}
                            >
                              <i className="xsmall-icon icon-delete pl-0"></i>
                            </button>
                            {/* MODAL DELETE */}
                            <div
                              id={`modal-delete-${x.id}`}
                              className="modal fade"
                            >
                              <div className="modal-dialog modal-dialog-centered">
                                <div className="modal-content">
                                  <div className="modal-body text-center mt-4 mb-1">
                                    <h4 className="text-blue font-weight-bold">
                                      ¿Seguro que quieres eliminar esta
                                      incidencia?
                                    </h4>
                                  </div>
                                  <div className="modal-footer justify-content-between mx-3">
                                    <button
                                      type="button"
                                      className="btn btn-shadow  my-2"
                                      data-dismiss="modal"
                                    >
                                      Cancelar
                                    </button>
                                    <button
                                      onClick={() => deleteItem(x.id)}
                                      type="button"
                                      className="btn btn-red my-2"
                                      data-dismiss="modal"
                                    >
                                      Confirmar
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="d-flex icons-bottom-incidence justify-content-end mt-auto">
                            {x.comments && x.comments.length ? (
                              <Link
                                to={`/incidences/monitoring/${x.id}`}
                                className="btn bg-transparent border-0 p-0 m-0 d-flex align-items-center  "
                              >
                                <i className="xsmall-icon icon-comments pl-0 mx-auto"></i>
                              </Link>
                            ) : (
                              ''
                            )}
                            {/* <button className="btn bg-transparent border-0 p-0 m-0 d-flex align-items-center ml-2">
                              <i className="xsmall-icon icon-photo pl-0 mx-auto"></i>
                            </button> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
              <div className="container text-center mt-4">
                <Link
                  to="/incidences/create"
                  className="btn btn-new-incidence col-8 col-md-6 col-lg-4 mx-auto d-flex align-items-center justify-content-center text-center"
                >
                  Levantar incidencia
                </Link>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <Loader />
      )}
    </Section>
  );
};

export default List;
