import React, { useState, useEffect } from 'react';
import axios from 'axios';
import moment from 'moment';
import { useSelector } from 'react-redux';
import Section from '../layouts/Section';
import { servicePath } from '../../constants/defaultsValues';
import io from 'socket.io-client';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import Loader from '../../components/Loader';

const apiUpload = `${servicePath}/upload`;
const apiUrl = `${servicePath}/incidences`;
const apiDepartaments = `${servicePath}/departaments`;
const apiServices = `${servicePath}/services`;
const Create = () => {
  const user = useSelector((state) => state.user);
  const resident = useSelector((state) => state.resident);
  const token = useSelector((state) => state.jwt);
  const address = useSelector((state) => state.address);
  const condominium = useSelector((state) => state.condominium);
  const [departaments, setDepartaments] = useState([]);
  const [services, setServices] = useState([]);
  const [data, setData] = useState([]);
  const [photos, setPhotos] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [createdSuccess, setCreateSuccess] = useState(false);
  const [photosError, setPhotosError] = useState('');
  const [condominiumData, setCondominiumData] = useState();
  const [errors, setErrors] = useState({
    title: null,
    departament: null,
    area: null,
    service: null,
    description: null,
  });

  async function fetchData() {
    setIsLoaded(false);
    const condominium = resident.addresses
      ? resident.addresses.find((x) => x.id === address).condominium
      : null;
    setData([]);
    axios
      .get(`${apiDepartaments}?_limit=-1&condominium=${condominium}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setDepartaments(res.data);
        setIsLoaded(true);
      });
  }

  const getServices = async (departament) => {
    await axios
      .get(`${apiServices}?_limit=-1&departament=${departament}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setServices(res.data);
      });
  };

  useEffect(() => {
    if (token) {
      fetchData();
    }
  }, [token]);
  const validateRequired = (value) => {
    let error = null;
    if (!value) {
      error = 'Campo obligatorio';
    }
    return error;
  };
  const validate = () => {
    const validTitle = validateRequired(data.title);
    const validDepartament = validateRequired(data.departament);
    const validService = validateRequired(data.service);
    const validDescription = validateRequired(data.description);
    setErrors({
      title: validTitle,
      departament: validDepartament,
      service: validService,
      description: validDescription,
    });

    return (
      !validTitle && !validDepartament && !validService && !validDescription
    );
  };

  const upload = (file) => {
    const imgs = photos ? photos : [];
    if (file) {
      const bytesToMegaBytes = (bytes) => bytes / 1024 ** 2;
      const size = bytesToMegaBytes(file.size);
      if (
        size > 5 &&
        (file.type !== 'image/jpeg' ||
          file.type !== 'image/png' ||
          file.type !== 'image/gif' ||
          file.type !== 'image/svg+xml' ||
          file.type !== 'image/webp')
      ) {
        setPhotosError('Tiene que ser una imagen menor a 5 MB');
      } else {
        imgs.push({
          file: file,
          name: file.name,
          url: URL.createObjectURL(file),
        });
        setPhotos(imgs);
        setPhotosError('');
        setErrors({
          photos: null,
        });
      }
    }
  };

  const handlePushNotification = (incidenceId) => {
    const socket = io.connect(servicePath);
    socket.emit('notification:new-incidence', {
      username: `${resident.name} ${resident.lastname}`,
      condominium,
      incidence: incidenceId,
    });
  };

  const uploadFile = async (file, incidenceId) => {
    const bodyFormData = new FormData();
    bodyFormData.append('files', file, `${file.name}`);
    bodyFormData.append('ref', 'incidence');
    bodyFormData.append('refId', incidenceId);
    bodyFormData.append('field', 'photo');
    const saveDocument = await axios({
      method: 'post',
      url: `${apiUpload}`,
      data: bodyFormData,
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${token}`,
      },
    });
    return saveDocument;
  };

  const onSubmit = () => {
    setIsLoaded(false);
    const values = {
      ...data,
      date: new Date(),
      status: 1,
      condominium: resident.addresses.find((r) => r.id === address).condominium,
      address,
      user: user.id,
    };
    axios
      .post(`${apiUrl}`, values, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (photos) {
          photos.forEach(async (p) => {
            await uploadFile(p.file, res.data.id);
          });
        }
        setCondominiumData(res.data.condominium);
        setCreateSuccess(true);
        handlePushNotification(res.data.id);
        setIsLoaded(true);
      });
  };
  return (
    <Section
      title={'Incidencias'}
      withBack={true}
      noBorder={true}
      cancelTo="/incidences"
    >
      {isLoaded ? (
        <>
          {!createdSuccess ? (
            <div className="transaction-area pt-5 ">
              <div className="container pt-4">
                <div className="col-8 mx-auto text-center text-title">
                  Carga la información relevante para tu Incidencia
                </div>
                <div className="col-12 col-lg-8 mx-auto section form pt-5">
                  <div className="row mb-4">
                    <div className="col-3 text-center">
                      <i className="small-icon icon-name" />
                    </div>
                    <div className="col-9">
                      <input
                        className="w-100 bg-beige border-0"
                        placeholder="Título de la incidencia"
                        onKeyUp={(e) =>
                          setData({ ...data, title: e.target.value })
                        }
                      />{' '}
                      {errors.title ? (
                        <span className="text-danger">{errors.title}</span>
                      ) : null}
                    </div>
                  </div>
                  <div className="row my-4 align-items-end">
                    <div className="col-3 text-center p-0">
                      <i className="large-icon mi-cuenta " />
                    </div>
                    <div className="col-9 ">
                      <p>Selecciona un departamento</p>
                      <div className="div-select col-12 bg-beige">
                        <select
                          className="form-select  w-100 bg-beige"
                          defaultValue=""
                          onChange={(e) => {
                            setData({
                              ...data,
                              departament: Number(e.target.value),
                              service: '',
                            });
                            getServices(e.target.value);
                          }}
                        >
                          <option value="">Seleccionar...</option>
                          {departaments.map((x, key) => (
                            <option value={x.id} key={key}>
                              {x.name}
                            </option>
                          ))}
                        </select>
                      </div>
                      {errors.departament ? (
                        <span className="text-danger">
                          {errors.departament}
                        </span>
                      ) : null}
                    </div>
                  </div>
                  <div className="row my-4 align-items-end">
                    <div className="col-3 text-center">
                      <i className="small-icon icon-services" />
                    </div>
                    <div className="col-9 ">
                      <p>Categoria del servicio</p>
                      <div className="div-select col-12 bg-beige">
                        <select
                          className="form-select  w-100 bg-beige"
                          value={data.service}
                          onChange={(e) =>
                            setData({
                              ...data,
                              service: Number(e.target.value),
                            })
                          }
                        >
                          <option value="">Seleccionar...</option>
                          {services.map((x, key) => (
                            <option value={x.id} key={key}>
                              {x.name}
                            </option>
                          ))}
                        </select>
                      </div>
                      {errors.service ? (
                        <span className="text-danger">{errors.service}</span>
                      ) : null}
                    </div>
                  </div>
                  <div className="row my-4 align-items-end">
                    <div className="col-9 ml-auto">
                      <p>Descripción de la incidencia</p>
                    </div>
                    <div className="col-12">
                      <textarea
                        style={{ height: '240px' }}
                        className="form-control w-100 bg-beige border-0 p-4"
                        placeholder="Describe aquí tu reporte…"
                        onKeyUp={(e) =>
                          setData({ ...data, description: e.target.value })
                        }
                      ></textarea>
                      {errors.description ? (
                        <span className="text-danger">
                          {errors.description}
                        </span>
                      ) : null}
                    </div>
                  </div>
                  {/* {/* {photos && (
                <Carousel
                  photos={photos}
                  servicePath={servicePath}
                />
              )} */}
                  <div className="row my-4 align-items-end">
                    <div className="col-12 text-center">
                      <Carousel
                        dynamicHeight
                        emulateTouch
                        infiniteLoop
                        showThumbs={false}
                        preventMovementUntilSwipeScrollTolerance
                        selectedItem={photos?.length - 1 || 0}
                        statusFormatter={(current, total) =>
                          `${current} / ${total}`
                        }
                        className="mt-5"
                      >
                        {photos
                          ? photos.map((p) => {
                              return (
                                <div key={p.url}>
                                  <img
                                    src={p.url}
                                    alt={`content set on ${p.url}`}
                                  />
                                </div>
                              );
                            })
                          : ''}
                      </Carousel>
                      {photosError ? (
                        <span className="text-danger">{photosError}</span>
                      ) : (
                        ''
                      )}

                      <label
                        htmlFor="picture"
                        className="btn bg-transparent border-0 d-flex align-items-center mx-auto btn-photo"
                      >
                        <i className="small-icon icon-photo pl-0 mr-2" /> Cargar
                        una foto
                      </label>
                      <input
                        id="picture"
                        className="d-none"
                        accept="image/*"
                        type="file"
                        onChange={(e) => upload(e.target.files[0])}
                      />
                    </div>
                  </div>
                </div>
                <div className="container text-center my-4">
                  <button
                    onClick={(e) => {
                      if (validate()) {
                        onSubmit();
                      }
                    }}
                    className="btn btn-new-incidence col-8 col-md-6 col-lg-4 mx-auto d-flex align-items-center justify-content-center text-center "
                  >
                    Levantar incidencia
                  </button>
                </div>
              </div>
            </div>
          ) : (
            <Resumen
              data={data}
              photos={photos}
              condominium={condominiumData}
            />
          )}
        </>
      ) : (
        <Loader />
      )}
    </Section>
  );
};

const Resumen = ({ data, photos, condominium }) => {
  return (
    <div className="transaction-area pt-5 resumen-incidences ">
      <div className="container pt-5">
        <div className="col-8 mx-auto text-center text-title">
          <i className="xlarge-icon icon-generada"></i>
        </div>
        <div className="col-10 mx-auto text-center mt-4">
          <h4>¡Incidencia generada!</h4>
        </div>
        <div className="col-12 mx-auto text-center my-4">
          <span>Resumen de tu incidencia</span>
        </div>
        <div className="col-12 mx-auto  px-3   text-center">
          <div className="card w-100 py-5 px-3">
            <p>{data.title}</p>
            <span>{data.service?.name}</span>
            <p>{moment(new Date()).format('LL')}</p>
            <span>{data?.area?.name}</span>
            <Carousel
              dynamicHeight
              emulateTouch
              infiniteLoop
              showThumbs={false}
              preventMovementUntilSwipeScrollTolerance
              statusFormatter={(current, total) => `${current} / ${total}`}
              className="mt-5"
            >
              {photos
                ? photos?.map((p) => {
                    return (
                      <div key={p.url}>
                        <img src={p.url} alt={`content set on ${p.url}`} />
                      </div>
                    );
                  })
                : ''}
            </Carousel>
            <span className="mt-5 ">{data.description}</span>
          </div>
        </div>
        <div className="col-10 mx-auto py-5  ">
          <small>
            Tu incidencia fue generada con éxito y será Atendidas por el
            departamento correspondiente, mantente al tanto de tus
            notificaciones para conocer el proceso de resolución , si por algún
            motivo no atienden tu reporte en el tiempo deseado llama al
            teléfono:
          </small>
          <p className="mt-4 text-center text-blue">{condominium.phone}</p>
        </div>
        <div className="container text-center">
          <Link
            to="/incidences"
            className="btn btn-new-incidence col-8 mx-auto d-flex align-items-center justify-content-center text-center "
          >
            Terminar
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Create;
