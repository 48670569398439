import React from 'react';
// import Footer from "../layouts/Footer";
// import Navbar from "../layouts/Navbar";
import Section from '../layouts/Section';
import { Link } from 'react-router-dom';
// import Signin from "../user/login";
// import AutoNotification from "../layouts/AutoNotification";
// import SelectAddress from "../layouts/SelectAddress";
import { connect } from 'react-redux';

const Home = ({ state }) => {
  return (
    <>
      {/* Navigation Bar Imported from Layouts  <Navbar />
       Auto Notification Imported from Layout
      <AutoNotification /> */}
      <Section title={'Home'}>
        <div className="container">
          <div className="row">
            <div className="col-12 text-center pt-4 mb-3">
              <span className="text-welcome">
                Bienvenido {state.resident.name}!
              </span>
            </div>
          </div>
          {/* <SelectAddress /> */}
          <div className="row m-0 align-items-stretch cards">
            <div className="card-1">
              <Link to="/payments" className="btn card-dashboard">
                <div className="row justify-content-center align-items-center">
                  <i className="large-icon icon-pagos" />
                  <span>Pagos</span>
                </div>
              </Link>
            </div>
            <div className="card-2">
              <Link to="/reservations" className="btn card-dashboard">
                <div className="row  justify-content-center align-items-center">
                  <i className="large-icon icon-reservaciones " />
                  <span>Reservaciones</span>
                </div>
              </Link>
            </div>
            <div className="card-2">
              <Link to="/news" className="btn card-dashboard">
                <div className="row justify-content-center align-items-center">
                  <i className="large-icon icon-noticias" />
                  <span>Noticias</span>
                </div>
              </Link>
            </div>
            <div className="card-1">
              <Link to="/incidences" className="btn card-dashboard">
                <div className="row justify-content-center align-items-center">
                  <i className="large-icon icon-incidencias" />
                  <span>Incidencias</span>
                </div>
              </Link>
            </div>
            <div className="card-1">
              <Link to="/surveys" className="btn card-dashboard">
                <div className="row justify-content-center align-items-center">
                  <i className="large-icon icon-encuestas" />
                  <span>Encuestas</span>
                </div>
              </Link>
            </div>
            <button
              type="button"
              className="card-2 border-0 bg-transparent d-none"
              data-toggle="tooltip"
              data-placement="top"
              title="PRÓXIMAMENTE"
            >
              <Link to="#" className="btn disabled card-dashboard">
                <div className="row justify-content-center align-items-center">
                  <i className="large-icon icon-panic" />
                  <span>BOTÓN DE PÁNICO</span>
                </div>
              </Link>
            </button>
            {state.transparency && (
              <div className="card-2">
                <Link to="/transparency" className="btn card-dashboard">
                  <div className="row justify-content-center align-items-center">
                    <i className="large-icon icon-transparencia" />
                    <span>Transparencia</span>
                  </div>
                </Link>
              </div>
            )}
            <button
              type="button"
              className="card-1 border-0 bg-transparent"
              data-toggle="tooltip"
              data-placement="top"
              title="PRÓXIMAMENTE"
            >
              <Link to="/invitations" className="btn card-dashboard">
                <div className="row justify-content-center align-items-center">
                  <i className="large-icon icon-invitaciones" />
                  <span>Invitaciones</span>
                </div>
              </Link>
            </button>
            <button
              type="button"
              className="card-1 border-0 bg-transparent d-none"
              data-toggle="tooltip"
              data-placement="top"
              title="PRÓXIMAMENTE"
            >
              <Link to="#" className="btn disabled card-dashboard">
                <div className="row  justify-content-center align-items-center">
                  <i className="large-icon icon-reservaciones " />
                  <span>Eventos</span>
                </div>
              </Link>
            </button>
            <button
              type="button"
              className="card-1 border-0 bg-transparent d-none"
              data-toggle="tooltip"
              data-placement="top"
              title="PRÓXIMAMENTE"
            >
              <Link to="#" className="btn disabled card-dashboard">
                <div className="row  justify-content-center align-items-center">
                  <i className="large-icon icon-directorio" />
                  <span>Directorio</span>
                </div>
              </Link>
            </button>
            <button
              type="button"
              className="card-1 border-0 bg-transparent d-none"
              data-toggle="tooltip"
              data-placement="top"
              title="PRÓXIMAMENTE"
            >
              <Link to="#" className="btn disabled card-dashboard">
                <div className="row justify-content-center align-items-center">
                  <i className="large-icon icon-votaciones" />
                  <span>Votaciones</span>
                </div>
              </Link>
            </button>
            <div className="card-1">
              <Link to="/offers" className="btn card-dashboard">
                <div className="row justify-content-center align-items-center">
                  <i className="large-icon icon-offer" />
                  <span>Ofertas</span>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </Section>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    state: state,
  };
};

export default connect(mapStateToProps)(Home);
