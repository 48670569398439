import React from 'react';
import Section from '../layouts/Section';
import { Link } from 'react-router-dom';

const Welcome = () => {
  return (
    <Section
      allNotification={false}
      searchPopup={true}
      title={'welcome'}
      disabledHeader={true}
      disabledFooter={true}
    >
      <section
        className="section-login"
        style={{
          backgroundImage:
            'url(' +
            process.env.PUBLIC_URL +
            '/assets/img/Fondo-login.png' +
            ')',
          backgroundSize: '100% 100%',
          minHeight: '100%',
          position: 'absolute',
          width: '100%',
        }}
      >
        <div
          className="text-center title"
          style={{ paddingTop: '60%', paddingBottom: '50%' }}
        >
          <div className="col-md-4 col-5 mx-auto mb-4">
            <img
              src={process.env.PUBLIC_URL + '/assets/img/civity-logo.svg'}
              alt="civity"
            />
          </div>
          <div className="col-12 text-center">
            <h4 className="text-center">¡Bienvenido!</h4>
          </div>
        </div>

        <div
          className="bottom bottom-register"
          style={{ paddingBottom: '35%' }}
        >
          <Link to="/">
            <span style={{ fontWeight: '400' }}>Comenzar a usar civity</span>
          </Link>
        </div>
      </section>
    </Section>
  );
};

export default Welcome;
