import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import moment from 'moment/moment';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { servicePath } from '../../constants/defaultsValues';
import io from 'socket.io-client';
import { Alert } from '../../alertServices';
import { Camera } from '@capacitor/camera';
import PaymentList from './paymentList';
import Section from '../layouts/Section';
import Loader from '../../components/Loader';

const today = moment().format('YYYY-MM-DD');
const apiUpload = `${servicePath}/upload`;
const apiQuotas = `${servicePath}/quotas`;

function RegisterPayment() {
  const condominiumId = useSelector((s) => s.condominium);
  const user = useSelector((s) => s.user);
  const address = useSelector((s) => s.address);
  const resident = useSelector((s) => s.resident);
  const token = useSelector((s) => s.jwt);
  const [isLoaded, setIsLoaded] = useState(true);
  const [errors, setErrors] = useState({
    title: null,
    departament: null,
    area: null,
    service: null,
    description: null,
    quotas: null,
  });
  const [isSend, setIsSend] = useState(false);
  const [name, setName] = useState('');
  const [unit, setUnit] = useState('');
  const [date, setDate] = useState('');
  const [paymentMethod, setPaymentMethod] = useState('');
  const [id, setId] = useState('');
  const [idMovement, setIdMovement] = useState('');
  const [comment, setComment] = useState('');
  const [amount, setAmount] = useState('');
  const [image, setImage] = useState(null);
  const [isMobile, setIsMobile] = useState(false);
  const [hasPermissions, setHasPermissions] = useState(false);
  const [quotasList, setQuotasList] = useState(null);
  const [quotas, setQuotas] = useState([]);

  const inputRef = useRef(null);

  useEffect(() => {
    setIsMobile(
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
    );
  }, []);

  const handleIconClick = (event) => {
    event.preventDefault();
    if (inputRef.current) {
      inputRef.current.click();
    }
  };

  async function fetchData() {
    try {
      const addressResponse = await axios.get(
        `${servicePath}/addresses/${address}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const quotasResponse = await axios.get(
        `${apiQuotas}?address=${address}&isActive=true&canceled=false&isCancellation=false&pending_gt=0&_limit=-1&_sort=expirationDate:DESC`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setQuotasList(quotasResponse.data);
      setUnit(addressResponse.data);
      setName(
        `${addressResponse.data?.name} ${user.resident?.name} ${user.resident?.lastname}`
      );
    } catch (error) {
      console.error(error);
    }
  }
  const checkPermission = async () => {
    const cameraPermissions = await Camera.checkPermissions();
    if (
      cameraPermissions.camera === 'granted' &&
      cameraPermissions.photos === 'granted'
    ) {
      setHasPermissions(true);
    } else {
      Camera.requestPermissions().then((permissionsGranted) => {
        if (
          permissionsGranted.camera === 'granted' &&
          permissionsGranted.photos === 'granted'
        ) {
          setHasPermissions(true);
        }
      });
    }
  };

  const upload = async (file) => {
    const imgs = [];

    if (file) {
      const fileSizeInMB = file.size / (1024 * 1024);
      if (fileSizeInMB > 5) {
        Alert.error({
          title: 'El tamaño de la imagen debe ser menor a 5 MB',
          position: 'toast-top-right',
        });
      } else {
        imgs.push({
          file: file,
          name: file.name,
          url: URL.createObjectURL(file),
        });
        setImage(imgs[0]);
      }
    }
  };

  const uploadFile = async (file, id) => {
    const bodyFormData = new FormData();
    bodyFormData.append('files', file, `${file.name}`);
    bodyFormData.append('ref', 'transactions');
    bodyFormData.append('refId', id);
    bodyFormData.append('field', 'image');
    const saveDocument = await axios({
      method: 'post',
      url: `${apiUpload}`,
      data: bodyFormData,
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${token}`,
      },
    });
    return saveDocument;
  };

  const validateRequired = (value, errorMessage = 'Campo obligatorio') => {
    let error = null;
    if (!value || (Array.isArray(value) && value.length === 0)) {
      error = errorMessage;
    }
    return error;
  };
  const validate = () => {
    const validUnit = validateRequired(address);
    const validDate = validateRequired(date);
    const validPaymentMethod = validateRequired(paymentMethod);
    const validAmount = validateRequired(amount);
    const validQuotas = validateRequired(
      quotas,
      'Debes seleccionar al menos un cargo'
    );

    setErrors({
      unit: validUnit,
      date: validDate,
      paymentMethod: validPaymentMethod,
      amount: validAmount,
      quotas: validQuotas,
    });
    return !validUnit &&
      !validDate &&
      !validPaymentMethod &&
      !validAmount &&
      !validQuotas
      ? true
      : false;
  };

  const handlePushNotification = () => {
    const socket = io.connect(servicePath);
    socket.emit('notification:register-payment', {
      username: `${resident.name} ${resident.lastname}`,
      condominium: condominiumId,
    });
  };

  const onSubmit = () => {
    setIsLoaded(false);
    const data = {
      type: 'Ingress',
      condominium: condominiumId,
      address,
      name: 'Ingreso Manual App',
      date: moment(date),
      amount: amount
        ? Number(
            typeof amount === 'string' ? amount.replace(/\$|,/g, '') : amount
          )
        : null,
      idCharge: id,
      editor: user.id,
      resident: user.resident.id,
      canceled: false,
      verified: false,
      comment,
      paymentMethod,
      quotas,
    };
    axios
      .post(`${servicePath}/transactions/id-movement`)
      .then((res) => {
        setIdMovement(res.data);
        axios
          .post(
            `${servicePath}/transactions`,
            {
              idMovement: `I-${res.data}`,
              ...data,
            },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((res) => {
            if (image) {
              uploadFile(image.file, res.data.id);
            }
            setIsSend(true);
            setIsLoaded(true);
            handlePushNotification(res.data.id);
          })
          .catch((err) => {
            console.log(err);
            setIsLoaded(true);
          });
      })
      .catch((err) => {
        console.log(err);
        setIsLoaded(true);
      });
  };

  useEffect(() => {
    fetchData();
    checkPermission();
  }, []);

  return (
    <Section
      title={'Registra tu pago'}
      withBack={true}
      noBorder={true}
      // cancelTo="/payments"
    >
      {isLoaded ? (
        <>
          {!isSend ? (
            <>
              <div className="text-center pt-5">
                <Link
                  to="/payments/how-to-pay"
                  className="btn bg-blue font-weight-bold"
                >
                  ¿Cómo pagar?
                </Link>
              </div>
              <form onSubmit={onSubmit} className="transaction-area pt-5">
                <div className="col-12 col-lg-8 mx-auto pb-1">
                  {errors.quotas ? (
                    <span className="text-danger text-center">
                      {errors.quotas}
                    </span>
                  ) : null}
                </div>
                <PaymentList
                  data={quotasList}
                  quotas={quotas}
                  setQuotas={setQuotas}
                  setAmount={setAmount}
                ></PaymentList>

                <div className="col-12 col-lg-8 mx-auto section form pt-5">
                  <div className="mb-4">
                    <p>Unidad</p>
                    <input
                      id="unit"
                      className="w-100 bg-beige border-0"
                      placeholder={name}
                      values={unit.id}
                      disabled
                    />{' '}
                    {errors.unit ? (
                      <span className="text-danger text-center pt-5">
                        {errors.unit}
                      </span>
                    ) : null}
                  </div>
                  <div className="my-4">
                    <div className="">
                      <p>Fecha de pago*</p>
                      <div className="input-container">
                        <input
                          ref={inputRef}
                          className="w-100 bg-beige border-0 date-input"
                          type="date"
                          value={date}
                          onChange={(e) => setDate(e.target.value)}
                          max={today}
                          style={{
                            /* '-webkit-appearance': 'none',
                            '-moz-appearance': 'none', */
                            appearance: 'none',
                          }}
                        />
                        {isMobile && (
                          <button
                            onClick={(e) => handleIconClick(e)}
                            className="calendar-icon"
                          >
                            <img
                              src={
                                process.env.PUBLIC_URL +
                                '/assets/img/icon/calendar.svg'
                              }
                              alt="Calendar Icon"
                            />
                          </button>
                        )}
                      </div>
                      {errors.date ? (
                        <span className="text-danger">{errors.date}</span>
                      ) : null}
                    </div>
                  </div>
                  <div className="my-4 align-items-end">
                    <div className="">
                      <p>Método de pago*</p>
                      <div className="div-select col-12 bg-beige">
                        <select
                          className="form-select pl-0 pr-2 w-100 bg-beige"
                          // defaultValue=""
                          onChange={(e) => setPaymentMethod(e.target.value)}
                        >
                          <option value="">Seleccionar</option>
                          <option value="Efectivo">Efectivo</option>
                          <option value="Tarjeta">Tarjeta</option>
                          <option value="Transferencia">Transferencia</option>
                          <option value="Cheque">Cheque</option>
                        </select>
                      </div>
                      {errors.paymentMethod ? (
                        <span className="text-danger">
                          {errors.paymentMethod}
                        </span>
                      ) : null}
                    </div>
                  </div>
                  <div className="my-4">
                    <div>
                      <p>Monto*</p>
                      <input
                        className="w-100 bg-beige border-0"
                        type="number"
                        placeholder="$0.00"
                        value={amount}
                        onChange={(e) => setAmount(e.target.value)}
                      />
                      {errors.amount ? (
                        <span className="text-danger">{errors.amount}</span>
                      ) : null}
                    </div>
                  </div>
                  {/* <div className="my-4">
                  <div>
                    <p>ID Transacción/Aprobación</p>
                    <input
                      className="w-100 bg-beige border-0"
                      type="text"
                      onChange={(e) => setId(e.target.value)}
                    />
                    {errors.id ? (
                      <span className="text-danger">{errors.id}</span>
                    ) : null}
                  </div>
                </div> */}
                  <div className="my-4 align-items-end">
                    <div className="ml-auto">
                      <p>Comentarios</p>
                    </div>
                    <div className="">
                      <textarea
                        style={{ height: '240px' }}
                        className="form-control w-100 bg-beige border-0 p-4"
                        onChange={(e) => setComment(e.target.value)}
                      />
                      {errors.comment ? (
                        <span className="text-danger">{errors.comment}</span>
                      ) : null}
                    </div>
                  </div>
                </div>
                <div className="container text-center my-4">
                  {hasPermissions ? (
                    <label
                      style={{ display: 'revert' }}
                      htmlFor="picture"
                      className="btn btn-short p-3"
                    >
                      {image
                        ? image.name.length > 20
                          ? `${image.name.substring(0, 20)}`
                          : image.name
                        : 'Adjunta una imagen'}
                    </label>
                  ) : (
                    <button
                      type="button"
                      className="btn btn-short p-3"
                      onClick={() => checkPermission()}
                      style={{ cursor: 'pointer' }}
                    >
                      Adjunta una imagen
                    </button>
                  )}

                  <input
                    id="picture"
                    className="d-none"
                    accept="image/png, image/jpg"
                    type="file"
                    onChange={(e) =>
                      hasPermissions && upload(e.target.files[0])
                    }
                  />
                </div>
                <div
                  className="d-flex align-items-center justify-content-between border-top text-center py-3 mt-4 px-4"
                  style={{ border: '5px', border: 'black' }}
                >
                  <Link to="/payments/options">
                    <button
                      type="button"
                      className="btn btn-short bg-beige text-dark"
                    >
                      Cancelar
                    </button>
                  </Link>
                  <button
                    type="button"
                    onClick={() => {
                      if (validate()) {
                        onSubmit();
                      }
                    }}
                    className="btn btn-short"
                  >
                    Aceptar
                  </button>
                </div>
              </form>
            </>
          ) : (
            <div className="text-center pt-5 transaction-area">
              <div className="h-100">
                <img
                  src="./assets/img/payments/Pago_exitoso.svg"
                  alt="success"
                  className="w-30"
                />
                <h1 className="text-blue" style={{ fontSize: '45px' }}>
                  ¡Pago <br /> enviado!
                </h1>
                <h6 className="mt-4 text-blue ">
                  Haz enviado tu comprobante
                  <br />
                  de pago correctamente
                </h6>
                <p className="mt-4">
                  A la brevedad se verificará la información
                  <br />
                  para actualizar el estado de tus cargos.
                </p>
                {idMovement ? (
                  <>
                    <h6 className="mt-3">ID de transacción</h6>
                    <h6 className="text-blue">{idMovement}</h6>
                  </>
                ) : (
                  ''
                )}
              </div>
              <div
                className="d-flex align-items-center justify-content-center border-top text-center py-3 px-4"
                style={{ border: '5px', border: 'black', marginTop: '180px' }}
              >
                <Link to="/payments">
                  <button type="button" className="btn btn-short">
                    Terminar
                  </button>
                </Link>
              </div>
            </div>
          )}
        </>
      ) : (
        <Loader />
      )}
    </Section>
  );
}

export default RegisterPayment;
