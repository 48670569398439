import {
  all,
  // call,
  fork,
  put,
  // takeLatest,
  takeEvery,
} from 'redux-saga/effects';
// import axios from "axios";
// import { servicePath } from "../../constants/defaultsValues";
import { CHANGE_ADDRESS } from '../actions';
// import { Redirect } from "react-router-dom";

import { changeAddress } from './actions';

function* changeAddressAction(payload) {
  console.log('changeAddressAction', payload);
  const address = payload.payload.address;

  try {
    if (address) {
      yield put(changeAddress(address));
    } else {
      console.log('error');
    }
  } catch (error) {
    console.log(error);
  }
}
export function* watchChangeAddress() {
  yield takeEvery(CHANGE_ADDRESS, changeAddressAction);
}

export default function* rootSaga() {
  yield all([fork(watchChangeAddress)]);
}
