import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';

const daysOfWeek = [
  { key: 7, value: 'D' },
  { key: 1, value: 'L' },
  { key: 2, value: 'M' },
  { key: 3, value: 'X' },
  { key: 4, value: 'J' },
  { key: 5, value: 'V' },
  { key: 6, value: 'S' },
];
const InvitationsForm = ({ onSubmit, isLoading, data }) => {
  const address = useSelector((state) => state.address);
  const condominium = useSelector((state) => state.condominium);
  const [name, setName] = useState('');
  const [type, setType] = useState('guest');
  const [isRecurrent, setIsRecurrent] = useState(false);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [startTime, setStartTime] = useState('');
  const [endTime, setEndTime] = useState('');
  const [days, setDays] = useState([]);
  const [never, setNever] = useState(false);
  const [errors, setErrors] = useState({
    name: false,
    startDate: false,
    endDate: false,
    days: false,
  });

  const toggleDay = (day) => {
    setDays((prevDays) =>
      prevDays.includes(day)
        ? prevDays.filter((d) => d !== day)
        : [...prevDays, day]
    );
  };

  const handleEndDate = (value) => {
    setEndDate(value);
    if (value && never) {
      setNever(false);
    }
  };

  const handleNever = (e) => {
    const { checked } = e.target;
    setNever(checked);
    if (checked) {
      setEndDate('');
    }
  };

  const handleStartTime = (value) => {
    setStartTime(value);
    if (moment(value, 'hh:mm A').isAfter(moment(endTime, 'hh:mm A'))) {
      setEndTime('');
    }
  };

  const handleEndTime = (value) => {
    setEndTime(value);
    if (moment(value, 'hh:mm A').isBefore(moment(startTime, 'hh:mm A'))) {
      setStartTime('');
    }
  };

  const validate = async () => {
    const newErrors = {};
    if (!name) newErrors.name = true;
    if (!startDate) newErrors.startDate = true;
    if (isRecurrent && days.length === 0) newErrors.days = true;
    setErrors(newErrors);

    if (Object.values(newErrors).every((value) => !value)) {
      const startTimestamp = startDate ? moment(startDate).valueOf() : null;
      const endTimestamp = endDate ? moment(endDate).valueOf() : null;
      const startTimeET = startTime
        ? moment(startTime, 'hh:mm').format('HH:mm:ss.SSS')
        : null;
      const endTimeET = endTime
        ? moment(endTime, 'hh:mm').format('HH:mm:ss.SSS')
        : null;

      const values = {
        name,
        type,
        address,
        condominium,
        startDate: startTimestamp,
        endDate: endTimestamp,
        startTime: startTimeET,
        endTime: endTimeET,
        recurrent: isRecurrent,
        days,
        isNever: never,
      };
      await onSubmit(values);
    }
  };

  useEffect(() => {
    if (!isRecurrent) {
      setDays([]);
      setNever(false);
      setEndDate('');
    } else {
      setNever(true);
    }
  }, [isRecurrent]);

  useEffect(() => {
    if (data) {
      const isNever = data.recurrent && !data.endDate;
      const mappedDays = data.days.map((day) => day.id);
      setDays();
      setName(data.name);
      setType(data.type);
      setIsRecurrent(data.recurrent);
      setStartDate(moment(data.startDate).format('YYYY-MM-DD'));
      setEndDate(moment(data.endDate).format('YYYY-MM-DD'));
      setStartTime(data.startTime);
      setEndTime(data.endTime);
      setDays(mappedDays);
      setNever(isNever);
    }
  }, [data]);

  return (
    <>
      <div className="col-12 col-lg-8 mx-auto section form my-5">
        <div className="row mb-4 align-items-end">
          <div className="col-2 d-flex align-items-center p-0 justify-content-center mb-2">
            <i className="small-icon mi-cuenta" />
          </div>

          <div className="col-10 pl-0">
            <p>Nombre del visitante*</p>
            <input
              id="name"
              className={`w-100 bg-beige ${
                errors.name ? 'border-danger' : 'border-0'
              }`}
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
        </div>
        <div className="row mb-4 align-items-end">
          <div className="col d-flex align-items-center p-0 justify-content-center mb-1">
            <i className="icon-visit-type" />
          </div>

          <div className="col-10 pl-0">
            <p>Tipo de visita*</p>
            <div className="div-select col-12 bg-beige">
              <select
                className="form-select  w-100 bg-beige"
                value={type}
                onChange={(e) => setType(e.target.value)}
              >
                <option value="guest">Invitado</option>
                <option value="delivery">Repartidor</option>
                <option value="maintenance">Mantenimiento</option>
                <option value="other">Otro</option>
              </select>
            </div>
          </div>
        </div>
        <div className="mb-4">
          <label className="toggle">
            <p className="toggle-label ml-0">Recurrente</p>
            <input
              className="toggle-checkbox check-blue"
              type="checkbox"
              checked={isRecurrent}
              onChange={() => setIsRecurrent(!isRecurrent)}
            />
            <div className="toggle-switch"></div>
          </label>
        </div>
        {isRecurrent && (
          <div className="text-center mb-3">
            <div className="mb-1 d-flex justify-content-between">
              {daysOfWeek.map((day) => (
                <label key={day.value} className="checkbox-circle">
                  <input
                    type="checkbox"
                    checked={days.includes(day.key)}
                    onChange={() => toggleDay(day.key)}
                  />
                  <span>{day.value}</span>
                </label>
              ))}
            </div>
            {errors.days && (
              <small className="text-danger ">
                Debe seleccionar al menos un día recurrente*
              </small>
            )}
          </div>
        )}
        <div className="row mb-4 justify-content-center">
          <p>
            {isRecurrent
              ? 'Comienzo de invitación*'
              : 'Vigencia de la invitación*'}
          </p>
          <div className="custom-date-input">
            <input
              type="date"
              id="startDate"
              min={moment().format('YYYY-MM-DD')}
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
              className={`${errors.startDate ? 'border-danger' : 'border-0'}`}
            />
            <i className="small-icon icon-visit-date" />
          </div>
        </div>
        <div className="row mb-4 justify-content-center">
          <div className="col-12 text-center">
            <p className="time-label">Horarios de la visita</p>
            <div className="d-flex justify-content-center">
              <div className="col-6 pl-0">
                <div className="custom-time-input">
                  <input
                    type="time"
                    id="startTime"
                    onChange={(e) => handleStartTime(e.target.value)}
                    value={startTime}
                  />
                  <i className="icon-visit-hour" />
                </div>
              </div>
              <div className="col-6 pr-0">
                <div className="custom-time-input">
                  <input
                    type="time"
                    id="endTime"
                    onChange={(e) => handleEndTime(e.target.value)}
                    value={endTime}
                  />
                  <i className="icon-visit-hour" />
                </div>
              </div>
            </div>
          </div>
        </div>
        {isRecurrent && (
          <div className="row mb-4 justify-content-center">
            <div className="col-12 text-center">
              <p>Finaliza</p>
              <label className="toggle">
                <p className="toggle-label ml-0">Nunca</p>
                <input
                  className="toggle-checkbox check-blue"
                  type="checkbox"
                  onChange={handleNever}
                  checked={never}
                />
                <div className="toggle-switch"></div>
              </label>
              <div className="row mb-2 d-flex align-items-center m-0 justify-content-between">
                <div className="">
                  <p className="mb-0 text-align-start">El:</p>
                </div>
                <div className="col-8">
                  <div className="custom-date-input">
                    <input
                      type="date"
                      id="endDate"
                      min={moment().format('YYYY-MM-DD')}
                      onChange={(e) => handleEndDate(e.target.value)}
                      value={endDate}
                    />
                    <i className="small-icon icon-visit-date" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      <div className="footer-pay bg-white d-flex justify-content-center text-center mt-4">
        <button
          className="btn btn-new-incidence w-70 d-flex align-items-center justify-content-center px-5 position-relative text-center"
          onClick={validate}
          disabled={isLoading}
        >
          {isLoading ? (
            <span className="spinner-button spinner-lg"></span>
          ) : (
            'Guardar'
          )}
        </button>
      </div>
    </>
  );
};
export default InvitationsForm;
