import React, { useState } from 'react';
import Section from '../layouts/Section';
import { NavLink } from 'react-router-dom';
import axios from 'axios';
import { localPath, servicePath } from '../../constants/defaultsValues';

// const goBack = () => {
//   useHistory.goBack();
// };
/* VALIDATIONS */
const validateEmail = (value) => {
  let error;
  if (!value) {
    error = 'Por favor, ingresa un correo electrónico valido';
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
    error = 'Correo electrónico invalido';
  }
  return error;
};
/* END VALIDATIONS */
const ForgotPassword = () => {
  // const history = useHistory();
  // const { token } = useParams();
  const [email, setEmail] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState('');

  // const validateReset = () => {
  // const validPass = validatePassword(password);
  // const validPassConfirm = validatePassword(passwordConfirm);
  //   setErrors({
  //     password: validPass,
  //     passwordConfirm: validPassConfirm,
  //   });
  //   return !validPass && !validPassConfirm;
  // };

  const onSubmit = (e) => {
    e.preventDefault();
    axios
      .post(`${servicePath}/email/forgot-password-app`, {
        email,
        redirectTo: `${localPath}/#/reset-password`,
      })
      .then(() => {
        setSubmitted(true);
      })
      .catch((error) => {
        if (error.message) {
          switch (error.response?.data?.data[0]?.messages[0]?.id) {
            case 'Auth.form.error.user.not-exist':
              setError(
                'No se ha encontrado el usuario, por favor pide al administrador de tu condominio que te registr.e'
              );
            case 'Auth.form.error.confirmed':
              setError(
                'La cuenta aún no esta verificada, revisa si te llego el correo de confirmación o inténte de nuevo.'
              );
              break;
            case 'Auth.form.error.invalid':
              setError('Error en el usuario o contraseña.');
              break;
            default:
              setError('Ha ocurrido un error, por favor intente mas tarde.');
              break;
          }
        }
      });
  };

  return (
    <Section
      allNotification={false}
      searchPopup={true}
      title={'Forgot Password'}
      disabledHeader={true}
      disabledFooter={true}
    >
      <section
        className="section-login"
        style={{
          backgroundImage:
            'url(' +
            process.env.PUBLIC_URL +
            '/assets/img/Fondo-login.png' +
            ')',
          backgroundSize: '100% 100%',
          minHeight: '100%',
          position: 'absolute',
          width: '100%',
        }}
      >
        <div className="page-title bg-transparent">
          {/* <div className="container pt-3">
              <button
                className="text-left home-clicked go-back"
                onClick={goBack}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="30"
                  height="30"
                  fill="currentColor"
                  className="bi bi-chevron-left"
                  viewBox="0 0 16 16"
                >
                  <path
                    fillRule="evenodd"
                    d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"
                  />
                </svg>
              </button>
            </div> */}
        </div>
        <div className="text-center mg-bottom-40 title">
          <div className="col-md-4 col-5 mx-auto mb-5">
            <img
              src={process.env.PUBLIC_URL + '/assets/img/civity-logo.svg'}
              alt="civity"
            />
          </div>
          <div className="col-12 text-center">
            <h4 className="text-center">Restablece tu contraseña</h4>
          </div>
        </div>
        <div className="Login-area">
          <div className="container">
            <form className="contact-form-inner bg-transparent">
              {submitted ? (
                <>
                  <h2 className="col-12 text-center mx-auto mb-3 text-white">
                    ¡Mensaje enviado!
                  </h2>
                  <h4 className="col-12 text-center mx-auto mb-3 text-white">
                    Te enviaremos un enlace a tu correo electrónico para
                    confirmar el cambio.
                  </h4>
                  <NavLink to="/login">
                    <div className="w-100 mx-auto text-center">
                      <button className="btn btn-login mx-auto">
                        Regresar
                      </button>
                    </div>
                  </NavLink>
                </>
              ) : (
                <>
                  <label className="single-input-wrap">
                    <input
                      type="email"
                      onChange={(e) => setEmail(e.target.value)}
                      required
                      value={email}
                      placeholder="Correo electrónico"
                    />
                    {error ? (
                      <span className="text-danger">{error}</span>
                    ) : null}
                  </label>
                  {/* <label className="single-input-wrap">
                <input
                  type="password"
                  onChange={(e) => setPasswordConfirm(e.target.value)}
                  value={passwordConfirm}
                  placeholder="Confirmar contraseña"
                />
                {errors.password ? (
                  <span className="text-danger">{errors.password}</span>
                ) : null}
              </label> */}
                  <NavLink to="/login">
                    <div className="text-right">
                      <span>¿Ya tienes una cuenta? Inicia Sesión</span>
                    </div>
                  </NavLink>
                  <div className="w-100 text-center">
                    <button
                      className="btn btn-login mx-auto"
                      onClick={(e) => {
                        const validEmail = validateEmail(email);
                        if (!validEmail) {
                          onSubmit(e);
                        } else {
                          setError(validEmail);
                        }
                      }}
                    >
                      Establecer contraseña
                    </button>
                  </div>
                </>
              )}
            </form>
          </div>
        </div>
      </section>
    </Section>
  );
  // }
};

// const mapStateToProps = (state) => {
//   return {
//     jwt: state.jwt,
//     message: state.error,
//   };
// };

// export default connect(mapStateToProps, { resetPasswordAction: resetPasswordStart })(
//   ResetPassword
// );

export default ForgotPassword;
