import React, { useState, useEffect } from 'react';
import axios from 'axios';
import moment from 'moment';
import { Link, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Section from '../layouts/Section';
import { servicePath } from '../../constants/defaultsValues';
import io from 'socket.io-client';
import Comments from './Comments';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import Loader from '../../components/Loader';

const apiUrl = `${servicePath}/incidences`;
const apiUpload = `${servicePath}/upload`;
const apiComments = `${servicePath}/comments`;
const Resumen = () => {
  const { id } = useParams();
  const condominium = useSelector((state) => state.condominium);
  const resident = useSelector((state) => state.resident);
  const token = useSelector((state) => state.jwt);
  const address = useSelector((state) => state.address);
  const [selectedItem, setSelectedItem] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);

  async function fetchData() {
    setIsLoaded(false);
    await axios
      .get(`${apiUrl}/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setSelectedItem(res.data);
        setIsLoaded(true);
      });
  }

  useEffect(() => {
    if (token) {
      fetchData();
    }
  }, [token]);

  const uploadFile = async (file) => {
    const bodyFormData = new FormData();
    bodyFormData.append('files', file);
    const saveDocument = await axios({
      method: 'post',
      url: `${apiUpload}`,
      data: bodyFormData,
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${token}`,
      },
    });
    return saveDocument;
  };

  const handlePushNotification = () => {
    const socket = io.connect(servicePath);
    socket.emit('notification:incidence-commented', {
      username: `${resident.name} ${resident.lastname}`,
      condominium,
      incidence: selectedItem.id,
    });
  };

  const onSubmit = async (data) => {
    let image = null;
    if (data.image) {
      image = await uploadFile(data.image);
    }
    if (data.comment || data.image) {
      setIsLoaded(false);
      const values = {
        date: new Date(),
        time: moment(new Date(), 'HH:mm').format('HH:mm:ss'),
        image: image ? image.id || image.data[0].id : null,
        comment: data.comment,
        incidence: selectedItem.id,
        user: selectedItem.user.id,
      };
      axios
        .post(`${apiComments}`, values, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          handlePushNotification();
          fetchData();
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  return (
    <Section
      title={'Seguimiento'}
      withBack={true}
      noBorder={true}
      cancelTo="/incidences"
    >
      {isLoaded ? (
        <div className="transaction-area pt-5 resumen-incidences ">
          <div className="container pt-2">
            <div className="col-12 mx-auto text-center my-4">
              <span>Resumen de tu incidencia</span>
              <p
                className={`${selectedItem.status.id === 1 ? 'text-red' : ''} ${
                  selectedItem.status?.id === 3 ? 'text-green' : ''
                } ${selectedItem.status.id === 2 ? 'text-orange' : ''}`}
              >
                {selectedItem.status.name}
              </p>
            </div>
            <div className="col-12 mx-auto  px-3   text-center">
              <div className="card w-100 py-5 px-3">
                <p>{selectedItem.title}</p>
                <span>{selectedItem.service?.name}</span>
                <p>{moment(selectedItem.date).format('LL')}</p>
                <span>{selectedItem.area?.name}</span>
                <Carousel
                  dynamicHeight
                  emulateTouch
                  infiniteLoop
                  showThumbs={false}
                  preventMovementUntilSwipeScrollTolerance
                  statusFormatter={(current, total) => `${current} / ${total}`}
                  className="mt-5"
                >
                  {selectedItem.photo
                    ? selectedItem.photo.map((p) => {
                        return (
                          <div key={p.id}>
                            <img
                              src={`${servicePath}${p.url}`}
                              alt={`monitoring content set on ${p.url}`}
                            />
                          </div>
                        );
                      })
                    : ''}
                </Carousel>
                <span className="mt-5">{selectedItem.description}</span>
              </div>
            </div>
            <div className="col-12 mx-auto p-3 comments text-left">
              {selectedItem.comments.map((x, key) => (
                <div
                  key={key}
                  className={`card w-100 py-3 px-3 my-2 ${
                    x.user === selectedItem.user?.id ? 'bg-white' : ''
                  }`}
                >
                  <span>
                    {x.user === selectedItem.user?.id
                      ? 'Tu respondiste:'
                      : 'Respuesta de Administración'}
                  </span>
                  <p>
                    {moment(x.date).format('DD/MM/YYYY')}{' '}
                    {x.time ? moment(x.time, 'HH:mm:ss').format('HH:mm') : ''}
                  </p>
                  {x.image && (
                    <a
                      href={`${servicePath}${x.image?.url}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <div className="mb-3 w-100">
                        <img
                          src={`${servicePath}${x.image?.url}`}
                          alt={x.image?.name}
                          className="w-100 h-100"
                          style={{ objectFit: 'cover', borderRadius: '10px' }}
                        />
                      </div>
                    </a>
                  )}
                  <span>{x.comment}</span>
                </div>
              ))}
              {selectedItem.status.id !== 3 ? (
                <Comments
                  data={selectedItem.comments}
                  currentUser={selectedItem.user.id}
                  onSubmit={onSubmit}
                  servicePath={servicePath}
                />
              ) : (
                // <textarea
                //   style={{ height: "120px", fontSize: "10px" }}
                //   className="form-control w-100 bg-beige border-0 p-3 card bg-white my-2"
                //   placeholder="Responder…"
                //   value={message}
                //   onChange={(e) => setMessage(e.target.value)}
                //   onKeyUp={(e) => setMessage(e.target.value)}
                // ></textarea>
                ''
              )}
            </div>
            <div className="container text-center  container-btn-small">
              {selectedItem.status.id !== 3 ? (
                <div className="row">
                  <Link
                    to="/incidences"
                    className="btn btn-end bg-blue col-5 mx-auto d-flex align-items-center justify-content-center text-center "
                  >
                    Cerrar
                  </Link>
                  {/* <button
                    onClick={() => {
                      if (message) {
                        onSubmit();
                      }
                    }}
                    className="btn btn-send col-5 mx-auto d-flex align-items-center justify-content-center text-center "
                  >
                    Enviar
                  </button> */}
                </div>
              ) : (
                <div className="w-100 text-center resumen-incidences">
                  <div className="w-100 mt-2">
                    <i className="small-icon icon-generada mx-auto"></i>
                  </div>
                  <div className="w-100">
                    <p
                      className="col-8 mx-auto mt-2 mb-3"
                      style={{ fontWeight: 'normal' }}
                    >
                      Incidencia resuelta por el administrador
                    </p>
                  </div>
                  <div className="w-100">
                    <Link
                      to="/incidences"
                      className="btn btn-new-incidence col-8 mx-auto d-flex align-items-center justify-content-center text-center "
                    >
                      Cerrar
                    </Link>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      ) : (
        <Loader />
      )}
    </Section>
  );
};

export default Resumen;
