import React, { useState, useEffect, useRef } from 'react';
import { Filesystem, Directory } from '@capacitor/filesystem';
import { Share } from '@capacitor/share';
import { Capacitor } from '@capacitor/core';
import { useSelector } from 'react-redux';
import { QRCodeSVG } from 'qrcode.react';
import axios from 'axios';
import moment from 'moment';
import { toPng } from 'html-to-image';
import { Link, useParams, useHistory } from 'react-router-dom';
import { Alert } from '../../alertServices';
import QRCode from '../../components/invitations/qrCode';
import { servicePath, adminPath } from '../../constants/defaultsValues';
import Section from '../../components/layouts/Section';
import Loader from '../../components/Loader';

const daysOfWeek = [
  { key: 7, value: 'D' },
  { key: 1, value: 'L' },
  { key: 2, value: 'M' },
  { key: 3, value: 'X' },
  { key: 4, value: 'J' },
  { key: 5, value: 'V' },
  { key: 6, value: 'S' },
];
const apiUrl = `${servicePath}/invitations`;
const types = {
  guest: 'Invitado',
  delivery: 'Repartidor',
  maintenance: 'Mantenimiento',
  other: 'Otro',
};
const ResumenInvitation = () => {
  const { id } = useParams();
  let history = useHistory();
  const qrCodeRef = useRef(null);
  const token = useSelector((state) => state.jwt);
  const [isLoaded, setIsLoaded] = useState(false);
  const [data, setData] = useState();
  const [isWeb, setIsWeb] = useState(false);
  const [isLoadQr, setIsLoadQR] = useState(false);

  const goBack = () => {
    history.goBack();
  };

  useEffect(() => {
    const isWeb = Capacitor.getPlatform() === 'web';
    setIsWeb(isWeb);
  }, []);

  async function fetchData() {
    setIsLoaded(true);
    try {
      const response = await axios.get(`${apiUrl}/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setData(response.data);
      setIsLoaded(false);
    } catch (error) {
      Alert.error({
        title: 'Error al obtener la invitación',
      });
      setIsLoaded(false);
    }
  }

  const formatTime = (time) => {
    return moment(time, 'HH:mm:ss.SSS').format('hh:mm A');
  };

  const shareQRCode = async () => {
    setIsLoadQR(true);
    let dataImage = await toPng(qrCodeRef.current);

    try {
      const canShare = await Share.canShare();

      const { uri } = await Filesystem.writeFile({
        path: 'invitacion.png',
        data: dataImage,
        directory: Directory.Cache,
      });

      if (canShare && canShare.value && !isWeb) {
        await Share.share({
          title: 'Civity',
          dialogTitle: 'Compartir imagen',
          files: [uri],
        });
      } else {
        alert('La función de compartir no es compatible en este dispositivo.');
      }
    } catch (error) {
      console.log('Error al compartir el archivo, intentelo mas tarde.');
    } finally {
      setIsLoadQR(false);
    }
  };

  useEffect(() => {
    if (token) {
      fetchData();
    }
  }, [token]);

  return (
    <Section
      title="Invitaciones"
      withBack={true}
      noBorder={true}
      cancelTo="/invitations"
    >
      {isLoaded ? (
        <Loader />
      ) : (
        <div className="transaction-area pt-5 resumen-incidences">
          <div className="container" style={{ paddingBottom: '4rem' }}>
            <div className="col-12 mx-auto text-center my-4 d-flex align-items-center justify-content-center">
              <Link
                to={`/invitation/edit/${data?.id}`}
                className="text-blue font-weight-bold d-flex align-items-center"
              >
                <i className="xsmall-icon icon-edit mr-2"></i>
                Editar invitación
              </Link>
            </div>

            <div className="col-12 mx-auto text-center my-4">
              <div className="my-3">
                <QRCodeSVG
                  value={`${adminPath}/verify-access/${data?.id}`}
                  size={210}
                  bgColor={'#ffffff'}
                  fgColor={'#2b2baf'}
                />
              </div>
              <p className="text-16 text-blue font-weight-bold">
                Resumen de invitación
              </p>
              <p className="text-14 text-blue font-weight-bold">{data?.name}</p>
              <p className="text-14 text-blue font-weight-normal">
                {types[data?.type]}
              </p>
              <div className="row px-2 py-0">
                <div className="col">
                  <div className="card-beige w-100 bg-beige m-0">
                    <p className="text-16 text-blue">Desde:</p>
                    <span>
                      {moment(data?.startDate).format('DD / MM / YYYY')}
                    </span>
                    <br />
                    {data?.endDate && data.startTime ? (
                      <span className="text-blue font-weight-bold">
                        {formatTime(data.startTime)}
                      </span>
                    ) : (
                      data?.startTime &&
                      data?.endTime && (
                        <span className="text-blue font-weight-bold">
                          {formatTime(data.startTime)} -{' '}
                          {formatTime(data.endTime)}
                        </span>
                      )
                    )}
                  </div>
                </div>
                {data?.endDate && (
                  <div className="col">
                    <div className="card-beige w-100 bg-beige m-0">
                      <p className="text-16 text-blue">Hasta:</p>
                      <span>
                        {moment(data?.endDate).format('DD / MM / YYYY')}
                      </span>
                      <br />
                      {data?.endTime && (
                        <span className="text-blue font-weight-bold">
                          {formatTime(data.endTime)}
                        </span>
                      )}
                    </div>
                  </div>
                )}
              </div>
              {data?.days && data?.days.length > 0 && (
                <div className="row px-2 py-0 mt-3">
                  <div className="col">
                    <div className="card-beige w-100 p-3 bg-beige m-0">
                      <p className="text-16 text-blue">Dias:</p>
                      <div className="mb-1 d-flex justify-content-between">
                        {daysOfWeek.map((day) => (
                          <label key={day.value} className="checkbox-circle">
                            <input
                              type="checkbox"
                              checked={data.days.some((d) => d.id === day.key)}
                              readOnly
                            />
                            <span>{day.value}</span>
                          </label>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <p className="text-16 text-blue font-weight-normal my-5">
                {data?.address.name}
              </p>
            </div>
          </div>
          <div className="footer-pay bg-white d-flex justify-content-center text-center mt-4">
            <div className="d-flex flex-fill">
              {data?.active && (
                <div className="flex-fill mr-1">
                  <button
                    onClick={shareQRCode}
                    className="btn btn-reservas btn-block d-flex align-items-center justify-content-center"
                  >
                    Compartir
                  </button>
                </div>
              )}
              <div className="flex-fill ml-1">
                <button
                  onClick={() => goBack()}
                  className="btn btn-muted btn-block d-flex align-items-center justify-content-center"
                >
                  Cerrar
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      <div style={{ position: 'absolute', left: '-9999px' }}>
        <div ref={qrCodeRef}>
          <QRCode data={data} />
        </div>
      </div>
      {isLoadQr && <Loader />}
    </Section>
  );
};

export default ResumenInvitation;
