import React from 'react';
import moment from 'moment';
import { QRCodeSVG } from 'qrcode.react';
import { adminPath } from '../../constants/defaultsValues';

moment.locale('es');

function QRCode({ data }) {
  return (
    <div className="container bg-white" style={{ maxWidth: '430px' }}>
      <div className="row">
        <div className="col-md-12 d-flex align-items-center justify-content-center position-relative">
          <img
            src="https://back.civity.axo-corp.com/uploads/icons/vector-blue.png"
            alt=""
            className="img-esquina"
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '80px',
              height: 'auto',
            }}
          />
          <div className="text-center mt-5 mb-4">
            <h1 className="text-blue">¡Hola, {data?.name}!</h1>
            <p className="mb-0" style={{ color: 'black' }}>
              Tu pase de acceso está listo. ¡Bienvenido!
            </p>
          </div>
        </div>

        <div className="col-12 text-center mb-2">
          <div style={{ maxWidth: '300px', margin: '0 auto' }}>
            <QRCodeSVG
              value={`${adminPath}/verify-access/${data?.id}`}
              size={210}
              bgColor={'#ffffff'}
              fgColor={'#2b2baf'}
            />
          </div>
          <small className="m-2">
            Vigencia:{' '}
            {data?.endDate
              ? moment(data?.endDate).format('DD MMMM YYYY')
              : moment(data?.startDate).format('DD MMMM YYYY')}
          </small>
        </div>
        <div className="col-12 text-center mb-2 px-5">
          <p className="mb-0" style={{ color: 'black' }}>
            <b className="text-blue">Unidad: </b>
            {data?.address?.name}
          </p>
          {data?.condominium && (
            <small className="text-capitalize" style={{ color: 'black' }}>
              {data.condominium.address} #{data.condominium.number}
              {data.condominium.numInterior &&
                ` Int. ${data.condominium.numInterior}`}
              , {data.condominium.neighborhood}, {data.condominium.city},{' '}
              {data.condominium.state}, {data.condominium.zip},{' '}
              {data.condominium.country}.
            </small>
          )}
        </div>
        <div className="col-12 text-center my-2 ">
          <div
            style={{
              width: '80px',
              height: 'auto',
              margin: '0 auto',
              overflow: 'hidden',
            }}
          >
            <img
              src={process.env.PUBLIC_URL + '/assets/img/civity-logo-blue.svg'}
              alt="Civity"
              style={{ width: '100%', height: 'auto' }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default QRCode;
