import React, { useState } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { servicePath } from '../../constants/defaultsValues';

const apiUrl = `${servicePath}/offers/applycode`;

const ApplyCode = ({ quotas, applyOffer }) => {
  const address = useSelector((state) => state.address);
  const condominium = useSelector((state) => state.condominium);
  const token = useSelector((state) => state.jwt);
  const [promoCode, setPromoCode] = useState('');
  const [isLoaded, setIsLoaded] = useState(false);
  const [message, setMessage] = useState('');

  const handleApply = async () => {
    setIsLoaded(true);
    try {
      const res = await axios.post(
        apiUrl,
        {
          code: promoCode,
          quotas,
          address,
          condominium,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (res.data.status) {
        applyOffer(res.data.offer);
        setMessage('');
      } else {
        setMessage(
          res.data.message || 'Hubo un problema al aplicar el código.'
        );
      }

      setIsLoaded(false);
    } catch (error) {
      setIsLoaded(false);
      console.log(error);
    }
  };
  return (
    <div>
      <div className="input-group">
        <input
          type="text"
          className="form-control"
          placeholder="Ingresa tu código de promoción"
          value={promoCode}
          onChange={(e) => setPromoCode(e.target.value)}
        />

        <div className="input-group-append">
          <button
            type="button"
            className="btn bg-blue"
            disabled={isLoaded || !promoCode}
            onClick={() => handleApply()}
          >
            {isLoaded ? (
              <span className="spinner-button spinner-center"></span>
            ) : (
              'Aplicar'
            )}
          </button>
        </div>
      </div>
      {message && <div className="text-danger mt-2">{message}</div>}
    </div>
  );
};

export default ApplyCode;
