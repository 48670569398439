import React, { useState, useEffect, useRef } from 'react';
import { Filesystem, Directory } from '@capacitor/filesystem';
import { Share } from '@capacitor/share';
import { Capacitor } from '@capacitor/core';
import { useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment';
import 'moment/locale/es';
import { toPng } from 'html-to-image';
import { Alert } from '../../alertServices';
import { servicePath } from '../../constants/defaultsValues';
import Section from '../../components/layouts/Section';
import SelectAddress from '../../components/layouts/SelectAddress';
import Loader from '../../components/Loader';
import QRCode from '../../components/invitations/qrCode';

moment.locale('es');

const apiUrl = `${servicePath}/invitations`;
const Invitations = () => {
  const qrCodeRef = useRef(null);
  let history = useHistory();
  const token = useSelector((state) => state.jwt);
  const address = useSelector((state) => state.address);
  const [isLoaded, setIsLoaded] = useState(false);
  const [historial, setHistorial] = useState(false);
  const [allItems, setAllItems] = useState([]);
  const [data, setData] = useState();
  const [selectItem, setSelectItem] = useState(null);
  const [share, setShare] = useState(false);
  const [isWeb, setIsWeb] = useState(false);
  const [isLoadQr, setIsLoadQR] = useState(false);

  useEffect(() => {
    /* const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    const isMobileBrowser =
      /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(
        userAgent
      ); */
    const isWeb = Capacitor.getPlatform() === 'web';
    setIsWeb(isWeb);
  }, []);

  const getData = (response) => {
    const itemsAll = response || allItems;
    let items = [];

    setIsLoaded(true);
    if (historial) {
      items = itemsAll.filter((item) => item.active === false);
    } else {
      items = itemsAll.filter((item) => item.active === true);
    }
    setData(items);
    setIsLoaded(false);
  };

  async function fetchData() {
    setIsLoaded(true);

    try {
      const response = await axios.get(
        `${apiUrl}?isCanceled=false&address=${address}&_sort=startDate:DESC`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setAllItems(response.data);
      setIsLoaded(false);
      setShare(false);
      setSelectItem(null);
      getData(response.data);
    } catch (error) {
      Alert.error({
        title: 'Hubo un problema, intentelo mas tarde.',
      });
      history.push('/');
      setIsLoaded(false);
    }
  }

  const handleDelete = async () => {
    try {
      await axios.delete(`${apiUrl}/${selectItem.id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      fetchData();
    } catch (error) {
      Alert.error({
        title: 'Hubo un problema al eliminar la invitación.',
      });
    }
  };

  const shareQRCode = async () => {
    let dataImage = await toPng(qrCodeRef.current);

    try {
      const canShare = await Share.canShare();

      const { uri } = await Filesystem.writeFile({
        path: 'invitacion.png',
        data: dataImage,
        directory: Directory.Cache,
      });

      if (canShare && canShare.value && !isWeb) {
        await Share.share({
          title: 'Civity',
          dialogTitle: 'Compartir imagen',
          files: [uri],
        });
      } else {
        alert('La función de compartir no es compatible en este dispositivo.');
      }
    } catch (error) {
      console.log('Error al compartir el archivo, intentelo mas tarde.');
    } finally {
      setIsLoadQR(false);
    }
  };

  useEffect(() => {
    if (allItems.length > 0) {
      getData();
    }
  }, [historial]);

  useEffect(() => {
    if (share && selectItem) {
      shareQRCode();
      setShare(false);
    }
  }, [share, selectItem]);

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Section title="Invitations">
      {isLoaded ? (
        <Loader />
      ) : (
        <div
          className="transaction-area pt-3"
          style={{ paddingBottom: '4rem' }}
        >
          <div className="container">
            <SelectAddress />
          </div>
          <div className="container my-4 pb-5">
            <div className="d-flex justify-content-center">
              <button
                type="button"
                onClick={() => setHistorial(false)}
                className={`${
                  historial ? 'btn-muted' : 'btn-reservas'
                } btn btn-reservas mr-4 col-5 d-flex align-items-center justify-content-center text-center`}
              >
                Invitaciones
              </button>
              <button
                onClick={() => setHistorial(true)}
                className={`${
                  historial ? 'btn-reservas' : 'btn-muted'
                } btn col-5 d-flex align-items-center justify-content-center text-center`}
              >
                Historial
              </button>
            </div>
            <div className="container">
              <div className="col-12 text-center text-title my-4">
                {data?.length > 0 && !historial
                  ? 'Tus visitas actuales'
                  : data?.length > 0 && historial
                  ? 'Historial de visitas'
                  : 'No hay visitas'}
              </div>
              <div className="transaction-inner row align-items-stretch">
                {data?.length > 0 &&
                  data.map((item) => (
                    <Card
                      key={item.id}
                      item={item}
                      setSelectItem={setSelectItem}
                      handleDelete={handleDelete}
                      setShare={setShare}
                      historial={historial}
                      setIsLoadQR={setIsLoadQR}
                    />
                  ))}
              </div>
            </div>
          </div>
          <div className="footer-pay bg-white d-flex justify-content-center text-center mt-4">
            <Link
              to="/invitations/create"
              className="btn btn-new-incidence d-flex align-items-center justify-content-center px-5"
            >
              Crear Invitación
            </Link>
          </div>
        </div>
      )}
      <div style={{ position: 'absolute', left: '-9999px' }}>
        <div ref={qrCodeRef}>
          <QRCode data={selectItem} />
        </div>
      </div>
      {isLoadQr && <Loader />}
    </Section>
  );
};

const Card = ({
  item,
  setSelectItem,
  handleDelete,
  setShare,
  historial,
  setIsLoadQR,
}) => {
  const types = {
    guest: 'Invitado',
    delivery: 'Repartidor',
    maintenance: 'Mantenimiento',
    other: 'Otro',
  };
  function formatDate(date) {
    const today = moment().startOf('day');
    const formattedDate = moment(date).format('DD MMMM YYYY');
    return moment(date).isSame(today, 'day') ? 'Hoy' : formattedDate;
  }
  return (
    <>
      <div className="col-12 col-sm-12 col-md-6 col-xl-4 w-100 my-2">
        <div className="card-incidences w-100">
          <div className="col-10 w-100 p-0 d-flex align-items-center container-text px-3">
            <div className="w-100 details pl-2 my-4">
              <Link to={`/invitation/${item.id}`}>
                <h5 className="text-capitalize">{item.name}</h5>
                <span className="text-dark pb-0 font-weight-bold">
                  Tipo: {types[item.type]}
                </span>
                <h5 className="mt-3 p-0 text-capitalize">
                  {moment(item.startDate).format('DD MMMM YYYY')}
                </h5>
                {item.isNever ? (
                  <span>Sin Vigencia</span>
                ) : (
                  <span className="text-capitalize">
                    Vigencia:{' '}
                    {item.endDate
                      ? formatDate(item.endDate)
                      : formatDate(item.startDate)}
                  </span>
                )}
                <br />
                <span>Modo: {!item.recurrent ? 'Unico' : 'Recurrente'}</span>
              </Link>
            </div>
            {!historial && (
              <div className="d-flex flex-column justify-content-between align-items-center">
                <button
                  type="button"
                  className="btn bg-transparent border-0 p-0 m-0 d-flex align-items-center ml-2"
                  data-toggle="modal"
                  data-target={`#modal-delete`}
                  onClick={() => setSelectItem(item)}
                >
                  <i className="large-icon icon-delete-circle pl-0"></i>
                </button>
                <Link
                  to={`/invitation/edit/${item.id}`}
                  className="btn bg-transparent border-0 p-0 m-0 d-flex align-items-center ml-2"
                >
                  <i className="large-icon icon-edit pl-0"></i>
                </Link>
                <button
                  type="button"
                  className="btn bg-transparent border-0 p-0 m-0 d-flex align-items-center ml-2"
                  onClick={() => {
                    setSelectItem(item);
                    setShare(true);
                    setIsLoadQR(true);
                  }}
                >
                  <i className="large-icon icon-share pl-0"></i>
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
      {/* MODAL DELETE */}
      <div id={`modal-delete`} className="modal fade">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body text-center mt-4 mb-1">
              <h4 className="text-blue font-weight-bold">
                ¿Seguro que quieres eliminar esta invitación?
              </h4>
            </div>
            <div className="modal-footer justify-content-between mx-3">
              <button
                type="button"
                className="btn btn-shadow my-2"
                data-dismiss="modal"
              >
                Cancelar
              </button>
              <button
                type="button"
                className="btn btn-red my-2"
                data-dismiss="modal"
                onClick={handleDelete}
              >
                Confirmar
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Invitations;
