import React, { useEffect, useState } from 'react';
import { servicePath } from '../../constants/defaultsValues';
import moment from 'moment';
import io from 'socket.io-client';
import { Link } from 'react-router-dom';
import Section from '../layouts/Section';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { formatter } from '../../helpers/Utils';
import Loader from '../../components/Loader';

const apiUrl = `${servicePath}/reservations`;
const apiCancelCharge = `${servicePath}/reservations/cancelcharge`;
function Reservation() {
  const { id } = useParams();
  const token = useSelector((state) => state.jwt);
  const user = useSelector((state) => state.user);
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [reservation, setReservation] = useState({});
  const [icon, setIcon] = useState({});
  const [isExpired, setIsExpired] = useState(false);

  const fetchData = async () => {
    setLoading(true);
    axios
      .get(`${servicePath}/reservations/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setReservation(res.data);
        hasReservationExpired(res.data);
        axios
          .get(`${servicePath}/icons/${res.data.area.icon}`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then((res) => {
            setIcon(res.data);
          });
        setLoading(false);
      });
  };

  const hasReservationExpired = (item) => {
    const reservationDate = moment(item.end, 'HH:mm:ss.SSS').set({
      year: moment(item.date).year(),
      month: moment(item.date).month(),
      date: moment(item.date).date(),
    });
    const hoursDifference = reservationDate.diff(moment(), 'hours');
    setIsExpired(hoursDifference <= 4);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const senEmail = (reservation, type) => {
    try {
      axios.post(
        `${apiUrl}/send-email`,
        { reservation, type },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const socket = io.connect(servicePath);
      socket.emit('notification:cancel-reservation', {
        username: `${reservation.resident.name} ${reservation.resident.lastname}`,
        condominium: reservation.area.condominium,
        area: reservation.area.name,
        date: moment(reservation.date).format('DD MMM YYYY'),
      });
    } catch (error) {
      Alert.error({
        title:
          'Lo sentimos, no pudimos enviar el correo de cancelación de tu reserva en este momento.',
        position: 'toast-top-right',
      });
    }
  };

  const onCancel = async () => {
    // setIsLoaded(true);

    const reservationStart = moment(reservation.start, 'HH:mm:ss.SSS').set({
      year: moment(reservation.date).year(),
      month: moment(reservation.date).month(),
      date: moment(reservation.date).date(),
    });
    const hoursDifference = reservationStart.diff(moment(), 'hours');
    const requestUrl =
      hoursDifference >= 4
        ? `${apiCancelCharge}/${reservation.id}?editor=${user.id}`
        : `${apiUrl}/${reservation.id}`;

    const requestPayload = {
      canceled: true,
    };

    try {
      const result = await axios.put(requestUrl, requestPayload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      senEmail(result.data, 'cancel');

      history.push('/reservations');
    } catch (error) {
      console.log(error);
      // setIsLoaded(false);
    }
  };

  // const deleteItem = (id) => {
  //   axios
  //     .put(`${servicePath}/reservations/${id}`,
  //     {
  //       cancelled: true,
  //     },
  //     {
  //       headers: {
  //         Authorization: `Bearer ${token}`,
  //       },
  //     })
  //     .then(() => {
  //       history.push('/reservations');
  //     });
  // };

  return (
    <Section title={'Reservación'} withBack={true}>
      {!loading ? (
        <div className="container pt-4 text-center">
          <div className="text-center">
            <img src="assets/reservations/reserva-generada.svg" alt="" />
          </div>
          <div
            className="col-8 mx-auto mt-4 text-center text-blue font-weight-bold"
            style={{ fontSize: '45px' }}
          >
            ¡Tu reserva!
          </div>
          <p className="text-gray my-4">Resumen de tu reservación</p>
          <div className="card-beige mx-3 py-5 text-center bg-beige">
            <div className="text-center mb-3">
              <img
                className="w-100 font-weight-bold object-fit-contain"
                width="70"
                height="70"
                src={`/assets/icons/${icon?.fileName}`}
                alt="img"
              />
            </div>
            <div className="text-title text-center">
              {reservation.area?.name || ' '}
            </div>
            <h5 className="text-gray my-3 font-weight-bold">
              {moment(reservation.date).format('DD/MM/YYYY') || ' '}
            </h5>
            <div className="text-title text-center">
              {reservation.start?.substring(0, 5)} -{' '}
              {reservation.end?.substring(0, 5)}
            </div>
            <h5 className="text-gray mt-4 font-weight-bold">Cargo:</h5>
            <div className="text-title text-center">
              {formatter.format(reservation.blockArea?.cost || 0)}
            </div>
          </div>
          <p className="col-10 mx-auto mt-5 text-left">
            En breve recibirás un correo electrónico con la confirmación de tu
            reserva. El cargo de{' '}
            {formatter.format(reservation.blockArea?.cost || 0)} pesos por esta
            reservación se verá reflejada en tu estado de cuenta de este mes.
          </p>
          <p className="col-10 mx-auto mt-5 font-weight-bold text-left">
            Es importante mencionar que una vez que se genera una reservación
            esta deberá ser respetada, si no se pudiera utilizar por cualquier
            motivo es importante que libere el horario reservado para que otros
            puedan hacer uso de las áreas, con un mínimo de 4 horas de
            anticipación, en caso de que el área tenga un costo, si no se
            cancela antes del tiempo indicado se generará un cargo descrito por
            la reserva.
          </p>
          <div className="py-5">
            {!isExpired && (
              <button
                type="button"
                className="btn mx-auto btn-muted text-red col-8 mt-5 d-flex align-items-center justify-content-center"
                data-toggle="modal"
                data-target={`#modal-delete-${reservation.id}`}
              >
                Cancelar
              </button>
            )}
          </div>

          <div id={`modal-delete-${reservation.id}`} className="modal fade">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-body text-center my-4">
                  <p>Estas por cancelar tu reserva</p>
                  <div className="px-2 py-2 text-center">
                    <h5 className="pb-2 text-blue">{reservation.area?.name}</h5>
                    <span className="text-dark pb-0 font-weight-bold">
                      {moment(reservation.date).format('DD')}{' '}
                      {moment(reservation.date).format('MMMM')}{' '}
                      {moment(reservation.date).format('YYYY')}
                    </span>{' '}
                    -{' '}
                    <span>
                      {reservation.start?.substring(0, 5)} a{' '}
                      {reservation.end?.substring(0, 5)}
                    </span>
                    <br />
                    <span>Precio: ${reservation.blockArea?.cost}</span>
                  </div>
                  <h4 className="text-blue font-weight-bold">
                    ¿Deseas continuar?
                  </h4>
                </div>
                <div className="modal-footer justify-content-between mx-3">
                  <button
                    type="button"
                    className="btn btn-shadow w-40 my-2"
                    data-dismiss="modal"
                  >
                    Cancelar
                  </button>
                  <button
                    onClick={() => onCancel()}
                    type="button"
                    className="btn btn-red w-40 my-2"
                    data-dismiss="modal"
                  >
                    Confirmar
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <Loader />
      )}
    </Section>
  );
}

export default Reservation;
