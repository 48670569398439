import axios from 'axios';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import { servicePath } from '../../constants/defaultsValues';
import { formatter } from '../../helpers/Utils';
import Section from '../layouts/Section';
import Loader from '../../components/Loader';

const url = `${servicePath}/transactions`;
const statusConfig = {
  pending: {
    icon: './assets/img/payments/Pago_exitoso.svg',
    status: 'Pendiente',
    info: (
      <>
        Pago por <br /> verificar
      </>
    ),
  },
  verified: {
    icon: './assets/img/payments/Pago_exitoso.svg',
    status: 'Verificado',
    info: (
      <>
        ¡Tu pago ha <br /> sido procesado <br /> con éxito!
      </>
    ),
  },
  canceled: {
    icon: './assets/img/payments/payment-fail.svg',
    status: 'Rechazado',
    info: (
      <>
        Tu pago ha <br /> sido rechazado.
      </>
    ),
  },
};

const Payment = ({ selectedCard, resident }) => {
  let history = useHistory();
  const { id } = useParams();
  const token = useSelector((state) => state.jwt);
  const [ingress, setIngress] = useState({});
  const [paymentStatus, setPaymentStatus] = useState({});
  const [isLoaded, setIsLoaded] = useState(true);

  const goBack = () => {
    history.goBack();
  };

  const updatePaymentStatus = (payment) => {
    let selectedStatus = statusConfig.pending;

    if (payment.verified) {
      selectedStatus = statusConfig.verified;
    } else if (payment.canceled) {
      selectedStatus = statusConfig.canceled;
    }

    setPaymentStatus(selectedStatus);
  };

  async function fetchData() {
    setIsLoaded(false);
    axios
      .get(`${url}/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setIngress(res.data);
        updatePaymentStatus(res.data);
        setIsLoaded(true);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    if (id) {
      fetchData();
    }
  }, [id]);

  return ingress ? (
    <Section title="Pago" witBack>
      {isLoaded ? (
        <div className="text-center pt-5">
          <img src={paymentStatus.icon} alt="success" className="w-30" />
          <h1 className="text-blue" style={{ fontSize: '45px' }}>
            {paymentStatus.info}
          </h1>
          <div className="btn-short d-flex align-items-center justify-content-between px-4 mt-5 w-75 mx-auto">
            <span className="font-weight-normal">Pago realizado</span>
            <span>{formatter.format(Math.abs(ingress?.amount))}.00</span>
          </div>
          <div className="mt-4 font-weight-bold">
            <div>
              <span>Status: </span>
              {paymentStatus.status}
            </div>
            <div>
              <span>Fecha de pago: </span>
              {moment(ingress?.date).locale(false).format('DD MMMM YYYY')}
            </div>
            <div>
              <span>Cliente: </span>
              {ingress?.resident?.name} {ingress?.resident?.lastname}
            </div>
            <div>
              <span>ID de transacción: </span>
              {ingress?.idMovement}
            </div>
          </div>
          <button
            onClick={() => goBack()}
            className="btn btn-short mt-5 col-7 col-md-5 col-lg-3 mx-auto d-flex align-items-center justify-content-center text-center"
          >
            Terminar
          </button>
        </div>
      ) : (
        <Loader />
      )}
    </Section>
  ) : (
    ''
  );
};

export default Payment;
