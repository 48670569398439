import React, { useState, useEffect } from 'react';
import axios from 'axios';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import { Alert } from '../../alertServices';
import { servicePath } from '../../constants/defaultsValues';
import Section from '../../components/layouts/Section';
import Loader from '../../components/Loader';

const apiSurveys = `${servicePath}/surveys`;
const apiQuestions = `${servicePath}/survey-questions`;
const apiAnswers = `${servicePath}/survey-residents/submit-responses`;

const SurveyDetail = () => {
  const { id } = useParams();
  let history = useHistory();
  const token = useSelector((state) => state.jwt);
  const resident = useSelector((state) => state.resident);
  const address = useSelector((state) => state.address);
  const [isLoaded, setIsLoaded] = useState(false);
  const [data, setData] = useState();
  const [responses, setResponses] = useState({});
  const [unansweredQuestions, setUnansweredQuestions] = useState([]);
  const [timeLeft, setTimeLeft] = useState('');
  const [surveyClosed, setSurveyClosed] = useState(false);

  const calculateTimeLeft = () => {
    const now = moment();
    const end = moment(data?.survey.endDate);

    if (now.isAfter(end)) {
      setSurveyClosed(true);
      setTimeLeft('00:00:00');
    } else {
      const diff = end.diff(now);
      const duration = moment.duration(diff);

      const days = Math.floor(duration.asDays());
      const hours = duration.hours();
      const minutes = duration.minutes();
      const seconds = duration.seconds();

      setTimeLeft(
        `${days > 0 ? days + 'd ' : ''}${hours
          .toString()
          .padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds
          .toString()
          .padStart(2, '0')}`
      );
    }
  };

  const handleResponseChange = (questionId, value) => {
    setResponses((prevResponses) => ({
      ...prevResponses,
      [questionId]: value,
    }));
    setUnansweredQuestions((prevQuestions) =>
      prevQuestions.filter((id) => id !== questionId)
    );
  };
  const handleSubmit = async () => {
    if (unansweredQuestions.length > 0) {
      Alert.error({
        title: 'Por favor, responde todas las preguntas antes de guardar.',
      });
      return;
    }

    try {
      setIsLoaded(true);
      const answersToSend = [];
      for (const questionId in responses) {
        const responseValue = responses[questionId];
        const questionType = data.questions.find(
          (question) => question.id === parseInt(questionId)
        )?.type;

        let answerData = {
          survey: id,
          survey_question: questionId,
          resident: resident.id,
          address,
        };
        if (questionType === 'text') {
          answerData.text = responseValue;
        } else {
          answerData.survey_response = responseValue;
        }

        answersToSend.push(answerData);
      }

      await axios.post(
        apiAnswers,
        { address, survey: data.survey, answers: answersToSend },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      Alert.success({
        title: '¡Respuestas guardadas exitosamente!',
      });
      setResponses({});
      setIsLoaded(false);
      history.push(`/survey/summary/${id}`);
    } catch (error) {
      setIsLoaded(false);
      Alert.error({
        title: 'Error al guardar las respuestas',
        message: error.message,
      });
    }
  };

  async function fetchData() {
    setIsLoaded(true);
    try {
      const response = await axios.get(`${apiQuestions}?survey=${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const surveyData = await axios.get(`${apiSurveys}/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const val = {
        survey: surveyData.data,
        questions: response.data,
      };

      setUnansweredQuestions(response.data.map((question) => question.id));
      setData(val);
      setIsLoaded(false);
    } catch (error) {
      Alert.error({
        title: 'Error al obtener la invitación',
      });
      setIsLoaded(false);
    }
  }

  useEffect(() => {
    if (data) {
      const interval = setInterval(() => {
        calculateTimeLeft();
      }, 1000);

      return () => clearInterval(interval);
    }
  }, [data]);

  useEffect(() => {
    if (token) {
      fetchData();
    }
  }, [token]);

  return (
    <Section
      title="Encuestas"
      withBack={true}
      noBorder={true}
      cancelTo="/surveys"
    >
      {isLoaded ? (
        <Loader />
      ) : (
        <div className="container my-4">
          <div className="col-12 ">
            <div className="text-center my-4">
              <h3 className="title text-blue">{data?.survey?.title}</h3>
            </div>
            <p className="mb-0 text-16 text-blue font-weight-bold">
              {moment().format('D MMMM YYYY, h:mm a')}
            </p>
            <p className="text-16">
              {surveyClosed ? (
                <span className="text-danger">Cerrada</span>
              ) : (
                <>
                  <span className="text-mint">Activa:</span> te quedan{' '}
                  <span className="text-mint">{timeLeft}</span>
                  <b> para votar</b>
                </>
              )}
            </p>
            <p className="mb-0 text-16">
              <span className="font-weight-bold text-blue">Descripción: </span>
              <span>{data?.survey.description}</span>
            </p>
          </div>
          <div className="col-12 my-4">
            <div className="row align-items-stretch gap-y-3 ">
              {data?.questions && data?.questions?.length
                ? data?.questions?.map((item, key) => {
                    return (
                      <div
                        key={item.id}
                        className="col-12 col-sm-12 col-md-6 col-xl-4 mb-3"
                      >
                        <p className="text-blue text-16 font-weight-bold">
                          Pregunta {key + 1}:
                        </p>
                        <div className="w-100 border-none bg-transparent text-center">
                          <div className="card-incidences h-100 px-4 py-3">
                            <p className="text-16 text-blue font-weight-bold mb-3 text-capitalize">
                              {item?.title}
                            </p>
                            {item.type === 'text' ? (
                              <input
                                type="text"
                                className="form-control"
                                value={responses[item.id] || ''}
                                onChange={(e) =>
                                  handleResponseChange(item.id, e.target.value)
                                }
                              />
                            ) : item.type === 'radio' ? (
                              item.survey_responses.map((response) => (
                                <div
                                  key={response.id}
                                  className="form-check mb-3 d-flex align-items-center"
                                  style={{ cursor: 'pointer' }}
                                >
                                  <input
                                    type="radio"
                                    id={response.id}
                                    name={`question_${item.id}`}
                                    value={response.id}
                                    checked={responses[item.id] === response.id}
                                    onChange={() =>
                                      handleResponseChange(item.id, response.id)
                                    }
                                    className="form-check-input"
                                    style={{
                                      width: '25px',
                                      height: '25px',
                                      marginRight: '10px',
                                    }}
                                  />
                                  <label
                                    htmlFor={response.id}
                                    className="form-check-label"
                                    style={{
                                      fontSize: '18px',
                                      color:
                                        responses[item.id] === response.id
                                          ? '#3927ab'
                                          : '#000',
                                      lineHeight: '25px',
                                      marginTop: '1px',
                                      marginLeft: '1rem',
                                    }}
                                  >
                                    {response.title}
                                  </label>
                                </div>
                              ))
                            ) : null}
                          </div>
                        </div>
                      </div>
                    );
                  })
                : ''}
            </div>
          </div>
          <div className="col-12 mt-4 text-center">
            <button
              className="btn btn-short px-5"
              onClick={() => handleSubmit()}
              disabled={surveyClosed || unansweredQuestions.length > 0}
            >
              Guardar
            </button>
          </div>
        </div>
      )}
    </Section>
  );
};

export default SurveyDetail;
