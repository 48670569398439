import React from 'react';

function CalendarStart() {
  return (
    <svg
      width="132"
      height="138"
      viewBox="0 0 132 138"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M65.4667 108.693L48.4601 117.587C47.6201 118.024 46.6401 117.315 46.8001 116.386L50.0467 97.5445C50.1134 97.1731 49.9867 96.7951 49.7201 96.5364L35.9601 83.1927C35.2801 82.5361 35.6534 81.3888 36.5934 81.2495L55.6067 78.5038C55.9801 78.4508 56.3001 78.2187 56.4667 77.8804L64.9734 60.7365C65.3934 59.8876 66.6067 59.8876 67.0267 60.7365L75.5334 77.8804C75.7001 78.2187 76.0201 78.4508 76.3934 78.5038L95.4067 81.2495C96.3467 81.3821 96.7201 82.5361 96.0401 83.1927L82.2801 96.5364C82.0134 96.7951 81.8867 97.1731 81.9534 97.5445L85.2001 116.386C85.3601 117.315 84.3801 118.024 83.5401 117.587L66.5334 108.693C66.2001 108.521 65.8001 108.521 65.4667 108.693Z"
        stroke="#2830A8"
        strokeWidth="3"
        strokeMiterlimit="10"
      />
      <path
        d="M108.253 15.8638H121.447C126.353 15.8638 130.333 19.8231 130.333 24.7043V127.501C130.333 132.383 126.353 136.342 121.447 136.342H10.5534C5.64675 136.342 1.66675 132.383 1.66675 127.501V24.7043C1.66675 19.8231 5.64675 15.8638 10.5534 15.8638H23.7467"
        stroke="#2830A8"
        strokeWidth="3"
        strokeMiterlimit="10"
      />
      <path
        d="M43.4199 15.8638H88.5866"
        stroke="#2830A8"
        strokeWidth="3"
        strokeMiterlimit="10"
      />
      <path
        d="M1.66675 49.2432H130.333"
        stroke="#2830A8"
        strokeWidth="3"
        strokeMiterlimit="10"
      />
      <path
        d="M39.5799 1.65796H27.5799C25.4628 1.65796 23.7466 3.36529 23.7466 5.4714V25.3676C23.7466 27.4737 25.4628 29.181 27.5799 29.181H39.5799C41.697 29.181 43.4133 27.4737 43.4133 25.3676V5.4714C43.4133 3.36529 41.697 1.65796 39.5799 1.65796Z"
        stroke="#2830A8"
        strokeWidth="3"
        strokeMiterlimit="10"
      />
      <path
        d="M104.413 1.65796H92.4132C90.2961 1.65796 88.5799 3.36529 88.5799 5.4714V25.3676C88.5799 27.4737 90.2961 29.181 92.4132 29.181H104.413C106.53 29.181 108.247 27.4737 108.247 25.3676V5.4714C108.247 3.36529 106.53 1.65796 104.413 1.65796Z"
        stroke="#2830A8"
        strokeWidth="3"
        strokeMiterlimit="10"
      />
    </svg>
  );
}

export default CalendarStart;
