import React, { useState, useEffect } from 'react';
import axios from 'axios';
import moment from 'moment';
import { Link, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Section from '../layouts/Section';
import { servicePath } from '../../constants/defaultsValues';

const Resumen = () => {
  const { id } = useParams();
  const user = useSelector((state) => state.user);
  const token = useSelector((state) => state.jwt);

  const apiUrl = `${servicePath}/incidences`;
  const apiComments = `${servicePath}/comments`;
  const apiDepartaments = `${servicePath}/departaments`;
  const apiServices = `${servicePath}/services`;
  const [selectedItem, setSelectedItem] = useState([]);
  const [message, setMessage] = useState('');
  const [services, setServices] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);

  async function fetchData() {
    axios
      .get(`${apiUrl}/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setSelectedItem(res.data);
        setMessage('');
        setIsLoaded(true);
      });
  }

  useEffect(() => {
    if (token) {
      fetchData();
    }
  }, [token]);
  const onSubmit = () => {
    const values = {
      date: new Date(),
      time: moment(new Date(), 'HH:mm').format('HH:mm:ss'),
      comment: message,
      incidence: selectedItem.id,
      user: selectedItem.user.id,
    };
    axios
      .post(`${apiComments}`, values, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        fetchData();
      });
  };

  return (
    <Section
      title={'Seguimiento'}
      withBack={true}
      noBorder={true}
      cancelTo="/incidences"
    >
      {isLoaded ? (
        <div className="transaction-area pt-5 resumen-incidences ">
          <div className="container pt-2">
            <div className="col-12 mx-auto text-center my-4">
              <span>Resumen de tu incidencia</span>
              <p
                className={`${selectedItem.status.id === 1 ? 'text-red' : ''} ${
                  selectedItem.status.id === 3 ? 'text-green' : ''
                } ${selectedItem.status.id === 2 ? 'text-orange' : ''}`}
              >
                {selectedItem.status.name}
              </p>
            </div>
            <div className="col-12 mx-auto  px-3   text-center">
              <div className="card w-100 py-5 px-3">
                <img
                  className="xlarge-icon a mx-auto mb-3"
                  src={`${servicePath}${selectedItem.service.icon.url}`}
                  alt=""
                />
                <p>{selectedItem.title}</p>
                <span>{selectedItem.service.name}</span>
                <p>{moment(selectedItem.date).format('LL')}</p>
                <span>{selectedItem.area.name}</span>
                <span className="mt-5 ">{selectedItem.description}</span>
              </div>
            </div>
            <div className="col-12 mx-auto  p-3  comments text-left">
              {selectedItem.comments.map((x, key) => (
                <div
                  key={key}
                  className={`card  w-100 py-3 px-3 my-2 ${
                    x.user === selectedItem.user.id ? 'bg-white' : ''
                  }`}
                >
                  <span>
                    {x.user === selectedItem.user.id
                      ? 'Tu respondiste:'
                      : 'Respuesta de Administración'}
                  </span>
                  <p>
                    {moment(x.date).format('DD/MM/YYYY')}{' '}
                    {moment(x.time, 'HH:mm:ss').format('HH:mm')}
                  </p>
                  <span>{x.comment}</span>
                </div>
              ))}
              {/* <div className="card  w-100 py-3 px-3 bg-white my-2">
                <span>Tu respondiste:</span>
                <p>12/mar/21 18:45</p>
                <span>
                  Le informamos la basura en áreas comunes ya fue recogida
                  adjuntamos fotos de testimonio, un cordial saludo.{" "}
                </span>
              </div> */}
              {selectedItem.status.id !== 3 ? (
                <textarea
                  style={{ height: '120px', fontSize: '10px' }}
                  className="form-control w-100 bg-beige border-0 p-3 card bg-white my-2"
                  placeholder="Responder…"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  onKeyUp={(e) => setMessage(e.target.value)}
                ></textarea>
              ) : (
                ''
              )}
            </div>
            <div className="container text-center  container-btn-small">
              {selectedItem.status.id !== 3 ? (
                <div className="row">
                  <Link
                    to="/incidences"
                    className="btn btn-end bg-blue col-5 mx-auto d-flex align-items-center justify-content-center text-center "
                  >
                    Terminar
                  </Link>
                  <button
                    onClick={() => {
                      if (message) {
                        onSubmit();
                      }
                    }}
                    className="btn btn-send col-5 mx-auto d-flex align-items-center justify-content-center text-center "
                  >
                    Enviar
                  </button>
                </div>
              ) : (
                <div className="w-100 text-center resumen-incidences">
                  <div className="w-100 mt-2">
                    <i className="small-icon icon-generada mx-auto"></i>
                  </div>
                  <div className="w-100">
                    <p
                      className="col-8 mx-auto mt-2 mb-3"
                      style={{ fontWeight: 'normal' }}
                    >
                      Incidencia resuelta por el administrador
                    </p>
                  </div>
                  <div className="w-100">
                    <Link
                      to="/incidences"
                      className="btn btn-new-incidence col-8 mx-auto d-flex align-items-center justify-content-center text-center "
                    >
                      Cerrar
                    </Link>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      ) : (
        ''
      )}
    </Section>
  );
};

export default Resumen;
