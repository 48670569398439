/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import io from 'socket.io-client';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Section from '../layouts/Section';
import { servicePath } from '../../constants/defaultsValues';
import { Calendar } from 'react-calendar';
import { Alert } from '../../alertServices';
import { formatter } from '../../helpers/Utils';
import Loader from '../../components/Loader';

const apiUrl = `${servicePath}/reservations`;
const apiDays = `${servicePath}/days`;
const apiAreas = `${servicePath}/areas`;
const apiReservationQuota = `${servicePath}/reservations/quota`;
const apiScheduleAreas = `${servicePath}/schedule-common-areas`;
const Create = () => {
  const resident = useSelector((state) => state.resident);
  const address = useSelector((state) => state.address);
  const condominium = useSelector((state) => state.condominium);
  const token = useSelector((state) => state.jwt);
  const [areas, setAreas] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [selectedArea, setSelectedArea] = useState(null);
  const [days, setDays] = useState([]);
  const [date, setDate] = useState(null);
  const [blocksData, setBlocksData] = useState([]);
  const [blockArea, setBlockArea] = useState([]);
  const [schedulesData, setSchedulesData] = useState([]);
  const [confirmation, setConfirmation] = useState(false);
  const [reservation, setReservation] = useState({});
  const [enabledDaysOfWeek, setEnabledDaysOfWeek] = useState([]);

  async function fetchData() {
    //setDate(new Date());
    setIsLoaded(false);
    const idCondominio = condominium;
    const firstRequest = axios.get(
      `${apiAreas}?_limit=-1&_sort=id&addresses=${address}&reserva=true&status=true`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const secondRequest = axios.get(
      `${apiScheduleAreas}?_limit=-1&_sort=start&area.condominium=${idCondominio}&status=true`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const thirdRequest = axios.get(
      `${apiScheduleAreas}?_limit=-1&_sort=end&area.condominium=${idCondominio}&status=true`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const forthRequest = axios.get(`${apiDays}?_limit=-1&_sort=value`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    const [firstResponse, secondResponse, thirdResponse, fourthResponse] =
      await Promise.all([
        firstRequest,
        secondRequest,
        thirdRequest,
        forthRequest,
      ]);
    const blocks = secondResponse.data;
    const blocks2 = thirdResponse.data;
    if (blocks.length) {
      const start = moment(blocks[0].start, 'HH:mm:ss').hour();
      const end = moment(blocks2[blocks2.length - 1].end, 'HH:mm:ss').hour();

      const times = [];
      for (let hour = start; hour < end; hour += 1) {
        times.push({
          time: moment(hour, 'HH').format('HH:00'),
          start: hour,
          end: hour + 1,
        });
      }
      setBlockArea(times);
    }
    setDays(fourthResponse.data);
    setAreas(firstResponse.data);
    setIsLoaded(true);
  }

  const getAvailabilityDays = (area) => {
    const areaData = areas.find((x) => x.id === Number(area.id));
    const shendules = areaData.scheduleAreas.filter(
      (shendule) => shendule.status === true
    );
    const resultArray = shendules.map((obj) => {
      const value = days.find((item) => item.id === obj.day)?.value;
      return value !== undefined ? value : null;
    });
    setEnabledDaysOfWeek(resultArray);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const senEmail = (reservation, type) => {
    try {
      axios.post(
        `${apiUrl}/send-email`,
        { reservation, type },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const socket = io.connect(servicePath);
      socket.emit('notification:new-reservation', {
        username: `${reservation.resident.name} ${reservation.resident.lastname}`,
        condominium: reservation.area.condominium,
        area: reservation.area.name,
        date: moment(reservation.date).format('DD MMM YYYY'),
      });
    } catch (error) {
      Alert.error({
        title:
          'Lo sentimos, no pudimos enviar el correo de confirmación de tu reserva en este momento.',
        position: 'toast-top-right',
      });
    }
  };

  const onSubmit = async () => {
    setIsLoaded(false);
    const values = {
      area: areas.find((item) => item.id === selectedArea.id),
      date: date,
      start: reservation.start
        ? moment(reservation.start, 'HH:mm').format('HH:mm:ss.SSS')
        : null,
      end: reservation.end
        ? moment(reservation.end, 'HH:mm').format('HH:mm:ss.SSS')
        : null,
      amount: reservation.price,
      status: 'pending',
      resident: resident,
      blockArea: blockArea,
      address: address,
      condominium,
    };
    if (values.amount > 0) {
      await axios
        .post(`${apiReservationQuota}`, values, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          setIsLoaded(true);
          setConfirmation(true);
          senEmail(res.data, 'new');
        })
        .catch((err) => {
          setIsLoaded(true);
          console.log(err);
        });
    } else {
      await axios
        .post(`${apiUrl}`, values, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          setIsLoaded(true);
          setConfirmation(true);
          senEmail(res.data, 'new');
        })
        .catch((err) => {
          setIsLoaded(true);
          console.log(err);
        });
    }
  };
  return (
    <Section
      title={'Reservaciones'}
      withBack={true}
      noBorder={true}
      cancelTo="/reservations"
    >
      {isLoaded ? (
        <div className="transaction-area pt-5 ">
          {selectedArea === null ? (
            <>
              {areas.length ? (
                <AreaSelect
                  areas={areas}
                  setSelectedArea={setSelectedArea}
                  getAvailabilityDays={getAvailabilityDays}
                />
              ) : (
                <div className="container">
                  <p className="mx-auto text-center text-title mt-5">
                    No tienes áreas comunes disponibles
                  </p>
                </div>
              )}
            </>
          ) : !confirmation ? (
            <PickDate
              date={date}
              setDate={setDate}
              setBlocksData={setBlocksData}
              blocksData={blocksData}
              setSchedulesData={setSchedulesData}
              schedulesData={schedulesData}
              selectedArea={selectedArea}
              setBlockArea={setBlockArea}
              blockArea={blockArea}
              setReservation={setReservation}
              reservation={reservation}
              areas={areas}
              days={days}
              onSubmit={onSubmit}
              enabledDaysOfWeek={enabledDaysOfWeek}
              setSelectedArea={setSelectedArea}
            />
          ) : (
            <Confirm
              selectedArea={selectedArea}
              reservation={reservation}
              date={date}
            />
          )}
        </div>
      ) : (
        <Loader />
      )}
    </Section>
  );
};

const AreaCard = ({ area, setSelectedArea, getAvailabilityDays }) => {
  return (
    <button
      type="button"
      onClick={() => {
        if (setSelectedArea && getAvailabilityDays) {
          setSelectedArea(area);
          getAvailabilityDays(area);
        }
      }}
      className="card-area btn-area border-0 mb-2 w-100 outline-none"
      style={{ backgroundColor: '#f8f7f7' }}
    >
      <div className="col-12">
        <div className="col-10 w-100 d-flex align-items-center">
          <div className="w-30">
            {area.icon && (
              <img
                className=""
                src={`/assets/icons/${area.icon?.fileName}`}
                width="40"
                height="40"
                alt="icon"
              />
            )}
          </div>
          <div className="w-80 details">
            <h5 className="w-100">{area.name}</h5>
          </div>
        </div>
      </div>
    </button>
  );
};

function AreaSelect({ areas, setSelectedArea, getAvailabilityDays }) {
  return (
    <div className="container pt-4">
      <div className="col-8 mx-auto text-center text-title">
        Selecciona donde deseas hacer tu reservación
      </div>
      <div className="transaction-area pt-3 section-incidences">
        <ul className="transaction-inner row align-items-stretch">
          {areas &&
            areas.map((area, key) => (
              <li key={key} className="col-12 my-1">
                <AreaCard
                  area={area}
                  setSelectedArea={setSelectedArea}
                  getAvailabilityDays={getAvailabilityDays}
                />
              </li>
            ))}
        </ul>
      </div>
      <div className="w-70 mx-auto my-4">
        <h4 className="text-title">Notas:</h4>
        <p>
          Es importante mencionar que una vez que se genera una reservación esta
          debe ser respetada, si no se podrá utilizar por cualquier motivo es
          importante que libere el horario reservado para que otros puedan hacer
          uso de las áreas.
        </p>
      </div>
    </div>
  );
}

function PickDate({
  selectedArea,
  areas,
  setDate,
  date,
  setBlocksData,
  blocksData,
  setSchedulesData,
  schedulesData,
  setBlockArea,
  blockArea,
  days,
  reservation,
  setReservation,
  onSubmit,
  enabledDaysOfWeek,
}) {
  const getAvailability = (block, schedule, reservations, date) => {
    const blockStart = moment(schedule.start, 'HH:mm:ss.SSS').toDate();
    const blockEnd = moment(schedule.end, 'HH:mm:ss.SSS').toDate();
    const durationInMilliseconds = block.duration * 60 * 60 * 1000;

    const availableReservations = [];
    const isCurrentDate = moment(date).isSame(moment(), 'day');

    for (
      let currentTime = blockStart.getTime();
      currentTime + durationInMilliseconds <= blockEnd.getTime();
      currentTime += durationInMilliseconds
    ) {
      const currentEnd = currentTime + durationInMilliseconds;
      const currentDateTime = moment(currentTime);
      if (
        !isCurrentDate ||
        (isCurrentDate && !currentDateTime.isBefore(moment()))
      ) {
        const isOverlapping = reservations.some((reservation) => {
          const reservationStart = moment(reservation.start, 'HH:mm:ss.SSS')
            .toDate()
            .getTime();
          const reservationEnd = moment(reservation.end, 'HH:mm:ss.SSS')
            .toDate()
            .getTime();
          return (
            (currentTime >= reservationStart && currentTime < reservationEnd) ||
            (currentEnd > reservationStart && currentEnd <= reservationEnd)
          );
        });

        if (!isOverlapping) {
          availableReservations.push({
            id: `${moment(currentTime).format('HH:mm:ss.SSS')}-${moment(
              currentEnd
            ).format('HH:mm:ss.SSS')}-${block.cost}`,
            start: moment(currentTime).format('HH:mm:ss.SSS'),
            end: moment(currentEnd).format('HH:mm:ss.SSS'),
            price: block.cost,
          });
        }
      }
    }

    return availableReservations;
  };

  const getBlocks = (area, date) => {
    setSchedulesData([]);
    let areaData = [];
    let blocks = [];

    if (area && date) {
      const day = days.find((d) => d.value === Number(moment(date).day())).id;
      areaData = areas.find((x) => x.id === Number(area.id));
      blocks = areaData.blockAreas;
      const schedule = areaData.scheduleAreas.find((s) => s.day === day);
      const reservations = areaData.reservations
        ? areaData.reservations.filter(
            (reserva) =>
              moment(reserva.date).format('DD-MM-YYYY') ===
                moment(date).format('DD-MM-YYYY') && !reserva.canceled
          )
        : [];

      const horarios = blocks.map((block) => {
        const possibleReservations = getAvailability(
          block,
          schedule,
          reservations,
          date
        );
        return {
          id: block.id,
          name: block.name,
          duration: block.duration,
          schedules: possibleReservations,
        };
      });
      setBlocksData(horarios);
    }
  };

  const tileDisabled = ({ activeStartDate, date, view }) => {
    if (view === 'month') {
      return !enabledDaysOfWeek.includes(date.getDay());
    }
    return false;
  };

  return (
    <div className="container pt-4">
      <div className="col-8 mx-auto text-center text-title">
        Selecciona día y hora <br /> Para realizar tu reserva
      </div>
      <div className="transaction-area pt-3 section-incidences">
        <ul className="transaction-inner row align-items-stretch">
          <li className="col-12 my-1">
            <AreaCard area={selectedArea} />
          </li>
          <li className="col-12 col-md-6">
            <div className="card-beige border-0 mx-auto text-center p-0 bg-beige">
              <Calendar
                onChange={(d) => {
                  getBlocks(selectedArea, d);
                  setDate(d);
                }}
                className="react-calendar react-calendar__navigation react-calendar__tile"
                // tileClassName={({ date, view }) => {
                //   if (mark.find(x => x === moment(date).format("DD-MM-YYYY"))) {
                //     return 'highlight'
                //   }
                // }}
                value={date}
                locale={navigator.language}
                minDate={new Date()}
                allowPartialRange={false}
                tileDisabled={tileDisabled}
              />
            </div>
          </li>
          <li className="col-12 col-md-4">
            {/* <div className="d-flex align-items-center mb-3">
              <i className="circle bg-red" />
              <span className="ml-4 text-gray font-weight-bold">
                Fuera de servicio
              </span>
            </div> */}
            <div className="d-flex align-items-center mb-3">
              <i className="circle bg-beige" />
              <span className="ml-4 text-gray font-weight-bold">
                Sin disponibilidad
              </span>
            </div>
            <div className="d-flex align-items-center mb-3">
              <i className="circle bg-blue" />
              <span className="ml-4 text-gray font-weight-bold">
                Día Seleccionado
              </span>
            </div>
          </li>
          {blocksData.length && date ? (
            <div className="mx-auto my-4">
              <div className="col-12 my-4 text-center text-title">
                ¿Por cuánto tiempo quieres reservar?
              </div>
              <div className="d-flex flex-wrap justify-content-center">
                {blocksData.map((block) => {
                  return (
                    <li
                      className="col-6 col-sm-6 col-md-6 col-xl-4 text-center my-1"
                      key={block.id}
                    >
                      <button
                        onClick={() => {
                          setSchedulesData(block.schedules || []);
                          setBlockArea(block);
                        }}
                        disabled={!block?.schedules?.length}
                        className={`${
                          blockArea.id === block.id ? 'bg-green' : 'bg-beige'
                        } card-beige p-4 border-0 btn-area outline-none`}
                      >
                        <div className="text-title text-center text-capitalize">
                          {block.name}
                        </div>
                        <span>({block.duration} hrs)</span>
                        <div
                          className={`${
                            blockArea.id === block.id
                              ? 'text-white'
                              : 'text-gray'
                          } font-weight-bold`}
                        >
                          {block.schedules.length > 0
                            ? `${block.schedules.length} Disponible`
                            : 'No Disponible'}
                        </div>
                      </button>
                    </li>
                  );
                })}
              </div>
            </div>
          ) : (
            ''
          )}
          {blocksData.length &&
          blocksData[0]?.schedules?.length &&
          schedulesData.length ? (
            <div className="mx-auto my-4">
              <div className="col-12 my-4 text-center text-title">
                Selecciona el horario de tu preferencia
              </div>
              <div className="d-flex flex-wrap justify-content-center">
                {schedulesData.map((item, i) => {
                  return (
                    <li
                      className="col-6 col-sm-6 col-md-6 col-xl-4 my-1 text-center"
                      key={i}
                    >
                      <button
                        onClick={() => setReservation(item)}
                        className={`${
                          reservation &&
                          reservation.start === item.start &&
                          reservation.end === item.end
                            ? 'bg-green'
                            : 'bg-beige'
                        } card-beige p-4 border-0 btn-area outline-none`}
                      >
                        <div className="text-title text-center">
                          {moment(item.start, 'HH:mm:ss.SSS').format('h:mm A')}{' '}
                          - {moment(item.end, 'HH:mm:ss.SSS').format('h:mm A')}
                        </div>
                        <div
                          className={`${
                            reservation &&
                            reservation.start === item.start &&
                            reservation.end === item.end
                              ? 'text-white'
                              : 'text-gray'
                          }  font-weight-bold`}
                        >
                          {item.price ? '$'.concat(item.price) : 'Sin costo'}
                        </div>
                      </button>
                    </li>
                  );
                })}
              </div>
            </div>
          ) : (
            ''
          )}
          <li className="col-12 col-sm-12 col-md-6 col-xl-4 mt-5 pt-4 mx-auto">
            <div className="d-flex justify-content-center align-items-center">
              <Link
                to="/reservations"
                className="btn btn-new-incidence btn-muted mr-4 col-5 d-flex align-items-center justify-content-center text-center"
              >
                Cancelar
              </Link>
              <button
                type="button"
                disabled={
                  !reservation.start && !reservation.end && !reservation.price
                }
                onClick={onSubmit}
                className="btn btn-new-incidence col-5 d-flex align-items-center justify-content-center text-center"
              >
                Reservar
              </button>
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
}

function Confirm({ selectedArea, date, reservation }) {
  return (
    <div className="container pt-4 text-center">
      <div className="text-center">
        <img src="assets/reservations/reserva-generada.svg" alt="" />
      </div>
      <div
        className="col-8 mx-auto mt-4 text-center text-blue font-weight-bold"
        style={{ fontSize: '45px' }}
      >
        ¡Reserva generada!
      </div>
      <p className="text-gray my-4">Resumen de tu reservación</p>
      <div className="card-beige mx-3 py-5 text-center bg-beige">
        <div className="text-center mb-3">
          <img
            className="w-100 font-weight-bold object-fit-contain"
            width="70"
            height="70"
            src={`/assets/icons/${selectedArea.icon?.fileName}`}
            alt="img"
          />
        </div>
        <div className="text-title text-center">{selectedArea?.name}</div>
        <h5 className="text-gray my-3 font-weight-bold">
          {moment(date).format('DD-MM-YYYY')}
        </h5>
        <div className="text-title text-center">
          {moment(reservation?.start, 'HH:mm:ss.SSS').format('h:mm A')} -{' '}
          {moment(reservation?.end, 'HH:mm:ss.SSS').format('h:mm A')}
        </div>
        <h5 className="text-gray mt-4 font-weight-bold">Cargo:</h5>
        <div className="text-title text-center">
          {formatter.format(reservation?.price || 0)}
        </div>
      </div>
      <p className="col-10 mx-auto mt-5 text-left">
        En breve recibirás un correo electrónico con la confirmación de tu
        reserva. El cargo de {formatter.format(reservation?.price || 0)} pesos
        por esta reservación se verá reflejada en tu estado de cuenta de este
        mes.
      </p>
      <p className="col-10 mx-auto mt-5 font-weight-bold text-left">
        Es importante mencionar que una vez que se genera una reservación esta
        deberá ser respetada, si no se pudiera utilizar por cualquier motivo es
        importante que libere el horario reservado para que otros puedan hacer
        uso de las áreas, con un mínimo de 4 horas de anticipación, en caso de
        que el área tenga un costo, si no se cancela antes del tiempo indicado
        se generará un cargo descrito por la reserva.
      </p>
      <p className="mt-4 text-center">{selectedArea.condominium.phone}</p>
      <Link
        to="/reservations"
        className="btn mx-auto btn-new-incidence mt-5 col-8 d-flex align-items-center justify-content-center text-center"
      >
        Terminar
      </Link>
    </div>
  );
}

export default Create;
