import React from 'react';

const CloseRedIcon = () => (
  <svg
    width="30"
    height="30"
    viewBox="0 0 23 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="11.5"
      cy="10.6066"
      r="7"
      transform="rotate(45 11.5 10.6066)"
      stroke="#FC716B"
    />
    <path d="M13.6582 8.48438L9.37835 12.7642" stroke="#FC716B" />
    <path d="M13.6582 12.7266L9.41556 8.48392" stroke="#FC716B" />
  </svg>
);

export default CloseRedIcon;
