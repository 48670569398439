import React from 'react';

const IconShow = () => {
  return (
    <svg
      width="22"
      height="16"
      viewBox="0 0 22 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.8667 7.63153C19.9774 3.51462 15.8208 0.65625 10.997 0.65625C6.17307 0.65625 2.01655 3.51462 0.130272 7.63153C-0.0434241 8.00939 -0.0434241 8.44516 0.130272 8.82607C2.01655 12.943 6.17307 15.8013 11 15.8013C15.8269 15.8013 19.9804 12.943 21.8697 8.82607C22.0434 8.4482 22.0434 8.01244 21.8697 7.63153H21.8667ZM10.997 13.4976C8.08678 13.4976 5.72817 11.139 5.72817 8.2288C5.72817 5.31862 8.08678 2.95696 10.997 2.95696C13.9071 2.95696 16.2657 5.31558 16.2657 8.22575C16.2657 11.1359 13.9071 13.4945 10.997 13.4945V13.4976Z"
        fill="#372AA1"
      />
    </svg>
  );
};
export default IconShow;
