import {
  LOGIN_USER_START,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_FAILED,
  RESET_PASSWORD_START,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILED,
  LOGOUT_USER,
  REGISTER_USER,
  REGISTER_USER_SUCCESS,
  REGISTER_USER_ERROR,
  SELECT_CHARGES_SUCCESS,
  SELECT_CHARGES_START,
  SELECT_ADDRESS_START,
  SELECT_ADDRESS_SUCCESS,
} from './actions';
const initialState = {
  user: null,
  jwt: null,
  error: null,
  address: null,
  resident: null,
  condominium: null,
  transparency: false,
};

const reducer = (state = initialState, action) => {
  let showTransparency = false;
  let condominiumId = null;

  switch ((initialState, action.type)) {
    case LOGIN_USER_START:
      return {
        ...state,
        username: action.payload.username,
        error: null,
        password: action.payload.password,
      };
    // break;
    case LOGIN_USER_SUCCESS:
      condominiumId = action.payload.resident.addresses.length
        ? action.payload.resident.addresses[0].condominium
        : null;
      if (condominiumId) {
        const selectedCondominium = action.payload.resident.condominiums.find(
          (condo) => condo.id === condominiumId
        );
        const { showExpenses, showIncomes, showQuotas } = selectedCondominium;
        if (showExpenses || showIncomes || showQuotas) {
          showTransparency = true;
        }
      }
      return {
        ...state,
        user: action.payload.user,
        jwt: action.payload.jwt,
        address: action.payload.resident.addresses.length
          ? action.payload.resident.addresses[0].id
          : null,
        condominium: condominiumId,
        resident: action.payload.resident,
        error: null,
        transparency: showTransparency,
        // password: null,
      };
    // break;
    case LOGIN_USER_FAILED:
      return {
        ...state,
        user: null,
        jwt: null,
        address: null,
        error: action.payload.message,
      };
    // break;
    case RESET_PASSWORD_START:
      return {
        ...state,
        username: action.payload.username,
        error: null,
        password: action.payload.password,
      };
    // break;
    case RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        user: action.payload.user,
        jwt: action.payload.jwt,
        address: action.payload.resident.addresses.length
          ? action.payload.resident.addresses[0].id
          : null,
        resident: action.payload.resident,
        error: null,
        password: null,
      };
    // break;
    case RESET_PASSWORD_FAILED:
      return {
        ...state,
        user: null,
        jwt: null,
        address: null,
        error: action.payload.message,
      };
    // break;
    case LOGOUT_USER:
      return {
        ...state,
        user: null,
        jwt: null,
        address: null,
        error: '',
      };
    // break;
    case REGISTER_USER:
      return { ...state, error: '' };
    // break;
    case REGISTER_USER_SUCCESS:
      return {
        ...state,
        user: action.payload.user,
        // jwt: action.payload.jwt,
        address: action.payload.resident.addresses.length
          ? action.payload.resident.addresses[0].id
          : null,
        resident: action.payload.resident,
        error: '',
      };
    // break;
    case REGISTER_USER_ERROR:
      return {
        ...state,
        user: null,
        jwt: null,
        address: null,
        error: action.payload.message,
      };
    // break;
    case SELECT_ADDRESS_START:
      return {
        ...state,
        address: action.payload.selectedAddress,
        resident: action.payload.resident,
        error: null,
      };
    // break;
    case SELECT_ADDRESS_SUCCESS:
      const selectedAddress = action.payload.selectedAddress;
      const selectedAddressObj = action.payload.resident.addresses.find(
        (a) => a.id === selectedAddress
      );
      condominiumId = selectedAddressObj
        ? selectedAddressObj.condominium
        : null;
      if (condominiumId) {
        const selectedCondominium = action.payload.resident.condominiums.find(
          (condo) => condo.id === condominiumId
        );
        const { showExpenses, showIncomes, showQuotas } = selectedCondominium;
        if (showExpenses || showIncomes || showQuotas) {
          showTransparency = true;
        }
      }

      return {
        ...state,
        address: selectedAddressObj ? selectedAddressObj.id : null,
        condominium: condominiumId,
        transparency: showTransparency,
        error: null,
      };
    // break;
    case SELECT_CHARGES_START:
      return {
        ...state,
        selectedCharges: action.payload.selectedCharges,
        error: '',
      };
    // break;
    case SELECT_CHARGES_SUCCESS:
      return {
        ...state,
        selectedCharges: action.payload.selectedCharges,
        error: '',
      };
    // break;
    default:
      return {
        ...state,
        error: null,
      };
    // break;
  }
};

export default reducer;
