import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { NavLink, useHistory, useParams } from 'react-router-dom';
import { servicePath } from '../../constants/defaultsValues';
import { Alert } from '../../alertServices';
import Section from '../layouts/Section';

// const goBack = () => {
//   useHistory.goBack();
// };
/* VALIDATIONS */
const validatePassword = (value) => {
  let error = null;
  if (!value) {
    error = 'Campo obligatorio';
  } else if (value.length < 8) {
    error = 'La contraseña debe tener al menos 8 caracteres';
  }
  return error;
};
const validatePasswordMatch = (password, confirmPassword) => {
  let error = null;
  if (password !== confirmPassword) {
    error = 'Las contraseñas no coinciden';
  }
  return error;
};
/* END VALIDATIONS */
const ResetPassword = (jwt) => {
  const history = useHistory();
  const { token } = useParams();
  const [user, setUser] = useState(null);
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const [errors, setErrors] = useState({ password: null, username: null });

  const userFetch = async () => {
    axios
      .post(`${servicePath}/residents/new/confirm`, {
        token,
      })
      .then((res) => {
        setUser(res.data?.id);
      })
      .catch((err) => {
        console.log(err.message);
        Alert.error({
          title:
            'El token ya ha sido utilizado o es inválido, por favor intenté enviar otro correo.',
        });
      });
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (token) {
      userFetch();
    }
  }, []);

  useEffect(() => {
    localStorage.clear();
  }, []);

  useEffect(() => {
    localStorage.clear();
  }, []);

  const validateReset = () => {
    const validPass = validatePassword(password);
    const validPassConfirm = validatePassword(passwordConfirm);
    const validPassMatch = validatePasswordMatch(password, passwordConfirm);
    setErrors({
      password: validPass,
      passwordConfirm: validPassConfirm,
      passwordMatch: validPassMatch,
    });
    return !validPass && !validPassConfirm && !validPassMatch;
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    const valid = validateReset();
    if (valid) {
      try {
        await axios.post(`${servicePath}/residents/update-password`, {
          userId: user,
          password: password,
        });

        history.push('/login');
      } catch (error) {
        let errorMessage =
          'Error al cambiar contraseña, por favor intenté enviar otro correo.';

        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          errorMessage = error.response.data.message;
        }

        Alert.error({
          title: errorMessage,
        });
      }
    }
  };

  // if (jasonWebToken !== null) {
  //   return <Redirect to="/">Error, ya hay una cuenta iniciada</Redirect>;
  // } else {
  return (
    <Section
      allNotification={false}
      searchPopup={true}
      title={'Login'}
      disabledHeader={true}
      disabledFooter={true}
    >
      <section
        className="section-login"
        style={{
          backgroundImage:
            'url(' +
            process.env.PUBLIC_URL +
            '/assets/img/Fondo-login.png' +
            ')',
          backgroundSize: '100% 100%',
          minHeight: '100%',
          position: 'absolute',
          width: '100%',
        }}
      >
        <div className="page-title bg-transparent">
          {/* <div className="container pt-3">
              <button
                className="text-left home-clicked go-back"
                onClick={goBack}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="30"
                  height="30"
                  fill="currentColor"
                  className="bi bi-chevron-left"
                  viewBox="0 0 16 16"
                >
                  <path
                    fillRule="evenodd"
                    d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"
                  />
                </svg>
              </button>
            </div> */}
        </div>
        <div className="text-center mg-bottom-40 title">
          <div className="col-md-4 col-5 mx-auto mb-5">
            <img
              src={process.env.PUBLIC_URL + '/assets/img/civity-logo.svg'}
              alt="civity"
            />
          </div>
          <div className="col-12 text-center">
            <h4 className="text-center">Establece una nueva contraseña</h4>
          </div>
        </div>
        <div className="Login-area">
          <div className="container">
            <form className="contact-form-inner bg-transparent">
              {jwt.message ? (
                <div className="col-12 text-center mx-auto mb-3">
                  <span className="text-danger mx-auto">{jwt.message}</span>
                </div>
              ) : null}
              <label className="single-input-wrap">
                <input
                  type="password"
                  onChange={(e) => setPassword(e.target.value)}
                  value={password}
                  placeholder="Contraseña"
                />
                {errors.password ? (
                  <span className="text-danger">{errors.password}</span>
                ) : null}
              </label>
              <label className="single-input-wrap">
                <input
                  type="password"
                  onChange={(e) => setPasswordConfirm(e.target.value)}
                  value={passwordConfirm}
                  placeholder="Confirmar contraseña"
                />
                {errors.passwordConfirm || errors.passwordMatch ? (
                  <span className="text-danger">
                    {errors.passwordConfirm || errors.passwordMatch}
                  </span>
                ) : null}
              </label>
              <NavLink to="/login">
                <div className="text-right">
                  <span>¿Ya tienes una cuenta? Inicia Sesión</span>
                </div>
              </NavLink>
              <div className="w-100 text-center">
                <button
                  className="btn btn-login mx-auto"
                  onClick={(e) => onSubmit(e)}
                >
                  Establecer contraseña
                </button>
              </div>
            </form>
          </div>
        </div>
      </section>
    </Section>
  );
  // }
};

// const mapStateToProps = (state) => {
//   return {
//     jwt: state.jwt,
//     message: state.error,
//   };
// };

// export default connect(mapStateToProps, { resetPasswordAction: resetPasswordStart })(
//   ResetPassword
// );

export default ResetPassword;
