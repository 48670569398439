import React from 'react';

const IconDownload = () => {
  return (
    <svg
      width="22"
      height="23"
      viewBox="0 0 22 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.3217 10.0391V0.591281C13.3217 0.264305 13.0467 0 12.7064 0H9.29449C8.95424 0 8.6792 0.264305 8.6792 0.591281V10.0391H5.10185C4.57352 10.0391 4.29092 10.6376 4.63874 11.02L10.5373 17.4977C10.7821 17.7666 11.2178 17.7666 11.4636 17.4977L17.3622 11.02C17.71 10.6376 17.4274 10.0391 16.8991 10.0391H13.3217Z"
        fill="#372AA1"
      />
      <path
        d="M20.845 23.0002H1.15496C0.517936 23.0002 0 22.5025 0 21.8903V16.0938H2.26833V20.8204H19.7317V16.0938H22V21.8903C22 22.5025 21.4821 23.0002 20.845 23.0002Z"
        fill="#372AA1"
      />
    </svg>
  );
};
export default IconDownload;
