import React from 'react';

const IconClose = () => {
  return (
    <svg
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M1 18L18 1" stroke="#372AA1" strokeWidth="2" />
      <path d="M1 0.999999L18 18" stroke="#372AA1" strokeWidth="2" />
    </svg>
  );
};
export default IconClose;
