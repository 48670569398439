import React from 'react';

export default function ElectronicPaymentsIcon() {
  return (
    <svg
      width="44"
      height="46"
      viewBox="0 0 64 66"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3317_3784)">
        <path
          d="M11.635 47.7884L2.39742 38.5515C0.834649 36.9889 0.834649 34.4608 2.39742 32.8981L32.9083 2.39758C34.4711 0.83492 36.9993 0.83492 38.5621 2.39758L58.5836 22.4177C60.1463 23.9803 60.1463 26.5084 58.5836 28.0711L58.199 28.4556"
          stroke="#2C2DA3"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14.2041 28.4634L39.413 3.24805"
          stroke="#2C2DA3"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M3.24829 39.411L11.6349 31.0332"
          stroke="#2C2DA3"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M23.5645 28.4639L44.0932 7.93652"
          stroke="#2C2DA3"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.93677 44.0906L11.6351 40.3926"
          stroke="#2C2DA3"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M58.7716 28.4629H15.6358C13.4261 28.4629 11.6348 30.2541 11.6348 32.4636V60.7716C11.6348 62.9811 13.4261 64.7723 15.6358 64.7723H58.7716C60.9813 64.7723 62.7726 62.9811 62.7726 60.7716V32.4636C62.7726 30.2541 60.9813 28.4629 58.7716 28.4629Z"
          stroke="#2C2DA3"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M22.7951 33.29H17.624C16.7971 33.29 16.1267 33.9604 16.1267 34.7873V37.7899C16.1267 38.6167 16.7971 39.2871 17.624 39.2871H22.7951C23.622 39.2871 24.2924 38.6167 24.2924 37.7899V34.7873C24.2924 33.9604 23.622 33.29 22.7951 33.29Z"
          stroke="#2C2DA3"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M16.1267 43.2393V46.6182"
          stroke="#2C2DA3"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M21.3306 43.2393V46.6182"
          stroke="#2C2DA3"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M26.5344 43.2393V46.6182"
          stroke="#2C2DA3"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M31.7383 43.2393V46.6182"
          stroke="#2C2DA3"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M42.1375 43.2393V46.6182"
          stroke="#2C2DA3"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M47.3413 43.2393V46.6182"
          stroke="#2C2DA3"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M52.5452 43.2393V46.6182"
          stroke="#2C2DA3"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M57.749 43.2393V46.6182"
          stroke="#2C2DA3"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M16.1267 50.1934H21.3305"
          stroke="#2C2DA3"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M26.5344 50.1934H31.7382"
          stroke="#2C2DA3"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M16.1267 53.7773H21.3305"
          stroke="#2C2DA3"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M26.5344 53.7773H31.7382"
          stroke="#2C2DA3"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M36.9419 50.1934H42.1375"
          stroke="#2C2DA3"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_3317_3784">
          <rect width="64" height="66" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
