import React, { useState } from 'react';
import Send from '../svg/Send';
import Camera from '../svg/Camera';
import CloseRedIcon from '../svg/CloseRedIcon';

const Comments = ({ onSubmit }) => {
  const [image, setImage] = useState(null);
  const [error, setError] = useState('');
  const [newComment, setNewComment] = useState('');
  return (
    <div className="mt-3">
      {image ? (
        <div>
          <span className="text-primary ml-3 pb-1">
            <u>{image.name}</u>
          </span>
          <button
            type="button"
            size="sm"
            className="btn m-0 bg-transparent h-50 p-0 ml-2"
            style={{ lineHeight: '0' }}
            onClick={() => setImage(null)}
          >
            <CloseRedIcon />
          </button>
        </div>
      ) : (
        ''
      )}
      {/* <div className="w-100 d-flex align-items-center" style={{ heigth: '50px' }}> */}
      {error ? <span className="text-danger text-xs px-3">{error}</span> : ''}
      <form
        className="w-100 d-flex align-items-center"
        id="form-comments"
        onSubmit={(e) => {
          e.preventDefault();
          if (newComment || image) {
            onSubmit({ comment: newComment, image });
            setImage(null);
            setNewComment('');
            setError('');
          } else {
            setError('El comentario no puede estar vacío');
          }
        }}
      >
        <div className="p-0 pl-3">
          <input
            type="textarea"
            id="comment"
            name="comment"
            placeholder="Responder..."
            onChange={(e) => setNewComment(e.target.value)}
            value={newComment}
            style={{
              resize: 'none',
              borderRadius: '5px',
              outline: 'none',
            }}
            className="border border-primary p-2 w-100"
            rows="1"
          />
          {/* {errors.comment && (
              <div>{errors.comment}</div>
            )} */}
        </div>
        <div className="p-0 ml-2">
          <div>
            <label
              htmlFor="image"
              style={{
                width: '40px',
                height: '40px',
                borderRadius: '40px',
                cursor: 'pointer',
              }}
              className="d-flex btn-area align-items-center justify-content-center p-0 m-0 border border-primary"
            >
              <Camera />
            </label>
            <input
              id="image"
              type="file"
              accept="image/png, image/jpeg, image/gif, image/svg+xml, image/webp"
              onChange={(e) => {
                if (e.currentTarget.files[0]) {
                  const bytesToMegaBytes = (bytes) => bytes / 1024 ** 2;
                  const size = bytesToMegaBytes(e.currentTarget.files[0].size);
                  if (
                    size > 5 &&
                    (e.currentTarget.files[0].type !== 'image/jpeg' ||
                      e.currentTarget.files[0].type !== 'image/png' ||
                      e.currentTarget.files[0].type !== 'image/gif' ||
                      e.currentTarget.files[0].type !== 'image/svg+xml' ||
                      e.currentTarget.files[0].type !== 'image/webp')
                  ) {
                    e.preventDefault();
                    setError('Tene que ser una imagen menor a 5 MB');
                  } else {
                    setError('');
                    setImage(e.currentTarget.files[0]);
                  }
                }
              }}
              className="d-none"
            />
          </div>
        </div>
        <div className="p-0 ml-2">
          <button
            type="submit"
            // onClick={() => {
            //   onSubmit({ comment: newComment, image });
            //   setImage(null);
            //   setNewComment('');
            // }}
            style={{
              width: '40px',
              height: '40px',
              borderRadius: '40px',
            }}
            className="d-flex align-items-center btn-area bg-blue border border-primary justify-content-center p-0"
          >
            <Send />
          </button>
        </div>
      </form>
    </div>
  );
};

export default Comments;
