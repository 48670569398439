import React from 'react';
import { Share } from '@capacitor/share';
import { Filesystem, Directory, Encoding } from '@capacitor/filesystem';
import { servicePath } from '../../constants/defaultsValues';
import FileViewer from './fileViewer';
import IconClose from '../svg/Close';
import IconDownload from '../svg/Download';
import IconShare from '../svg/Share';

const ModalFile = ({ file }) => {
  const handleDownload = async (file) => {
    const fileUrl = `${servicePath}${file.url}`;
    const fileName = file.name;
    /*  const link = document.createElement('a');
    link.href = fileUrl;
    link.target = '_blank';
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link); */

    try {
      const options = {
        url: fileUrl,
        path: fileName,
        directory: 'dowloads',
      };
      const result = await Filesystem.downloadFile(options);
    } catch (error) {
      const newWindow = window.open(fileUrl, '_blank');
    }
  };

  const handleShare = async (file) => {
    const fileUrl = `${servicePath}${file.url}`;
    try {
      const canShare = await Share.canShare();
      if (canShare && canShare.value) {
        await Share.share({
          title: file.name,
          url: fileUrl,
          dialogTitle: file.name,
        });
      } else {
        alert('La función de compartir no es compatible en este dispositivo.');
      }
    } catch (error) {
      console.log('Error al compartir el archivo, intentelo mas tarde.');
    }
  };

  return (
    <div id="modal-file" className="modal fade modal-file">
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header border-none align-items-center">
            <h4
              className="modal-title"
              style={{ maxWidth: '150px', wordWrap: 'break-word' }}
            >
              {file.name}
            </h4>
            <div className="d-flex align-items-center">
              <button
                id="btn-download"
                type="button"
                className="btn-unstyled"
                onClick={() => handleDownload(file)}
              >
                <IconDownload />
              </button>
              <button
                id="btn-share"
                type="button"
                className="btn-unstyled"
                onClick={() => handleShare(file)}
              >
                <IconShare />
              </button>
              <div className="vertical-line"></div>
              <button type="button" className="close" data-dismiss="modal">
                <IconClose />
                <span className="sr-only">close</span>
              </button>
            </div>
          </div>
          <div className="modal-body pt-0">
            <FileViewer doc={file} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalFile;
