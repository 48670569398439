import React from 'react';

export default function RegisterPaymentIcon() {
  return (
    <svg
      width="44"
      height="46"
      viewBox="0 0 52 72"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3317_3806)">
        <path
          d="M1.5 4.3V68.18C1.5 69.18 2.51 69.85 3.43 69.46L7.76 67.64C8.45 67.35 9.22 67.35 9.91 67.64L15.23 69.82C15.95 70.11 16.75 70.1 17.46 69.77L22.31 67.54C23.06 67.2 23.93 67.2 24.67 67.55L29.19 69.69C29.98 70.06 30.89 70.05 31.66 69.65L36.44 67.2C37.37 66.72 38.49 66.8 39.34 67.41L41.93 69.26C42.8 69.88 43.95 69.95 44.89 69.44L49.76 66.81C50.21 66.57 50.49 66.1 50.49 65.59V4.3C50.49 2.76 49.24 1.5 47.69 1.5H4.3C2.76 1.5 1.5 2.75 1.5 4.3Z"
          stroke="#2C2DA3"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.06006 10.1797H34.4901"
          stroke="#2C2DA3"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.06006 16.1602H26.7701"
          stroke="#2C2DA3"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.06006 22.1396H34.4901"
          stroke="#2C2DA3"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.06006 28.1201H26.7701"
          stroke="#2C2DA3"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.06006 34.0996H34.4901"
          stroke="#2C2DA3"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.06006 40.0801H26.7701"
          stroke="#2C2DA3"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.06006 46.0596H34.4901"
          stroke="#2C2DA3"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M39.99 10.1797H45.2"
          stroke="#2C2DA3"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M39.99 16.1602H45.2"
          stroke="#2C2DA3"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M39.99 22.1396H45.2"
          stroke="#2C2DA3"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M39.99 28.1201H45.2"
          stroke="#2C2DA3"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M39.99 34.0996H45.2"
          stroke="#2C2DA3"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M39.99 40.0801H45.2"
          stroke="#2C2DA3"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M39.99 46.0596H45.2"
          stroke="#2C2DA3"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M30.3701 53.6299H9.29006C8.61075 53.6299 8.06006 54.1806 8.06006 54.8599V58.6199C8.06006 59.2992 8.61075 59.8499 9.29006 59.8499H30.3701C31.0494 59.8499 31.6001 59.2992 31.6001 58.6199V54.8599C31.6001 54.1806 31.0494 53.6299 30.3701 53.6299Z"
          stroke="#2C2DA3"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M44.3701 54.57C44.3701 54.57 43.4801 53 41.8301 53C40.1801 53 39.8601 53.8 39.8601 54.57C39.8601 55.34 40.1101 55.67 42.1201 56.37C44.1301 57.07 44.3801 57.58 44.3801 58.53C44.3801 59.48 43.2401 59.8 42.1201 59.8C41.0001 59.8 39.8601 58.59 39.8601 58.59"
          stroke="#2C2DA3"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M42.1101 52.04V60.82"
          stroke="#2C2DA3"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_3317_3806">
          <rect width="52" height="71.52" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
