import React, { useEffect, useState } from 'react';
import qs from 'qs';
import moment from 'moment';
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
} from 'chart.js';
import Section from '../layouts/Section';
import { Pie, Bar } from 'react-chartjs-2';
import axios from 'axios';
import { servicePath } from '../../constants/defaultsValues';
import { useSelector } from 'react-redux';
import Loader from '../../components/Loader';

ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
  Title
);

function Transparency() {
  const condominium = useSelector((state) => state.condominium);
  const token = useSelector((state) => state.jwt);
  const [loading, setLoading] = useState(false);
  const [dateFilter] = useState(null);
  const [ingressQuotas, setIngressQuota] = useState(0);
  const [ingressAreas, setIngressAreas] = useState(0);
  const [ingressExtra, setIngressExtra] = useState(0);
  const [categoriesLabels, setCategoriesLabels] = useState(0);
  const [egressAmounts, setEgressAmounts] = useState([]);
  const [pending, setPending] = useState(0);
  const [expired, setExpired] = useState(0);
  const [maxBar, setMaxBar] = useState(0);
  const [activeIngress, setActiveIngress] = useState(false);
  const [activeEgress, setActiveEgress] = useState(false);
  const [activeCharges, setActiveCharges] = useState(false);

  const makeRequest = async (url, params) => {
    try {
      const response = await axios.get(url, {
        params,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (err) {
      console.log(err);
      throw err;
    }
  };

  async function fetchData() {
    setLoading(true);

    let queryGB = {};
    let queryQu = {};
    let ingressAreasCount = 0;
    let ingressExtraCount = 0;
    let ingressQuotaCount = 0;
    const balanceIngressArr = [];
    const balanceEgressArr = [];
    const balanceLabelsArr = [];
    const egressAmountArr = [];
    const egressLabels = [];
    const egressArr = [];
    let pendingCount = 0;
    let expiredCount = 0;

    const handleQuotas = (quotas) => {
      quotas.forEach((c) => {
        if (c.pending && moment(new Date()).isAfter(c.expirationDate)) {
          expiredCount += c.pending;
        } else if (c.pending) {
          pendingCount += c.pending;
        }
      });
    };

    const handleTransactions = (transactions) => {
      transactions.forEach((x, i) => {
        const prev = i - 1;

        if (x.type === 'Ingress') {
          if (i > 0 && moment(x.date).isSame(transactions[prev].date)) {
            balanceIngressArr[balanceIngressArr.length - 1] += x.amount;
          } else {
            balanceIngressArr.push(x.amount);
            balanceEgressArr.push('');
            balanceLabelsArr.push(moment(x.date).format('DD MMM YY'));
          }

          if (x.address) {
            ingressQuotaCount += x.amount;
          } else {
            ingressExtraCount += x.amount;
          }

          if (x.isReservation === true) {
            ingressAreasCount += x.amount;
          }
        }

        if (x.type === 'Egress') {
          if (
            balanceEgressArr.length > 0 &&
            moment(x.date).isSame(transactions[prev].date)
          ) {
            balanceEgressArr[balanceEgressArr.length - 1] += Math.abs(x.amount);
          } else {
            balanceEgressArr.push(Math.abs(x.amount));
            balanceIngressArr.push('');
            balanceLabelsArr.push(moment(x.date).format('DD MMM YY'));
          }

          if (x.address) {
            ingressQuotaCount += x.amount;
          }

          if (x.isReservation === true) {
            ingressAreasCount += x.amount;
          }
        }
      });
    };

    if (dateFilter !== null) {
      const end = moment(dateFilter.endDate)
        .add(1, 'days')
        .format('YYYY-MM-DD');
      queryGB = {
        subscriptionDate_gte: dateFilter.startDate,
        subscriptionDate_lte: end,
      };

      queryQu = {
        date_gte: dateFilter.startDate,
        date_lte: end,
      };
    }

    try {
      const [egressCategories, quotasQu, ingresses] = await Promise.all([
        makeRequest(`${servicePath}/categories`, {
          condominium,
          type: 'Egress',
          _limit: -1,
        }),
        makeRequest(`${servicePath}/quotas`, {
          'condominium.id': condominium,
          _sort: 'subscriptionDate:ASC',
          _limit: -1,
          ...queryQu,
        }),
        makeRequest(`${servicePath}/transactions`, {
          'condominium.id': condominium,
          _sort: 'date:ASC',
          canceled: false,
          _limit: -1,
          ...queryGB,
        }),
      ]);

      handleQuotas(quotasQu);
      handleTransactions(ingresses);

      egressCategories.forEach((c) => {
        const egres = {
          label: c.name,
          total: 0,
          amount: 0,
        };

        c.transactions.forEach((m) => {
          if (m.type === 'Egress') {
            egres.total += 1;
            egres.amount += Math.abs(m.amount);
          }
        });

        egressAmountArr.push(egres.amount || undefined);
        egressLabels.push(c.name);
        egressArr.push(egres || undefined);
      });

      setIngressQuota(ingressQuotaCount || undefined);
      setIngressAreas(ingressAreasCount || undefined);
      setIngressExtra(ingressExtraCount || undefined);
      setEgressAmounts(egressAmountArr);
      setCategoriesLabels(egressLabels);
      setPending(Math.abs(pendingCount) || undefined);
      setExpired(Math.abs(expiredCount) || undefined);
      setMaxBar(
        Math.max(
          ingressQuotaCount,
          Math.abs(pendingCount),
          Math.abs(expiredCount)
        )
      );
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  }

  const getCondominiumData = () => {
    axios
      .get(`${servicePath}/condominiums/${condominium}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setActiveIngress(res.data.showIncomes);
        setActiveEgress(res.data.showExpenses);
        setActiveCharges(res.data.showQuotas);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getCondominiumData();
    fetchData();
  }, []);

  return loading ? (
    <Loader />
  ) : (
    <Section title="Transparencia" withBack={true}>
      <div className="container">
        {!activeIngress && !activeEgress && !activeCharges ? (
          <div className="text-center mt-5 pt-5">
            <svg
              width="146"
              height="146"
              viewBox="0 0 146 146"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clipPath="url(#clip0_2818_648)">
                <path
                  d="M73 4C86.18 4 98.93 7.71 109.99 14.75L74.02 52.39L41.93 11.39C51.51 6.55 62.22 4 73 4ZM73 0C60.47 0 48.69 3.17 38.39 8.73C37.06 9.45 36.69 11.19 37.63 12.38L71.99 56.29C72.47 56.91 73.19 57.22 73.91 57.22C74.55 57.22 75.19 56.97 75.67 56.47L114.17 16.16C115.22 15.07 115.02 13.29 113.76 12.45C102.12 4.59 88.1 0 73 0V0Z"
                  fill="#2C2DA3"
                />
                <path
                  d="M20.67 28.04L61.06 79.65L83.64 141.17C80.11 141.72 76.54 142 73 142C34.95 142 4 111.05 4 73C4 56.45 9.91 40.56 20.67 28.04ZM20.75 23.16C20.09 23.16 19.42 23.43 18.93 23.97C7.17 36.93 0 54.12 0 73C0 113.32 32.68 146 73 146C77.51 146 81.92 145.58 86.2 144.79C87.68 144.52 88.56 142.97 88.04 141.55L64.72 78.03C64.63 77.79 64.51 77.57 64.35 77.37L22.67 24.1C22.18 23.48 21.47 23.17 20.75 23.17V23.16Z"
                  fill="#2C2DA3"
                />
                <path
                  d="M129.5 33.42C137.68 45.06 142 58.71 142 73.01C142 96.98 129.4 119.37 108.99 131.87L88.57 76.25L129.5 33.42ZM129.67 28.55C129.03 28.55 128.39 28.8 127.91 29.3L85.01 74.19C84.37 74.86 84.17 75.84 84.49 76.71L105.89 135.01C106.26 136.01 107.2 136.61 108.18 136.61C108.59 136.61 109.01 136.51 109.4 136.28C131.27 123.67 146.01 100.07 146.01 73.01C146.01 56.72 140.66 41.68 131.64 29.53C131.16 28.88 130.42 28.55 129.68 28.55H129.67Z"
                  fill="#2C2DA3"
                />
              </g>
              <defs>
                <clipPath id="clip0_2818_648">
                  <rect width="146" height="146" fill="white" />
                </clipPath>
              </defs>
            </svg>

            <h3 className="text-blue mt-5 ">No hay datos para mostrar</h3>
          </div>
        ) : (
          <>
            {activeIngress ? (
              <div className="text-center mt-5">
                <h3 className="text-blue">Ingresos</h3>
                <Pie
                  data={{
                    labels: [
                      'Cargos',
                      'Reservaciones',
                      'Ingresos Extraodinarios',
                    ],
                    datasets: [
                      {
                        label: 'Ingresos',
                        data: [ingressQuotas, ingressAreas, ingressExtra],
                        backgroundColor: [
                          '#2c2da3',
                          '#4741EF',
                          '#ffd65a',
                          // '#FC716B',
                          // '#96F5B6CC',
                          // '#015994',
                          // 'rgb(255, 159, 64)',
                        ],
                        borderColor: [
                          '#2c2da3',
                          '#4741EF',
                          '#ffd65a',
                          // '#FC716B',
                          // '#96F5B6CC',
                          // '#015994',
                          // 'rgb(255, 159, 64)',
                        ],
                        borderWidth: 2,
                      },
                    ],
                  }}
                  options={{
                    plugins: {
                      legend: {
                        position: 'bottom',
                        labels: {
                          padding: 30,
                          usePointStyle: true,
                          fontSize: 12,
                        },
                      },
                      responsive: true,
                      maintainAspectRatio: false,
                      title: {
                        display: false,
                      },
                      layout: {
                        padding: {
                          bottom: 20,
                        },
                      },
                    },
                  }}
                />
              </div>
            ) : (
              ''
            )}
            {activeEgress ? (
              <div className="text-center mt-5">
                <h3 className="text-blue">Egresos</h3>
                <Pie
                  data={{
                    labels: categoriesLabels,
                    datasets: [
                      {
                        label: '',
                        data: egressAmounts,
                        backgroundColor: [
                          '#2c2da3',
                          '#4741EF',
                          '#ffd65a',
                          '#FC716B',
                          '#96F5B6CC',
                          '#015994',
                          '#b9ffff',
                          '#2196f3',
                          '#81c9fa',
                          '#003785',
                          '#7178df',
                          'rgb(255, 159, 64)',
                        ],
                        borderColor: [
                          '#2c2da3',
                          '#4741EF',
                          '#ffd65a',
                          '#FC716B',
                          '#96F5B6CC',
                          '#015994',
                          '#b9ffff',
                          '#2196f3',
                          '#81c9fa',
                          '#003785',
                          '#7178df',
                          'rgb(255, 159, 64)',
                        ],
                        borderWidth: 2,
                      },
                    ],
                  }}
                  options={{
                    plugins: {
                      legend: {
                        position: 'bottom',
                        labels: {
                          padding: 30,
                          usePointStyle: true,
                          fontSize: 12,
                        },
                      },
                      responsive: true,
                      maintainAspectRatio: false,
                      title: {
                        display: false,
                      },
                      layout: {
                        padding: {
                          bottom: 20,
                        },
                      },
                    },
                  }}
                />
              </div>
            ) : (
              ''
            )}
            {activeCharges ? (
              <div className="mt-5 text-center">
                <h3 className="text-blue">Pagos cuotas mantenimiento</h3>
                <Bar
                  options={{
                    responsive: true,
                    scales: {
                      y: {
                        beginAtZero: true,
                      },
                    },
                    plugins: {
                      legend: {
                        display: false,
                      },
                    },
                  }}
                  data={{
                    labels: ['Pagado', 'Pendiente', 'Vencido'],
                    datasets: [
                      {
                        label: '',
                        borderColor: ['#96F5B6CC', '#ffd65a', '#FC716B'],
                        backgroundColor: ['#96F5B6CC', '#ffd65a', '#FC716B'],
                        data: [ingressQuotas, pending, expired],
                        borderWidth: 2,
                      },
                    ],
                  }}
                />
              </div>
            ) : (
              ''
            )}
          </>
        )}
      </div>
    </Section>
  );
}

export default Transparency;
