import React, { useState, useEffect } from 'react';
import Section from '../layouts/Section';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { servicePath } from '../../constants/defaultsValues';
import { Alert } from '../../alertServices';
import { useSelector } from 'react-redux';
import Loader from '../../components/Loader';

const apiCards = `${servicePath}/payment-methods`;
const apiDefault = `${servicePath}/payment-methods/defaultCard`;

const cardBrands = {
  visa: 'assets/img/payments/visa.png',
  mastercard: 'assets/img/payments/mastercard.svg',
  amex: 'assets/img/payments/amex.svg',
  cartes_bancaires: 'assets/img/payments/cartes_bancaires.svg',
  diners: 'assets/img/payments/diners.svg',
  discover: 'assets/img/payments/discover.png',
  jcb: 'assets/img/payments/jcb.svg',
  unionpay: 'assets/img/payments/uninpay.png',
};

function PaymentMethods() {
  const resident = useSelector((state) => state.resident);
  const user = useSelector((state) => state.user);
  const address = useSelector((state) => state.address);
  const condominium = useSelector((state) => state.condominium);
  const token = useSelector((state) => state.jwt);
  const [cards, setCards] = useState({});
  const [dropdownVisible, setDropdownVisible] = useState();
  const [loadingDefault, setLoadingDefault] = useState(null);
  const [isLoaded, setIsLoaded] = useState(true);
  // const [selectedCard, setSelectedCard] = useState(null);

  const toggleDropdown = (cardId) => {
    setDropdownVisible(cardId === dropdownVisible ? null : cardId);
  };

  async function fetchData() {
    setIsLoaded(false);
    axios
      .get(
        `${apiCards}/stripe/${user.id}/${
          resident.addresses.find((a) => a.id === address).condominium
        }?address=${address}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setCards(res.data);
        setIsLoaded(true);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const handleOutsideClick = (e) => {
    if (dropdownVisible && !e.target.closest('.custom-dropdown')) {
      setDropdownVisible(null);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    document.addEventListener('click', handleOutsideClick);
    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, [dropdownVisible]);

  const deleteCard = (card) => {
    axios
      .post(
        `${apiCards}/delete`,
        {
          card,
          address,
          resident: resident.id,
          condominium: resident.addresses.find((a) => a.id === address)
            .condominium,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        fetchData();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const domiciliedCancel = async (card) => {
    try {
      const residentDetailsResponse = await axios.get(
        `${servicePath}/resident-details?address=${address}&resident=${resident.id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (residentDetailsResponse) {
        await axios.put(
          `${servicePath}/resident-details/${residentDetailsResponse.data[0].id}`,
          {
            automaticPayments: false,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        fetchData();
        Alert.success({
          title: 'Domiciliación de pago cancelada.',
        });
      } else {
        Alert.error({
          title: 'Ocurrió un problema. Intente nuevamente más tarde.',
        });
      }
    } catch (error) {
      Alert.error({
        title: 'Ocurrió un problema. Intente nuevamente más tarde.',
      });
    }
  };

  const domiciliedActive = async (card) => {
    try {
      const paymentMethodsResponse = await axios.get(
        `${servicePath}/payment-methods?stripeId=${card.id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const residentDetailsResponse = await axios.get(
        `${servicePath}/resident-details?address=${address}&resident=${resident.id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (paymentMethodsResponse && residentDetailsResponse) {
        await axios.put(
          `${servicePath}/resident-details/${residentDetailsResponse.data[0].id}`,
          {
            automaticPayments: true,
            payment_method: paymentMethodsResponse.data[0].id,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        fetchData();
        Alert.success({
          title: 'Domiciliación de pago activada.',
        });
      } else {
        Alert.error({
          title: 'Ocurrió un problema. Intente nuevamente más tarde.',
        });
      }
    } catch (error) {
      Alert.error({
        title: 'Ocurrió un problema. Intente nuevamente más tarde.',
      });
    }
  };

  const handleSetDefault = async (cardId, customer) => {
    setLoadingDefault(cardId);
    try {
      const defaultCard = cardId === cards.defaultPaymentMethod;

      await axios.post(
        `${apiDefault}`,
        {
          defaultCard,
          paymentMethod: cardId,
          customer,
          condominium,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      fetchData();
      setLoadingDefault(null);
    } catch (error) {
      setLoadingDefault(null);
      Alert.error({
        title: 'Ocurrió un problema. Intente nuevamente más tarde.',
      });
    }
  };

  return (
    <Section allNotification={false} searchPopup={true} withBack>
      {isLoaded ? (
        <div className="cart-area pd-top-36 mg-bottom-40">
          <div className="container">
            <div className="section-title d-flex align-items-center justify-content-between">
              <div className="text-cen ">
                <h2 className="titl text-blue my-0">Tarjetas</h2>
              </div>
              <div className="count-title">{cards.paymentMethods?.length}</div>
            </div>
            {cards.paymentMethods?.length
              ? cards.paymentMethods.map((card) => (
                  <>
                    <div
                      key={card.id}
                      className="card card-payments"
                      style={{
                        backgroundImage: `url(assets/img/bg/15.jpeg)`,
                      }}
                    >
                      <div className="d-flex justify-content-end">
                        <div className="d-flex align-items-center">
                          <button
                            className="btn bg-transparent border border-light text-white d-flex align-items-center p-0 mr-2"
                            style={{ borderRadius: '50px', height: '2rem' }}
                            onClick={() =>
                              handleSetDefault(card.id, card.customer)
                            }
                            disabled={loadingDefault !== null}
                          >
                            {loadingDefault === card.id ? (
                              <div
                                className="spinner-border text-light mr-2 ml-2"
                                role="status"
                              >
                                <span className="sr-only">Loading...</span>
                              </div>
                            ) : card.id === cards.defaultPaymentMethod ? (
                              <i className="small-icon icon-card-default mr-2"></i>
                            ) : (
                              <i className="small-icon icon-card-nodefault mr-2"></i>
                            )}
                            <span className="mr-3">
                              {card.id === cards.defaultPaymentMethod
                                ? 'Predeterminada'
                                : 'Hacer predeterminada'}
                            </span>
                          </button>
                          <div className="custom-dropdown">
                            <input
                              type="checkbox"
                              id={`dropdown-toggle-${card.id}`}
                              className="custom-dropdown-toggle"
                            />
                            <button
                              className="custom-dropdown-label outline-none d-flex align-items-center"
                              onClick={() => toggleDropdown(card.id)}
                            >
                              <span
                                className="icon-menu-dot"
                                aria-hidden="true"
                              >
                                <i className="icon-menu-dot"></i>
                              </span>
                            </button>
                            <ul
                              className="custom-dropdown-menu"
                              style={{
                                display:
                                  dropdownVisible === card.id
                                    ? 'block'
                                    : 'none',
                              }}
                            >
                              <li>
                                <button
                                  type="button"
                                  data-toggle="modal"
                                  data-target={`#modal-delete-${card.id}`}
                                >
                                  Eliminar
                                </button>
                              </li>
                              {card.isDomiciled ? (
                                <li>
                                  <button
                                    type="button"
                                    data-toggle="modal"
                                    data-target={`#modal-domiciled-${card.id}`}
                                  >
                                    Desactivar Domiciliación
                                  </button>
                                </li>
                              ) : (
                                <li>
                                  <button
                                    type="button"
                                    data-toggle="modal"
                                    data-target={`#modal-domiciled-active-${card.id}`}
                                  >
                                    Activar Domiciliación
                                  </button>{' '}
                                </li>
                              )}
                            </ul>
                          </div>
                        </div>
                      </div>

                      <div className="card-header border-0 d-flex justify-content-between align-items-center">
                        <img
                          src={cardBrands[card.card.brand]}
                          alt="Card Logo"
                          className="card-logo"
                        />
                      </div>
                      <div className="card-body pb-0">
                        <div className="row mb-3">
                          <div className="col-md-6">
                            <h3 className="text-white">
                              **** **** **** {card.card.last4}
                            </h3>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-6">
                            <p className="text-white">Titular de la tarjeta</p>
                          </div>
                          <div className="col-6">
                            <p className="text-white">Fecha de caducidad</p>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-6">
                            <p className="text-white font-weight-bold">
                              {card?.billing_details?.name || ' '}
                            </p>
                          </div>
                          <div className="col-6">
                            <p className="text-white font-weight-bold">
                              {card.card?.exp_month <= 9
                                ? `0${card.card?.exp_month}`
                                : card.card?.exp_month}
                              /{card.card?.exp_year?.toString()?.substring(2)}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* MODAL DELETE */}
                    <div id={`modal-delete-${card.id}`} className="modal fade">
                      <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                          <div className="modal-body text-center mt-4 mb-1">
                            <h4 className="text-blue font-weight-bold">
                              ¿Estás seguro de que deseas eliminar esta tarjeta?
                            </h4>
                          </div>
                          <div className="modal-footer justify-content-between mx-3 border-0">
                            <button
                              type="button"
                              className="btn btn-shadow  my-2"
                              data-dismiss="modal"
                            >
                              Cancelar
                            </button>
                            <button
                              onClick={() => deleteCard(card)}
                              type="button"
                              className="btn btn-red my-2"
                              data-dismiss="modal"
                            >
                              Confirmar
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* MODAL DOMICILED */}
                    <div
                      id={`modal-domiciled-${card.id}`}
                      className="modal fade"
                    >
                      <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                          <div className="modal-body text-center mt-4 mb-1">
                            <h4 className="text-blue font-weight-bold">
                              ¿Estás seguro de que deseas cancelar la
                              domiciliación de pago?
                            </h4>
                          </div>
                          <div className="modal-footer justify-content-between mx-3 border-0">
                            <button
                              type="button"
                              className="btn btn-shadow  my-2"
                              data-dismiss="modal"
                            >
                              Cancelar
                            </button>
                            <button
                              onClick={() => domiciliedCancel(card)}
                              type="button"
                              className="btn btn-red my-2"
                              data-dismiss="modal"
                            >
                              Confirmar
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* MODAL DOMICILED ACTIVE*/}
                    <div
                      id={`modal-domiciled-active-${card.id}`}
                      className="modal fade"
                    >
                      <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                          <div className="modal-body text-center mt-4 mb-1">
                            <h4 className="text-blue font-weight-bold mb-4">
                              ¿Estás seguro de que deseas activar la
                              domiciliación de pago?
                            </h4>
                            <span>
                              Evita generar cargos moratorios al habilitar esta
                              función. Tus cuotas se cobrarán de la tarjeta
                              correspondiente de manera automática.
                            </span>
                          </div>
                          <div className="modal-footer justify-content-between mx-3 border-0">
                            <button
                              type="button"
                              className="btn btn-shadow  my-2"
                              data-dismiss="modal"
                            >
                              Cancelar
                            </button>
                            <button
                              onClick={() => domiciliedActive(card)}
                              type="button"
                              className="btn btn-red my-2"
                              data-dismiss="modal"
                            >
                              Confirmar
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                ))
              : null}
            <div className="container text-center my-4">
              <Link
                to="/payments/add-card"
                className="btn btn-new-incidence col-8 col-md-6 col-lg-4 mx-auto d-flex align-items-center justify-content-center text-center"
              >
                Agregar
              </Link>
            </div>
          </div>
        </div>
      ) : (
        <Loader />
      )}
    </Section>
  );
}

export default PaymentMethods;
