import React, { useState, useEffect } from 'react';
import DocViewer, { DocViewerRenderers } from '@cyntler/react-doc-viewer';
import { servicePath } from '../../constants/defaultsValues';

const FileViewer = ({ doc }) => {
  const [file, setFile] = useState(null);

  const showImageViewer = () => {
    return <img src={file.url} alt="Imagen" className="img-contain" />;
  };
  const showDocViewer = () => {
    const docs = [
      {
        uri: file.url,
      },
    ];
    return (
      <DocViewer
        pluginRenderers={DocViewerRenderers}
        documents={docs}
        style={{ height: 350 }}
        config={{
          header: {
            disableHeader: true,
            disableFileName: true,
            retainURLParams: true,
          },
          pdfZoom: {
            defaultZoom: 0.5,
            zoomJump: 0.2,
          },
        }}
      />
    );
  };

  useEffect(() => {
    const fetchedFile = {
      url: `${servicePath}${doc.url}`,
      ext: doc.ext,
    };
    setFile(fetchedFile);
  }, [doc]);

  if (!file) {
    return <p>Cargando...</p>;
  }

  switch (file.ext) {
    case '.pdf':
    case '.doc':
    case '.docx':
    case '.txt':
      return showDocViewer();
    case '.png':
    case '.jpg':
    case '.jpeg':
    case '.webp':
      return showImageViewer();
    default:
      return <p>Tipo de archivo no soportado.</p>;
  }
};

export default FileViewer;
