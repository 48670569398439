import {
  all,
  call,
  fork,
  put,
  takeLatest,
  takeEvery,
} from 'redux-saga/effects';
import axios from 'axios';
import { servicePath } from '../../constants/defaultsValues';
import {
  LOGIN_USER_START,
  LOGOUT_USER,
  REGISTER_USER,
  SELECT_CHARGES_START,
  RESET_PASSWORD_START,
  SELECT_ADDRESS_START,
} from './actions';
// import { Redirect } from "react-router-dom";
import {
  loginUserSuccess,
  loginUserFailed,
  logoutUser,
  resetPasswordSuccess,
  resetPasswordFailed,
  registerUserSuccess,
  registerUserError,
  selectChargesSuccess,
  selectAddressSuccess,
} from './actions';
// import login from "../../components/user/login";

const apiUrl = `${servicePath}/auth/local`;
const apiResidents = `${servicePath}/residents`;
const residentAsync = async (residentId, strapiToken) => {
  const resident = await axios.get(`${apiResidents}/${residentId}`, {
    headers: {
      Authorization: `Bearer ${strapiToken}`,
    },
  });
  return resident && resident.status === 200 ? resident.data : undefined;
};

const strapiCall = async (username, password) =>
  axios
    .post(apiUrl, {
      identifier: username,
      password,
    })
    .then(async (res) => {
      const data = res.data;
      const resident = await residentAsync(data.user.resident.id, data.jwt);
      return { user: data, resident };
    })
    .catch((err) => {
      return err;
    });

function* loginAction(req) {
  const { username, password } = req.payload;
  try {
    const loginUser = yield call(strapiCall, username, password);
    if (!loginUser.message) {
      if (!loginUser.resident || loginUser.resident?.addresses?.length === 0) {
        yield put(
          loginUserFailed(
            'La cuenta no tiene unidad asignada, contacte con su administrador.'
          )
        );
      } else {
        yield put(
          loginUserSuccess(
            loginUser.user.user,
            loginUser.user.jwt,
            loginUser.resident
          )
        );
        // history.push("/");
      }
    } else {
      switch (loginUser.response?.data?.data[0]?.messages[0]?.id) {
        case 'Auth.form.error.confirmed':
          yield put(
            loginUserFailed(
              'La cuenta aún no esta verificada, revisa si te llego el correo de confirmación o inténte de nuevo.'
            )
          );
          break;
        case 'Auth.form.error.invalid':
          yield put(loginUserFailed('Error en el usuario o contraseña.'));
          break;
        default:
          yield put(loginUserFailed('Por favor, inténte de nuevo más tarde.'));
          break;
      }
    }
  } catch (error) {
    yield put(loginUserFailed('Datos incorrectos, verifiquelos'));
  }
}
const resetPasswordAsync = async (code, newPassword, newPasswordConfirm) =>
  axios
    .post(`${servicePath}/auth/reset-password`, {
      code,
      newPassword,
      newPasswordConfirm,
    })
    .then(async (res) => {
      await axios.post(
        `${servicePath}/email/confirm-change-password`,
        {
          user: res.data.user,
        },
        {
          headers: {
            Authorization: `Bearer ${res.data.jwt}`,
          },
        }
      );
      return res.data;
    })
    .catch((error) => {
      return error.message;
    });
function* resetPassword({ payload }) {
  const { code, newPassword, newPasswordConfirm } = payload;
  try {
    const resetPasswordStatus = yield call(
      resetPasswordAsync,
      code,
      newPassword,
      newPasswordConfirm
    );
    if (!resetPasswordStatus) {
      yield put(resetPasswordSuccess('success'));
    } else {
      yield put(resetPasswordFailed(resetPasswordStatus.message));
    }
  } catch (error) {
    yield put(resetPasswordFailed(error));
  }
}
function* logoutAction(a, b) {
  window.location = `/`;
  try {
    yield call(logoutUser(null, null));
    // history.push("/");
  } catch (error) {
    console.log(error);
  }
}
// Watchers
const registerAsync = async (data) =>
  axios
    .post(`${servicePath}/auth/local/register`, {
      ...data,
      role: 1,
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return undefined;
    });

function* registerAction(payload) {
  const user = payload.payload.user.registerData;
  const history = payload.payload.user.history;

  try {
    const register = yield call(registerAsync, user);
    if (register) {
      // console.log("register", register);
      yield put(registerUserSuccess(register.user));
      history.push('/welcome');
    } else {
      yield put(
        registerUserError(
          'Ya existe una cuenta creada con este usuario y/o correo'
        )
      );
    }
  } catch (error) {
    yield put(registerUserError('Error al registrar, intentelo más tarde'));
  }
}
function* selectChargesAction(req) {
  const { selectedCharges } = req.payload;
  try {
    // if (charges) {
    yield put(selectChargesSuccess(selectedCharges));
    // } else {
    // yield put(loginUserFailed("No se"));
    // }
  } catch (error) {
    console.log(error);
    // yield put(loginUserFailed("Datos incorrectos, verifiquelos"));
  }
}
function* selectAddressAction(req) {
  const { selectedAddress, resident } = req.payload;
  try {
    // if (charges) {
    yield put(selectAddressSuccess(selectedAddress, resident));
    // } else {
    // yield put(loginUserFailed("No se"));
    // }
  } catch (error) {
    console.log(error);
    // yield put(loginUserFailed("Datos incorrectos, verifiquelos"));
  }
}

// WATCHERS
export function* watchLogin() {
  yield takeLatest(LOGIN_USER_START, loginAction);
}
export function* watchLogoutUser() {
  yield takeEvery(LOGOUT_USER, logoutAction);
}
export function* watchResetPassword() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(RESET_PASSWORD_START, resetPassword);
}
export function* watchRegisterUser() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(REGISTER_USER, registerAction);
}
export function* watchSelectCharges() {
  yield takeEvery(SELECT_CHARGES_START, selectChargesAction);
}
export function* watchSelectAddress() {
  yield takeEvery(SELECT_ADDRESS_START, selectAddressAction);
}

export default function* rootSaga() {
  yield all([
    fork(watchLogin),
    fork(watchLogoutUser),
    fork(watchRegisterUser),
    fork(watchSelectCharges),
    fork(watchResetPassword),
    fork(watchSelectAddress),
  ]);
}
