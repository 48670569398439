import React, { useState, useEffect } from 'react';
import axios from 'axios';
import moment from 'moment';
import { useSelector } from 'react-redux';
import Section from '../layouts/Section';
import { servicePath } from '../../constants/defaultsValues';
import CalendarStart from '../svg/CalendarStart';
import Loader from '../Loader';
moment.locale('es-mx');

const apiUrl = `${servicePath}/offers`;
const Offers = () => {
  const token = useSelector((state) => state.jwt);
  const address = useSelector((state) => state.address);
  const condominium = useSelector((state) => state.condominium);
  const [items, setItems] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [copiedStatus, setCopiedStatus] = useState(null);

  const handleCopy = (code) => {
    navigator.clipboard
      .writeText(code)
      .then(() => {
        setCopiedStatus('success');
        setTimeout(() => {
          setCopiedStatus(null);
        }, 2000);
      })
      .catch((error) => {
        setCopiedStatus('error');
        setTimeout(() => {
          setCopiedStatus(null);
        }, 2000);
      });
  };

  async function fetchData() {
    setIsLoaded(false);
    const currentDate = moment().format('YYYY-MM-DDTHH:mm:ss');
    try {
      const offersResponse = await axios.get(
        `${apiUrl}?condominium=${condominium}&addresses=${address}&isActive=true&_limit=-1&_sort=startDate:DESC&startDate_lte=${currentDate}&endDate_gte=${currentDate}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setItems(offersResponse.data);
      setIsLoaded(true);
    } catch (error) {}
  }

  useEffect(() => {
    if (token) {
      fetchData();
    }
  }, [token]);

  return (
    <Section title={'Ofertas'}>
      {isLoaded ? (
        <div className="transaction-area pt-3">
          <div className="container my-3">
            <div className="col-12">
              <div className="text-center my-4">
                <h3 className="title text-blue">Últimas Ofertas</h3>
              </div>
            </div>
          </div>
          {items.length > 0 ? (
            <div className="container container-news">
              <div className="row">
                {items.map((item) => (
                  <div className="col-12 col-lg-4 col-md-6" key={item.name}>
                    <div className="card mb-3">
                      <img
                        src={`${servicePath}${item.image?.url}`}
                        alt="img"
                        style={{ objectFit: 'cover', height: '250px' }}
                      />
                      <div className="card-body">
                        <h5 className="card-title text-blue">{item.title}</h5>
                        <p className="card-text">{item.description}</p>
                        <div className="d-flex flex-column align-items-center m-3">
                          <small className="text-blue mb-2">
                            <b>Usa el código:</b>
                          </small>
                          <button
                            className={`btn btn-border-blue d-flex align-items-center ${
                              copiedStatus === 'success'
                                ? 'border-success'
                                : copiedStatus === 'error'
                                ? 'border-danger'
                                : ''
                            }`}
                            onClick={() => handleCopy(item.code)}
                          >
                            {item.code} <i className="icon-copy"></i>
                          </button>
                        </div>

                        {item.terms && (
                          <p>
                            <small className="card-text">
                              <b>Terminos y condiciones:</b> <br /> {item.terms}
                            </small>
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ) : (
            <div className="container">
              <div className="col-12 mt-5 pt-5">
                <div className="text-center mt-4">
                  <CalendarStart />
                </div>
                <div className="text-center mt-4">
                  <h3 className="title text-blue">Sin novedades por ahora</h3>
                </div>
              </div>
            </div>
          )}
        </div>
      ) : (
        <Loader />
      )}
    </Section>
  );
};

export default Offers;
