import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import axios from 'axios';
import { servicePath } from '../../constants/defaultsValues';
import Section from '../../components/layouts/Section';
import InvitationsForm from '../../components/invitations/invitationsForm';
import { Alert } from '../../alertServices';

const apiUrl = `${servicePath}/invitations`;

const Edit = () => {
  let history = useHistory();
  const { id } = useParams();
  const token = useSelector((state) => state.jwt);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState();

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${apiUrl}/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setData(response.data);
      setIsLoading(false);
    } catch (error) {
      Alert.error({
        title: 'Error al obtener la invitación',
      });
      setIsLoading(false);
    }
  };

  const onSubmit = async (values) => {
    setIsLoading(true);
    axios
      .put(`${apiUrl}/${id}`, values, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {
        Alert.success({
          title: 'Invitación creada',
        });
        history.push('/invitations');
        setIsLoading(false);
      })
      .catch((error) => {
        Alert.error({
          title: 'Hubo un problema al crear invitación, intentelo mas tarde.',
        });
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (token) {
      fetchData();
    }
  }, [token]);

  return (
    <Section
      title="Invitaciones"
      withBack={true}
      noBorder={true}
      cancelTo="/invitations"
    >
      <div
        className="container pt-5 section-invitations"
        style={{ paddingBottom: '4rem' }}
      >
        <div className="col-10 mx-auto text-center text-title">
          Selecciona las opciones para editar tu invitación
        </div>

        <InvitationsForm
          onSubmit={onSubmit}
          isLoading={isLoading}
          data={data}
        />
      </div>
    </Section>
  );
};

export default Edit;
